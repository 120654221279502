import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import styled from 'styled-components'
import { Toaster } from 'react-hot-toast'
import { IoIosArrowForward } from 'react-icons/io'
import { FiEdit, FiUploadCloud } from 'react-icons/fi'

import Header from '../../components/Header'
import UploadCv from '../../components/UploadCv'

import { ModalContext } from '../../context/ModalContext'

import { getFromLocalStorage } from '../../utils/util'

import WFHIllustration from '../../assets/images/WFHIllustration.svg'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const ScreenOne = () => {
  const [loading, setLoading] = useState(false)
  const [cvData, setCvData] = useState({})

  const { isModalOpen } = useContext(ModalContext)

  const history = useHistory()

  const handleLink = () => {
    history.push('/cv-review')
  }

  useEffect(() => {
    const cvDetails = getFromLocalStorage('cvDetails')
    if (cvDetails) {
      setCvData(cvDetails)
    }
  }, [])

  return (
    <DndProvider backend={HTML5Backend}>
      <ScreenOneContainer>
        <Header />
        {isModalOpen && (
          <UploadCv
            cvData={cvData}
            loading={loading}
            setLoading={setLoading}
            setCvData={setCvData}
          />
        )}
        <div className="content">
          <h2>Welcome to HOPLA!</h2>
          <p>Set up your CV to start searching for jobs at HOPLA Careers</p>

          <div className="buttonContainer">
            <div className="button">
              <button className="createBtn" onClick={handleLink}>
                <FiEdit className="iconEdit" />
                <span>Create your CV</span>
                <IoIosArrowForward className="iconForward" />
              </button>
            </div>
            <div className="button">
              <label htmlFor="cv" className="createBtn">
                <FiUploadCloud className="iconEdit" />
                <div>
                  <span>Upload your CV</span>
                  <span className="small">
                    {loading ? 'Loading...' : 'PDF'}
                  </span>
                </div>
                <IoIosArrowForward className="iconForward" />
              </label>
            </div>
          </div>
        </div>
        <Toaster position="top-right" reverseOrder={false} />
      </ScreenOneContainer>
    </DndProvider>
  )
}

export default ScreenOne

export const ScreenOneContainer = styled.section`
  width: 100%;
  min-height: 100vh;
  background-image: url(${WFHIllustration});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;

  .content {
    width: 100%;
    padding: 3rem 6%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    h2 {
      font-size: 4rem;
      font-weight: 300;
      color: #31374f;
      line-height: 1.5;
      letter-spacing: 3%;
      text-align: center;
    }

    p {
      font-size: 1rem;
      text-align: center;
      font-weight: 300;
      color: #31374f;
      line-height: 1.5;
      letter-spacing: 3%;
    }

    .buttonContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 3rem;
      gap: 2rem;
      width: 100%;
      max-width: 600px;

      .createBtn {
        background: none;
        border: 1px solid #858aa0;
        border-radius: 100px;
        padding: 0 1.5rem;
        height: 4.5rem;
        width: 100%;
        min-width: 230px;
        max-width: 800px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        .iconEdit {
          font-size: 1.5rem;
          color: #ff5880;
        }

        span {
          flex-grow: 1;
          font-size: 1rem;
          color: #31374f;
          font-weight: 300;
          letter-spacing: 3%;
          margin: 0 0.5rem;
          width: 80px;

          &.small {
            font-size: 0.6rem;
            display: block;
            text-align: left;
            margin-top: 0.5rem;
          }
        }

        &:hover,
        &:active {
          border-color: #31374f;
        }

        .iconForward {
          font-size: 1.5rem;
          color: #858aa0;
        }
      }
    }

    @media only screen and (min-width: 600px) {
      .buttonContainer {
        flex-direction: row;
        justify-content: center;
      }
    }
  }
`
