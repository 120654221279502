import React from 'react'

import styled from 'styled-components'
import { IoIosCheckmarkCircleOutline } from 'react-icons/io'

const ItemSelect = ({ value, active, onClick }) => {
  return (
    <StyledItemSelect className={active ? 'active' : ''} onClick={onClick}>
      {value}

      {active && <IoIosCheckmarkCircleOutline className="icon" />}
    </StyledItemSelect>
  )
}

export default ItemSelect

const StyledItemSelect = styled.div`
  width: 100%;
  height: 3.75rem;
  border-radius: 100px;
  background-color: #fff;
  padding: 0 1.8125rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 300;
  color: #0e1324;
  border: 1px solid #aeb3c9;
  margin-bottom: 2rem;
  cursor: pointer;
  max-width: 456px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;

  &.active,
  &:hover {
    border: 1px solid #0e1324;
  }

  .icon {
    font-size: 1.25rem;
    color: #0e1324;
  }

  &:last-child {
    margin-bottom: 0;
  }
`
