import React, { useContext } from 'react'
import FlexWrapper from '../../components/MiniComponents/FlexWrapper'
import ItemSelect from '../../components/MiniComponents/ItemSelect'
import { ModalContext } from '../../context/ModalContext'
import { WorkEthicsContext } from '../../context/WorkEthics'
import { Button, StyledScreen } from '../remoteSetup/RemoteScreenOne'

const WorkEthicsStepTwo = () => {
  const { device, setDevice } = useContext(WorkEthicsContext)
  const { setWorkEthicsPage, setReachWorkEthicsPage } = useContext(ModalContext)

  return (
    <StyledScreen maxWidth="456px">
      <h2 className="subHeading">
        My hardware set-up is perfect for working from home. I have a...
      </h2>
      <p className="paragraph">
        Your computer hardware directly impacts productivity and reliability.
      </p>

      <div className="mb-2" />
      <ItemSelect
        value="Desktop computer dedicated for my use only."
        onClick={() => setDevice('Desktop computer dedicated for my use only.')}
        active={device === 'Desktop computer dedicated for my use only.'}
      />
      <ItemSelect
        value="Laptop computer dedicated for my use only."
        onClick={() => setDevice('Laptop computer dedicated for my use only.')}
        active={device === 'Laptop computer dedicated for my use only.'}
      />
      <ItemSelect
        value="I share a desktop/laptop with family with seperate user profiles."
        onClick={() =>
          setDevice(
            'I share a desktop/laptop with family with seperate user profiles.'
          )
        }
        active={
          device ===
          'I share a desktop/laptop with family with seperate user profiles.'
        }
      />
      <ItemSelect
        value="Plan to purchase a new desktop/laptop computer once I’m hired."
        onClick={() =>
          setDevice(
            'Plan to purchase a new desktop/laptop computer once I’m hired.'
          )
        }
        active={
          device ===
          'Plan to purchase a new desktop/laptop computer once I’m hired.'
        }
      />

      <FlexWrapper content="flex-end" mt="2rem">
        <Button
          onClick={() => {
            setWorkEthicsPage(1)
            setReachWorkEthicsPage(1)
          }}
        >
          Go Back
        </Button>
        <Button
          className="filled"
          disabled={device === '' || !device}
          onClick={() => {
            setWorkEthicsPage(3)
            setReachWorkEthicsPage(3)
          }}
        >
          Next Step
        </Button>
      </FlexWrapper>
    </StyledScreen>
  )
}

export default WorkEthicsStepTwo
