import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import logo from '../assets/images/logo.svg'
import user from '../assets/images/user.svg'

import { FiMenu } from 'react-icons/fi'
import { IoClose } from 'react-icons/io5'
import HeaderDropdown from './header/HeaderDropdown'
import { ModalContext } from '../context/ModalContext'
import { PagesContext } from '../context/FormPagesContext'

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLogged, setIsLogged] = useState(false)
  const [imgLink, setImgLink] = useState('')

  const {
    handleHeaderToggle,
    headerToggle,
  } = useContext(ModalContext)

  const { userDetails } = useContext(PagesContext)

  useEffect(() => {
    setImgLink(
      localStorage.getItem('profileImgLink') || userDetails?.profileImage
    )
  }, [localStorage.getItem('profileImgLink')])

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setIsLogged(true)
    } else {
      setIsLogged(false)
    }
  }, [])

  return (
    <StyledHeader className={isLogged ? 'logged' : ''}>
      <div className="header-logo">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      {isLogged ? (
        <div className="profile">
          <img
            onClick={handleHeaderToggle}
            src={!imgLink || imgLink === 'null' ? user : imgLink}
            alt="user"
          />
          {headerToggle && <HeaderDropdown />}
        </div>
      ) : (
        <>
          <div className={isOpen ? 'header-menu active' : 'header-menu'}>
            <Link to="/">Home</Link>
            <Link to="/login">Login</Link>
            <Link to="/contact">Contact Us</Link>
            <Link className="register" to="/signup">
              Create Account
            </Link>
          </div>
          {isOpen && (
            <div onClick={() => setIsOpen(!isOpen)} className="overlay" />
          )}
          {isOpen && (
            <IoClose onClick={() => setIsOpen(!isOpen)} className="closeIcon" />
          )}
          <FiMenu
            onClick={() => setIsOpen(!isOpen)}
            className="mobileMenuToggler"
          />
        </>
      )}
    </StyledHeader>
  )
}

export default Header

const StyledHeader = styled.header`
  height: 78px;
  width: 100%;
  background-color: #fff;
  padding: 0 2rem;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.logged {
    border-bottom: 1px solid #dfdfe9;
  }

  @media (max-width: 768px) {
    padding: 0 6%;
  }

  .profile {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }

  .header-logo {
    height: 2.2rem;

    img {
      height: 100%;
      width: auto;
    }
  }

  .overlay {
    display: none;

    @media (max-width: 768px) {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      height: 100vh;
      cursor: pointer;
    }
  }

  .closeIcon {
    @media (max-width: 768px) {
      display: block;
      position: fixed;
      z-index: 2;
      top: 2rem;
      right: 2rem;
      font-size: 1.5rem;
      cursor: pointer;

      &:hover {
        color: #ff5a5f;
      }
    }
  }

  .header-menu {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      color: #31374f;
      font-size: 0.85rem;
      font-weight: 400;
      margin-left: 2.1rem;

      &:first-child {
        margin-left: 0;
      }

      &.last {
        margin-left: 0;
        padding-left: 2rem;
        border-left: 1px solid #d0d5e8;
        color: #fd2055;
      }

      &.register {
        margin-right: 2rem;
        color: #fd2055;
      }
    }

    @media (max-width: 768px) {
      position: fixed;
      top: 0;
      right: 0;
      width: 70vw;
      height: 100vh;
      background-color: #fff;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 1;
      display: none;

      &.active {
        display: flex;
      }

      a {
        margin-left: 0;
        text-align: center;
        margin-bottom: 2rem;
        font-size: 1rem;

        &.last {
          margin-left: 0;
          padding-left: 0;
          color: #fd2055;
          border: none;
          padding-top: 2rem;
          border-top: 1px solid #d0d5e8;
        }

        &.register {
          margin-right: 0;
          color: #fd2055;
        }
      }
    }
  }

  .mobileMenuToggler {
    font-size: 2rem;
    color: #31374f;
    cursor: pointer;
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }
`
