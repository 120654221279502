import React, { useContext } from 'react'

import styled from 'styled-components'
import { ModalContext } from '../../context/ModalContext'
import OoklaTest from './components/OoklaTest'
import { WorkEthicsContext } from '../../context/WorkEthics'
import toast, { Toaster } from 'react-hot-toast'

const RemoteScreenOne = () => {
  const { setRemotePage, setReachRemotePage } = useContext(ModalContext)

  const { ooklaLink } = useContext(WorkEthicsContext)

  const proceedToNextStep = () => {
    const speedTestRegex = /^https:\/\/www\.speedtest\.net\/result\/\d{11}$/
    if (!speedTestRegex.test(ooklaLink)) {
      toast.error('Please enter a valid Speedtest link')
    } else {
      setRemotePage(2)
      setReachRemotePage(2)
    }
  }

  return (
    <StyledScreen>
      <h2 className="subHeading">Internet Speed</h2>
      <p className="paragraph">
        Test your internet speed and proceed to your work from home
        specification.
      </p>

      {/* test with ookla */}
      <OoklaTest />

      <div className="button-container end">
        <div className="nextOpt">
          <Button onClick={proceedToNextStep}>Next Step</Button>
        </div>
        {/* )} */}
        <Toaster position="top-right" reverseOrder={false} />
      </div>
    </StyledScreen>
  )
}

export default RemoteScreenOne

export const StyledScreen = styled.section`
  width: 100%;
  max-width: ${(p) => p.maxWidth || '100%'};

  @media (max-width: 768px) {
    margin-top: 3rem;
  }

  .mb-2 {
    margin-bottom: 2rem;
  }

  .subHeading {
    font-size: 1.5rem;
    font-weight: 300;
    color: #31374f;
    letter-spacing: 0.03rem;
    line-height: 1.5;
  }

  .paragraph {
    font-size: 0.75rem;
    font-weight: 300;
    color: #0e1324;
    letter-spacing: 0.03em;
    line-height: 1.5;
  }

  .internetSpeed {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .singleData {
      .title {
        font-size: 0.75rem;
        display: flex;
        align-items: center;
        font-weight: 500;

        span {
          font-weight: 300;
        }

        .icon {
          color: #31374f;
          margin-right: 0.5rem;

          &.download {
            color: #1877f2;
          }

          &.upload {
            color: #fd2055;

            path {
              stroke: #fd2055;
            }
          }
        }
      }

      .value {
        font-size: 4rem;
        font-weight: 300;
        color: #31374f;
        letter-spacing: 0.03em;
      }
    }
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2.5rem;

    &.end {
      justify-content: flex-end;
    }

    .nextOpt {
      display: flex;
      align-items: center;

      h6 {
        font-size: 0.75rem;
        font-weight: 300;
        margin-right: 1rem;
        color: #858aa0;
        cursor: pointer;

        &:hover {
          color: #31374f;
        }
      }
    }
  }
`

export const Button = styled.button`
  padding: 0.75rem 1.5rem;
  border-radius: 100px;
  border: 1px solid #ff7b9b;
  background: none;
  outline: none;
  color: #ff7b9b;
  cursor: pointer;
  transition: all ease-in-out 0.35s;

  &:hover {
    background: #ff5880;
    color: #fff;
  }

  &.noneFilled {
    background: none;
    border: none;
    color: #31374f;
    display: flex;
    align-items: center;

    &:hover {
      color: #ff5880;
    }
  }

  &.filled {
    background: #ff5880;
    color: #fff;
    margin-left: 1rem;

    &:hover {
      border: 1px solid #ff7b9b;
      background: none;
      color: #ff7b9b;
    }
  }

  &:disabled {
    background: #f9faff;
    border: 1px solid #aeb3c9;
    color: #aeb3c9;

    &:hover {
      background: #f9faff;
      border: 1px solid #aeb3c9;
      color: #aeb3c9;
      cursor: not-allowed;
    }
  }
`
