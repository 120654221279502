import React, { useContext, useMemo } from 'react'

import pluralize from 'pluralize'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import FlexWrapper from '../../components/MiniComponents/FlexWrapper'
import GridWrapper from '../../components/MiniComponents/GridWrapper'
import JobCard from '../../components/MiniComponents/JobCard'

import AuthLayouts from '../../layouts/AuthLayouts'

import check from '../../assets/icons/check-circle.svg'

import { PagesContext } from '../../context/FormPagesContext'

import { useGetJobsQuery } from '../../redux/services/JobApi'
import { useGetUsersQuery } from '../../redux/services/userApi'

const WorkEthicsSuccess = () => {
  const { cvUserData } = useContext(PagesContext)

  const { data: userDetails } = useGetUsersQuery(cvUserData?.id, {
    skip: !cvUserData?.id,
  })

  const technicalSkills = useMemo(() => {
    const meta = userDetails?.data[0]?.meta

    if (!meta) return null

    const updatedMeta = JSON.parse(meta)

    const { fullSkills } = updatedMeta

    return fullSkills[0]
  }, [userDetails])

  const query = useMemo(() => {
    if (!technicalSkills)
      return {
        pageLimit: 3,
      }

    return {
      pageLimit: 3,
    }
  }, [technicalSkills])

  const { data: jobs } = useGetJobsQuery(query)

  return (
    <AuthLayouts width="100vw" isSearch>
      <FlexWrapper content="center" align="center" direction="column">
        <StyledImg src={check} alt="check" />
        <H2>You’re all set up!</H2>
        <P>
          Your profile is complete, which means you can start submitting your
          applications To start searching for remote work click here
        </P>
      </FlexWrapper>

      {/* Recommendation */}
      <RecommendtionSection>
        <H4 mb="2rem">Recommended based on your CV</H4>

        {jobs?.data?.jobs?.length ? (
          <>
            <GridWrapper>
              {jobs?.data?.jobs?.map((job) => (
                <JobCard
                  key={job.id}
                  title={job.title}
                  salary={`${job?.currencies} ${job?.min_salary} - ${job?.currencies} ${job?.max_salary}`}
                  vacancy={`${job?.active_vacancy} ${pluralize(
                    'Candidate',
                    job?.active_vacancy
                  )}`}
                  type={job?.job_type}
                  location={job?.time_shift}
                />
              ))}
            </GridWrapper>
            <FlexWrapper content="flex-end">
              <Link to="/home">See More</Link>
            </FlexWrapper>
          </>
        ) : (
          <>
            <P>No Job Recommendation Available at the moment.</P>
            <Link to="/home">See All Jobs</Link>
          </>
        )}
      </RecommendtionSection>
    </AuthLayouts>
  )
}

export default WorkEthicsSuccess

const StyledImg = styled.img`
  width: 100%;
  max-width: 125px;
  height: auto;
  margin-top: 2rem;
  margin-bottom: 1rem;
`

const H2 = styled.h2`
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: #31374f;
`

const P = styled.p`
  font-size: 0.875rem;
  font-weight: 300;
  color: #31374f;
  line-height: 1.5;
  letter-spacing: 0.03em;
  text-align: center;
  max-width: 529px;
  margin-top: 1rem;
`

const RecommendtionSection = styled.section`
  width: 100%;
  border-top: 1px solid #dee2e6;
  max-width: 1243px;
  margin: 0 auto;
  margin-top: 4rem;
  padding: 2.1rem 0;

  a {
    font-size: 1rem;
    font-weight: 300;
    color: #1877f2;
    text-decoration: none;
    margin-left: 6.5rem;
    margin-top: 2rem;
    display: block;
  }
`

const H4 = styled.h4`
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 0.03em;
  line-height: 1.5;
  margin-bottom: ${(p) => (p.mb ? p.mb : 0)};

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`
