import React, { useState, useContext, useEffect, useMemo } from 'react'

import styled from 'styled-components'
import PhoneInput from 'react-phone-input-2'
import toast, { Toaster } from 'react-hot-toast'
import countries from 'all-countries-and-cities-json'
import Joi from 'joi'

import Modal from '../Modal'
import RichTextbox from '../RichTextbox'

import { ModalContext } from '../../context/ModalContext'
import { PagesContext } from '../../context/FormPagesContext'

import { getFullName, pickObject } from '../../utils/util'

const ProfileDetailsUpdate = () => {
  const { userDetails, setUserDetails, meta } = useContext(PagesContext)
  const { handleModalToggle } = useContext(ModalContext)

  const [cityList, setCityList] = useState([])
  const [fieldErrors, setFieldErrors] = useState({})

  const countryList = useMemo(() => Object.keys(countries), [])
  const validationSchema = useMemo(() => {
    return Joi.object({
      first_name: Joi.string().label('First Name').required(),
      last_name: Joi.string().label('Last Name').required(),
      professionalSummary: Joi.string()
        .label('Professional Summary')
        .required()
        .custom((value, helpers) => {
          // Check if the value is empty or contains only an empty paragraph
          if (value.trim() === '<p><br></p>') {
            return helpers.error('any.invalid', {
              label: 'Professional Summary',
            })
          }
          return value
        })
        .messages({
          'any.required': '"Professional Summary" is required',
          'any.invalid': '"Professional Summary" is not allowed to be empty',
        }),
      contact: Joi.string().label('Contact Number').required(),
      country: Joi.string()
        .label('Country/State')
        .required()
        .custom((value, helpers) => {
          if (value.trim() === 'Choose Option') {
            return helpers.error('any.invalid', {
              label: 'Country',
            })
          }
          return value
        })
        .messages({
          'any.required': '"Country" is required',
          'any.invalid': 'Please select a "country"',
        }),
      city: Joi.string().label('City/Municipality').required(),
      streetAddress: Joi.string().label('Address').required(),
      ZIPCode: Joi.string().label('ZIP Code').required(),
    })
  }, [])

  let applicantCVData = localStorage.getItem('applicant-cv-data')
  applicantCVData = JSON.parse(applicantCVData)
  const applicantCVMeta = JSON.parse(applicantCVData.meta)
  let applicantData = localStorage.getItem('applicant-data')
  applicantData = JSON.parse(applicantData)

  const handleCountryChange = (e) => {
    const selected = e.target.value
    const cityList = countries[selected]

    setUserDetails({
      ...userDetails,
      country: selected,
      city: cityList[0],
    })

    setCityList(cityList)
  }

  useEffect(() => {
    setUserDetails({
      first_name: applicantCVData.first_name,
      middle_name: applicantCVData.middle_name,
      last_name: applicantCVData.last_name,
      professionalSummary: applicantCVData.professional_summary,
      contact: applicantData.phone_number,
      city: applicantData.city,
      country: applicantData.country,
      streetAddress: applicantData.streetAddress,
      ZIPCode: applicantCVMeta.ZIPCode,
    })

    let cities = []
    if (userDetails.country) {
      cities = countries[userDetails.country]
    } else {
      cities = countries[countryList[0]]
    }
    setCityList(cities)
  }, [])

  const handleNext = () => {
    const userData = JSON.parse(localStorage.getItem('applicant-data'))
    const cvData = JSON.parse(localStorage.getItem('applicant-cv-data'))

    const formData = pickObject(userDetails, [
      'first_name',
      'last_name',
      'contact',
      'country',
      'city',
      'streetAddress',
      'ZIPCode',
      'professionalSummary',
    ])

    const result = validationSchema.validate(formData, { abortEarly: false })

    if (!result.error) {
      localStorage.setItem(
        'applicant-data',
        JSON.stringify({
          ...userData,
          city: userDetails.city,
          country: userDetails.country,
          phone_number: userDetails.contact,
        })
      )
      localStorage.setItem(
        'applicant-cv-data',
        JSON.stringify({
          ...cvData,
          professional_summary: JSON.stringify(userDetails.professionalSummary),
          meta: JSON.stringify({
            ...applicantCVMeta,
            meta,
          }),
          first_name: userDetails.first_name,
          middle_name: userDetails.middle_name,
          last_name: userDetails.last_name,
          name: getFullName(
            userDetails.first_name,
            userDetails.middle_name,
            userDetails.last_name
          ),
        })
      )
      handleModalToggle()
    } else {
      const validationErrors = result.error.details.reduce((acc, curr) => {
        acc[curr.context.key] = curr.message.replace(/"/g, '')
        return acc
      }, {})

      setFieldErrors(validationErrors)
      toast.error('Please correct the validation errors before proceeding.')
    }
  }

  return (
    <Modal title="Personal Information" btnTitle="Update" btnFunc={handleNext}>
      <StyledProfileDetailsUpdate>
        <div className="grid">
          <div className="inputs">
            <label className="label" htmlFor="fname">
              First Name<span className="required">*</span>
            </label>
            <input
              defaultValue={userDetails.first_name}
              onChange={(e) =>
                setUserDetails({ ...userDetails, first_name: e.target.value })
              }
              className={`input ${'first_name' in fieldErrors && 'has-error'}`}
              placeholder="First Name"
              type="text"
              id="fname"
            />
            {'first_name' in fieldErrors && (
              <sub style={{ color: 'red' }}>{fieldErrors.first_name}</sub>
            )}
          </div>
          <div className="inputs">
            <label className="label" htmlFor="mname">
              Middle Name
            </label>
            <input
              defaultValue={userDetails.middle_name}
              onChange={(e) =>
                setUserDetails({ ...userDetails, middle_name: e.target.value })
              }
              className="input"
              placeholder="Middle Name"
              type="text"
              id="mname"
            />
          </div>
          <div className="inputs">
            <label className="label" htmlFor="lname">
              Last Name<span className="required">*</span>
            </label>
            <input
              defaultValue={userDetails.last_name}
              onChange={(e) =>
                setUserDetails({ ...userDetails, last_name: e.target.value })
              }
              className={`input ${'last_name' in fieldErrors && 'has-error'}`}
              placeholder="Last Name"
              type="text"
              id="lname"
            />
            {'last_name' in fieldErrors && (
              <sub style={{ color: 'red' }}>{fieldErrors.last_name}</sub>
            )}
          </div>
        </div>
        <div className="inputs">
          <label>
            Professional Summary<span className="required">*</span>
          </label>
          <div className="moreInfo">
            <p>2-3 sentences that describe your professional experience</p>
          </div>

          <RichTextbox
            className={'professionalSummary' in fieldErrors && 'has-error'}
            content={userDetails.professionalSummary}
            setContent={(data) =>
              setUserDetails({ ...userDetails, professionalSummary: data })
            }
            errorMessage={
              'professionalSummary' in fieldErrors &&
              fieldErrors.professionalSummary
            }
          />
        </div>
        <div className="inputs">
          <label>
            Contact Number<span className="required">*</span>
          </label>

          <PhoneInput
            inputStyle={{ borderColor: 'contact' in fieldErrors && 'red' }}
            country={'ph'}
            value={userDetails.contact}
            onChange={(phone) =>
              setUserDetails({ ...userDetails, contact: phone })
            }
          />
          {'contact' in fieldErrors && (
            <sub style={{ color: 'red' }}>{fieldErrors.contact}</sub>
          )}
        </div>
        {/* country */}
        <div className="inputs">
          <label htmlFor="country">
            Country/State<span className="required">*</span>
          </label>

          <select
            value={userDetails.country}
            onChange={handleCountryChange}
            style={{ borderColor: 'country' in fieldErrors && 'red' }}
            className="select"
            name=""
            id="country"
          >
            <option>Choose Option</option>
            {countryList.map((country) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </select>
          {'country' in fieldErrors && (
            <sub style={{ color: 'red' }}>{fieldErrors.country}</sub>
          )}
        </div>
        {/* city */}
        <div className="inputs">
          <label htmlFor="city">
            City/Municipality<span className="required">*</span>
          </label>

          <select
            value={userDetails.city}
            onChange={(e) =>
              setUserDetails({ ...userDetails, city: e.target.value })
            }
            style={{ borderColor: 'city' in fieldErrors && 'red' }}
            className="select"
            name=""
            id="city"
          >
            {cityList?.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
          {'city' in fieldErrors && (
            <sub style={{ color: 'red' }}>{fieldErrors.city}</sub>
          )}
        </div>
        {/* Street Address */}
        <div className="inputs">
          <label htmlFor="streetAddress">
            Street Address<span className="required">*</span>
          </label>

          <input
            className={`input ${'streetAddress' in fieldErrors && 'has-error'}`}
            type="text"
            value={userDetails.streetAddress}
            placeholder="street"
            onChange={(e) =>
              setUserDetails({ ...userDetails, streetAddress: e.target.value })
            }
          />
          {'streetAddress' in fieldErrors && (
            <sub style={{ color: 'red' }}>{fieldErrors.streetAddress}</sub>
          )}
        </div>
        {/* ZIP Code */}
        <div className="inputs">
          <label>
            ZIP Code<span className="required">*</span>
          </label>

          <input
            onChange={(e) =>
              setUserDetails({ ...userDetails, ZIPCode: e.target.value })
            }
            className={`input ${'last_name' in fieldErrors && 'has-error'}`}
            type="text"
            placeholder="Zip code"
            value={userDetails.ZIPCode}
          />
          {'ZIPCode' in fieldErrors && (
            <sub style={{ color: 'red' }}>{fieldErrors.ZIPCode}</sub>
          )}
        </div>

        <Toaster position="top-right" reverseOrder={false} />
      </StyledProfileDetailsUpdate>
    </Modal>
  )
}

export default ProfileDetailsUpdate

// TODO: Remove unnecessary styles
export const StyledProfileDetailsUpdate = styled.div`
  width: 100%;

  .small-subtitle {
    font-size: 0.75rem;
    font-weight: 300;
    color: #0e1324;
    letter-spacing: 0.03em;
    line-height: 1.5rem;
  }

  .toggleBtnWrapper {
    margin: 2rem 0;
    display: flex;
    align-items: center;
  }

  .data-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    margin-top: 2rem;

    .react-datepicker-wrapper {
      display: block;
      margin-top: 1rem;
      width: 100%;

      .react-datepicker__input-container {
        width: 100%;

        input {
          width: 100%;
          height: 2.5rem;
          border-radius: 3px;
          border: 1px solid #aeb3c9;
          padding: 0 1rem;
          outline: none;
        }
      }
    }
  }

  .radioBtns {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    .radio {
      display: flex;
      align-items: center;
      margin-right: 2rem;

      .radio-input {
        opacity: 1;
        accent-color: #ff7b9b;
        width: 19px;
        height: 19px;
      }

      label {
        margin: 0;
        padding: 0;
        margin-left: 10px;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }

  .inputs {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    &.mt {
      margin-top: 1.5rem;
    }

    &.rad {
      margin-bottom: 2rem;
    }

    label {
      font-size: 0.875rem;
      font-weight: 300;
      color: #0e1324;
      letter-spacing: 3%;
      margin-bottom: 0.7rem;
      line-height: 1.7;
    }

    .select,
    .input {
      display: block;
      margin-top: 0.7rem;
      width: 100%;
      height: 2.5rem;
      border-radius: 3px;
      border: 1px solid #aeb3c9;
      padding: 0 1rem;
      outline: none;
      color: #858aa0;
      cursor: pointer;

      &.unique {
        border: none;
        margin-top: 0;
        height: 100%;
      }

      &.has-error {
        border-color: red;
      }

      &::placeholder {
        color: #858aa0;
        font-size: 0.75rem;
      }
    }

    .react-tel-input {
      width: 100%;
      margin-top: 0.7rem;
      border-radius: 3px;

      .form-control {
        width: 100%;
        height: 2.5rem;
        border: 1px solid #aeb3c9;
        border-radius: 3px;
      }
    }

    .moreInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 0.7rem;
        font-weight: 300;
        color: #31374f;
        letter-spacing: 3% inherit;
      }
    }

    .inputWrapper {
      width: 100%;
      min-height: 60px;
      border: 1px solid #aeb3c9;
      border-radius: 3px;
      padding: 0.875rem;
      margin-top: 0.5rem;

      &.flex {
        display: flex;
        align-items: center;
        padding-bottom: 0.375rem;
      }

      input.new {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        font-size: 0.7rem;
        margin-bottom: 0.5rem;
      }
    }

    .label {
      font-size: 0.875rem;
      font-weight: 300;
      color: #0e1324;
      letter-spacing: 0.03em;
      margin-bottom: 0.5rem;
    }

    .input {
      height: 2rem;
      width: 100%;
      border: 1px solid #aeb3c9;
      border-radius: 3px;
      background: none;
      outline: none;
      padding: 0 0.75rem;
    }
  }

  .addContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  button.keywordBtn {
    background: none;
    outline: none;
    border: none;
    font-size: 0.7rem;
    font-weight: 500;
    color: #858aa0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;

    &.remove {
      color: red;
    }

    &.save-edit {
      margin-left: auto;
      padding-right: 2rem;
      color: red;
    }

    &.mt {
      margin-top: 1rem;
    }

    &:hover {
      color: #31374f;
    }

    span {
      width: 0.875rem;
      height: 0.875rem;
      border-radius: 50%;
      background: #ffe2e9;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.5rem;

      &.mr {
        margin-left: 0;
        margin-right: 0.5rem;
      }

      .icon {
        font-size: 0.7rem;
        color: #fd2055;
      }
    }
  }

  .css-14el2xx-placeholder,
  .css-1wa3eu0-placeholder,
  .ck-placeholder {
    color: #858aa0;
    font-size: 0.875rem;
    font-weight: 300;
  }

  .required {
    color: red;
  }
`
