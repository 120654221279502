import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'

import JobDetails from '../../../components/JobDetails/JobDetails.jsx'

import { selectJob } from '../../../redux/feature/jobSlice'

import apiClient from '../../../helpers/apiClient'

import ReactGA4 from '../../../lib/ReactGA4.js'

const JobPage = ({ token }) => {
  const dispatch = useDispatch()

  const selectedJob = useSelector((state) => state.jobs.selectedJob)

  useEffect(() => {
    apiClient
      .get(`jobs?token=${token}`)
      .then((response) => {
        const fetchedJob = response?.data?.data?.jobs[0]

        const reactGA4 = new ReactGA4()
        reactGA4.sendPageView(`Shared Job - ${fetchedJob.title}`)
        dispatch(selectJob(fetchedJob))
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
      })
  }, [])
  let component

  if (selectedJob) {
    component = <JobDetails job={selectedJob} />
  } else {
    return null
  }
  return component
}

export default JobPage
