import { createWorker } from 'tesseract.js'

class OCRService {
  constructor() {
    this.language = 'eng'
  }

  async getTextFromImage(image) {
    try {
      const worker = await createWorker(this.language)
      const response = await worker.recognize(image)
      await worker.terminate()
      return response.data.text
    } catch (error) {
      throw new Error('Failed to extract text from the image.')
    }
  }

  async convertImagesToText(images) {
    try {
      const promises = images.map(async (e) => {
        return this.getTextFromImage(e)
      })
      const texts = await Promise.all(promises)
      return texts.join(' ')
    } catch (error) {
      throw new Error('Failed to convert text from images')
    }
  }
}

export default OCRService
