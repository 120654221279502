import React, { useState, useMemo, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'

import styled from 'styled-components'
import { BsCheck2Circle } from 'react-icons/bs'
import { SlQuestion } from 'react-icons/sl'
import toast from 'react-hot-toast'

import { ModalContext } from '../../../context/ModalContext'
import { PagesContext } from '../../../context/FormPagesContext'

import Modal from '../../../components/Modal'

import apiClient from '../../../helpers/apiClient'

const JobApplicationModal = () => {
  const [loading, setLoading] = useState(false)
  const [applied, setApplied] = useState(false)

  const [channels, setChannels] = useState([])
  const [channelId, setChannelId] = useState(0)

  const { userDetails } = useContext(PagesContext)
  const { setModal } = useContext(ModalContext)

  const selectedJob = useSelector((state) => state.jobs.selectedJob)

  const cvDetails = useMemo(
    () => JSON.parse(localStorage.getItem('applicant-cv-data')),
    []
  )
  const applicantData = useMemo(
    () => JSON.parse(localStorage.getItem('applicant-data')),
    []
  )

  const handleConfirmApply = () => {
    if (channelId === 0 || channelId === null) {
      toast.error('Please specify where did you learn about us')
      return
    }
    setLoading(true)
    const reqBody = {
      applicant_id: cvDetails?.id?.toString(),
      applicant_name: `${userDetails?.firstName} ${userDetails?.lastName}`,
      email: applicantData?.email,
      added_by: 'Recruiter',
      phone_number: cvDetails?.contact?.toString() || '',
      job_id: selectedJob?.id?.toString(),
      job_title: selectedJob?.title,
      tags: 'Pending',
      channel_id: channelId,
    }

    apiClient
      .post(`screening/${cvDetails?.id?.toString()}/add-applicant`, reqBody)
      .then(() => setApplied(true))
      .catch((err) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    apiClient
      .get(`/channels`)
      .then((response) => {
        setChannels(response.data.data.data)
      })
      .catch((error) => {
        console.log(error)
        toast.error(error?.response?.data?.message)
      })
  }, [])

  const handleSelectChannel = (e) => {
    const value = e.target.value
    const idx = channels.findIndex((x) => x.channel === value)
    if (idx > -1) {
      const channelId = channels[idx].id
      setChannelId(channelId)
    } else {
      setChannelId(0)
    }
  }

  const handleCloseModal = () => {
    setModal(false)
    setApplied(false)
  }

  return (
    <>
      {!applied ? (
        <Modal
          btnTitle={loading ? 'Loading...' : 'Continue'}
          btnFunc={handleConfirmApply}
        >
          <StyledConfirmApplication>
            <SlQuestion className="question_icon" />
            <h1>Confirm Application</h1>
            <p>Clicking the Continue button will confirm your application.</p>
            <br />
            <br />
            <div className="inputs">
              <label htmlFor="channel">Where did you learn about us?</label>
              <select
                className="select"
                name="channel"
                id="id"
                onChange={(e) => handleSelectChannel(e)}
              >
                <option>Choose Option</option>
                {channels.map((ch) => (
                  <option key={ch.id} value={ch.channel}>
                    {ch.channel}
                  </option>
                ))}
              </select>
            </div>
          </StyledConfirmApplication>
        </Modal>
      ) : (
        <Modal
          btnTitle="Finish"
          onOverlayClick={handleCloseModal}
          btnFunc={handleCloseModal}
          closeBtnFunc={handleCloseModal}
          noCancel
        >
          <StyledSuccessfulApplication>
            <BsCheck2Circle className="success_icon" />
            <h1>Application Successful!</h1>
            <p>
              Thank you for applying! You will receive a response within 2
              business days.
            </p>
          </StyledSuccessfulApplication>
        </Modal>
      )}
    </>
  )
}

export default JobApplicationModal

const StyledConfirmApplication = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  justify-content: center;

  .question_icon {
    font-size: 5rem;
    color: #ffa500;
    margin: 0.5rem;
  }

  h1,
  p {
    text-align: center;
  }

  .select,
  input {
    display: block;
    margin-top: 0.7rem;
    width: 100%;
    height: 2.5rem;
    border-radius: 3px;
    border: 1px solid #aeb3c9;
    padding: 0 1rem;
    outline: none;
    color: #858aa0;
    cursor: pointer;

    &.unique {
      border: none;
      margin-top: 0;
      height: 100%;
    }

    &::placeholder {
      color: #858aa0;
      font-size: 0.75rem;
    }
  }
`

const StyledSuccessfulApplication = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .success_icon {
    font-size: 5rem;
    color: #5cca95;
    margin: 0.5rem;
  }

  h1,
  p {
    text-align: center;
  }
`
