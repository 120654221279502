import React, { useEffect, useRef } from 'react'

import styled from 'styled-components'
import InputWrapper from './InputWrapper'

import { HiOutlinePaperClip } from 'react-icons/hi'
import toast, { Toaster } from 'react-hot-toast'

const SubFileUpload = ({
  attachment,
  setAttachment,
  noBorder,
  pt,
  maxW,
  label,
}) => {
  const textRef = useRef(null)

  const handleChange = (e) => {
    // check if selected file is an image
    if (e.target.files[0] && e.target.files[0].type.match('image.*')) {
      textRef.current.innerHTML = e.target.files[0].name
      setAttachment(e.target.files[0])
    } else {
      toast.error('Please select an image file')
    }
  }

  useEffect(() => {
    if (attachment) {
      textRef.current.innerHTML = attachment.name
    }
  }, [])

  return (
    <StyledUpload noBorder={noBorder} pt={pt} maxW={maxW}>
      <InputWrapper label={label || 'Attach a photo of your work area'}>
        <label className="upload" htmlFor="uploadFile">
          <p ref={textRef}>No File Choosen yet</p>
          <p className="choose">
            <HiOutlinePaperClip className="icon" />
            Choose File
          </p>
          <input onChange={handleChange} type="file" id="uploadFile" hidden />
        </label>
      </InputWrapper>

      <Toaster position="top-right" reverseOrder={false} />
    </StyledUpload>
  )
}

export default SubFileUpload

const StyledUpload = styled.div`
  width: 100%;
  padding-top: ${(props) => (props.pt ? props.pt : '2rem')};
  border-top: ${(props) => (props.noBorder ? 'none' : '1px solid #e8eaf4')};
  max-width: ${(props) => (props.maxW ? props.maxW : '456px')};

  .upload {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.625rem;
    width: 100%;
    border: 1px solid #aeb3c9;
    padding: 0 1.125rem;
    cursor: pointer;

    p.choose {
      font-size: 0.75rem;
      color: #858aa0;

      .icon {
        margin-right: 0.4rem;
      }
    }
  }
`
