import React from 'react'

import styled from 'styled-components'

import { IoMdTime } from 'react-icons/io'
import { IoCalendarClearOutline } from 'react-icons/io5'
import { FiUsers } from 'react-icons/fi'

const JobCard = ({ title, salary, vacancy, type, location }) => {
  return (
    <JobCardContainer>
      <div className="title">{title}</div>
      <div className="sub-title">{salary}</div>

      <div className="item">
        <FiUsers className="icon" />
        {vacancy}
      </div>
      <div className="item">
        <IoCalendarClearOutline className="icon" />
        {type}
      </div>
      <div className="item">
        <IoMdTime className="icon" />
        {location}
      </div>
    </JobCardContainer>
  )
}

export default JobCard

const JobCardContainer = styled.div`
  width: 100%;
  padding: 1.5rem;
  background: #fff;
  border: 1px solid #dfe2ec;
  border-radius: 3px;

  .title {
    font-size: 1.125rem;
    font-weight: 500;
    color: #31374f;
    letter-spacing: 0.03em;
    line-height: 1.5;
    margin-bottom: 0.5rem;
  }

  .sub-title {
    font-size: 1rem;
    font-weight: 300;
    color: #31374f;
    letter-spacing: 0.03em;
    margin-bottom: 1rem;
  }

  .item {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 300;
    color: #31374f;
    letter-spacing: 0.05em;
    margin-bottom: 1rem;

    &.mt {
      margin-top: 2.5rem;
      margin-bottom: 1.5rem;
    }

    .icon {
      font-size: 0.75rem;
      margin-right: 0.5rem;
    }

    .span {
      color: #1877f2;
      font-weight: 500;
      margin-right: 1.25rem;
    }
  }
`
