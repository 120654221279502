import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SearchHeader from '../pages/home/Header'

import styled from 'styled-components'

const AuthLayouts = ({ children, width, isSearch }) => {
  return (
    <>
      {isSearch ? <SearchHeader isNotFilter /> : <Header />}

      <ContentWrapper width={width}>{children}</ContentWrapper>
      <Footer />
    </>
  )
}

export default AuthLayouts

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: ${(p) => (p.width ? p.width : '376px')};
  margin: 0 auto;
  margin-top: 2rem;
  min-height: calc(100% - 142px);

  @media (max-width: 768px) {
    padding: 0 6%;
  }
`
