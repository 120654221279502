import React, { useState, useEffect, useContext } from 'react'

import styled from 'styled-components'
import { AiOutlineIdcard } from 'react-icons/ai'
import toast, { Toaster } from 'react-hot-toast'
import countries from 'all-countries-and-cities-json'

import { Input, PhoneNumberInput } from '../../components/Input'
import { Button } from '../../components/Button'
import Select from '../../components/MiniComponents/Select'
import ScrollToTop from '../../components/ScrollRestoration'

import { PagesContext } from '../../context/FormPagesContext'

import apiClient from '../../helpers/apiClient'

const FormComponent = () => {
  const { userDetails, setUserDetails } = useContext(PagesContext)
  const [cities, setCities] = useState([])

  const userId = localStorage.getItem('user_id')
  const userData = JSON.parse(localStorage.getItem('applicant-data'))
  const cvData = JSON.parse(localStorage.getItem('applicant-cv-data'))

  useEffect(() => {
    setUserDetails({
      ...userDetails,
      first_name: cvData.first_name,
      middle_name: cvData.middle_name,
      last_name: cvData.last_name,
      city: userData.city,
      country: userData.country,
      contact: userData.phone_number,
      email: userData.email,
    })
    for (const [key, value] of Object.entries(countries)) {
      if (key === userDetails.country) {
        setCities(value)
      }
    }
  }, [])

  const handleCountryChange = (value) => {
    const country = value
    setUserDetails({ ...userDetails, country })

    setCities(countries.country)

    for (const [key, value] of Object.entries(countries)) {
      if (key === country) {
        setCities(value)
      }
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    apiClient
      .put(`applicant/${userId}/update`, userDetails)
      .then((resp) => {
        toast.success('Applicant data successfully changed!')
        localStorage.setItem(
          'applicant-data',
          JSON.stringify({
            ...userData,
            first_name:
              resp.data.first_name !== undefined
                ? resp.data.data.first_name
                : userDetails.first_name,
            middle_name:
              resp.data.middle_name !== undefined
                ? resp.data.data.middle_name
                : userDetails.middle_name,
            last_name:
              resp.data.last_name !== undefined
                ? resp.data.data.last_name
                : userDetails.last_name,
            name:
              resp.data.name !== undefined
                ? resp.data.data.name
                : userDetails.name,
            city:
              resp.data.city !== undefined
                ? resp.data.data.city
                : userDetails.city,
            country:
              resp.data.country !== undefined
                ? resp.data.data.country
                : userDetails.country,
            phone_number:
              resp.data.phone_number !== undefined
                ? resp.data.data.phone_number
                : userDetails.phone_number,
            email:
              resp.data.email !== undefined
                ? resp.data.dta.email
                : userDetails.email,
          })
        )
        localStorage.setItem(
          'applicant-cv-data',
          JSON.stringify({
            ...cvData,
            first_name:
              resp.data.data.first_name !== undefined
                ? resp.data.data.first_name
                : userDetails.first_name,
            middle_name:
              resp.data.data.middle_name !== undefined
                ? resp.data.data.middle_name
                : userDetails.middle_name,
            last_name:
              resp.data.data.last_name !== undefined
                ? resp.data.data.last_name
                : userDetails.last_name,
            name:
              resp.data.data.name !== undefined
                ? resp.data.data.name
                : userDetails.name,
            city:
              resp.data.data.city !== undefined
                ? resp.data.data.city
                : userDetails.city,
            country:
              resp.data.data.country !== undefined
                ? resp.data.data.country
                : userDetails.country,
            email:
              resp.data.data.email !== undefined
                ? resp.data.data.email
                : userDetails.email,
          })
        )
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || 'Something went wrong!')
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="formGroup">
        <div className="form__inline">
          <Input
            title="First Name"
            type="text"
            fontSize="1rem"
            IconComponent={<AiOutlineIdcard />}
            value={userDetails.first_name}
            onChange={(value) =>
              setUserDetails({ ...userDetails, first_name: value })
            }
            required
          />
          <Input
            title="Middle Name"
            type="text"
            fontSize="1rem"
            IconComponent={<AiOutlineIdcard />}
            value={userDetails.middle_name}
            onChange={(value) =>
              setUserDetails({ ...userDetails, middle_name: value })
            }
          />
          <Input
            title="Last Name"
            type="text"
            fontSize="1rem"
            IconComponent={<AiOutlineIdcard />}
            value={userDetails.last_name}
            onChange={(value) =>
              setUserDetails({ ...userDetails, last_name: value })
            }
            required
          />
        </div>
      </div>

      <div className="formGroup">
        <Input
          title="Email Address"
          fontSize="1rem"
          value={userDetails.email}
          onChange={(value) => setUserDetails({ ...userDetails, email: value })}
          required
        />
      </div>

      <div className="formGroup">
        <PhoneNumberInput
          title="Phone Number"
          country="ph"
          value={userDetails.phone_number}
          onChange={(value) =>
            setUserDetails({ ...userDetails, phone_number: value })
          }
        />
      </div>

      <div className="formGroup">
        <label htmlFor="country">Country</label>
        <br />
        <Select
          className="country"
          data={Object.keys(countries)}
          value={userDetails.country}
          placeholder="Select Country"
          handleSelect={handleCountryChange}
        />
      </div>

      <div className="formGroup">
        <label htmlFor="city">City/Municipality</label>
        <Select
          className="city"
          data={cities}
          value={userDetails.city}
          placeholder="Select City"
          handleSelect={(value) =>
            setUserDetails({ ...userDetails, city: value })
          }
        />
      </div>

      <div className="button__container">
        <Button type="submit">Save Changes</Button>
      </div>
    </form>
  )
}

const SettingsOne = () => {
  return (
    <StyledSettingsOne>
      <ScrollToTop />
      <h2>Personal Information</h2>
      <div>
        <FormComponent />
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </StyledSettingsOne>
  )
}

export default SettingsOne

export const StyledSettingsOne = styled.div`
  width: 100%;
  padding: 0 15px;
  padding-left: 30px;

  .formLine {
    display: flex;
  }

  .formGroup {
    width: 100%;
    padding: 0 15px 10px 0;

    .form__inline {
      display: flex;
      gap: 1rem;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
  }

  label {
    margin-bottom: 5px;
    font-weight: 300;
    color: #31374f;
    letter-spacing: 5%;
    font-size: 16px;
  }

  .formNote {
    font-size: 12px;
    color: #31374f;
    font-weight: 300;
    margin: 10px 10px 15px;
  }

  .button__container {
    padding: 1rem 0;
    float: right;
    width: 10rem;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: 5%;
    color: #31374f;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    margin-top: 2rem;
    width: 100%;
  }
`
