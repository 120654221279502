import React from 'react'

import { useSelector } from 'react-redux'

import CVImage from './CVImage'

const UploadedCV = () => {
  const uploadedCV = useSelector((state) => state.cv.uploadedCV)

  return (
    typeof uploadedCV === 'object' &&
    [...Array(uploadedCV?.pages).keys()].map((page) => (
      <CVImage
        key={page}
        url={uploadedCV?.url?.slice(0, -3) + 'jpg'}
        pageNumber={page + 1}
      />
    ))
  )
}

export default UploadedCV
