import React from 'react'
import styled from 'styled-components'

const SpacingLine = ({ text, full }) => {
  return (
    <StyledLine className={full ? 'full' : ''}>
      <div className="line" />
      <div className="lineText">{text}</div>
    </StyledLine>
  )
}

export default SpacingLine

const StyledLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  position: relative;
  margin: 1.5rem 0;

  &.full {
    padding: 0;
  }

  .line {
    width: 100%;
    height: 1px;
    background: #aeb3c9;
  }

  .lineText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 0.5rem 0.9rem;
    font-size: 0.75rem;
    color: #5f6683;
  }

  &.full {
    .line {
      background: #dfdfe9;
    }

    .lineText {
      display: none;
    }
  }
`
