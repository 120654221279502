import React from 'react'

import styled from 'styled-components'
import { HiOutlinePaperClip } from 'react-icons/hi'

import Modal from '../../../components/Modal'
import InputWrapper from '../../../components/MiniComponents/InputWrapper'

/**
 * Renders an image uploader modal component.
 *
 * @param {Object} props - The props object.
 * @param {boolean} props.loading - Flag indicating if the image is being uploaded.
 * @param {function} props.onSaveImage - Function to save the image.
 * @param {function} props.onChangeImage - Function to handle image change.
 * @param {React.MutableRefObject} props.avatarRef - Reference object for the avatar image.
 * @param {React.MutableRefObject} props.imageRef - Reference object for the uploaded image.
 * @param {string} props.imgLink - The URL of the uploaded image.
 * @return {JSX.Element} The image uploader modal component.
 */
const ImageUploaderModal = ({
  loading,
  onSaveImage,
  onChangeImage,
  avatarRef,
  imageRef,
  imgLink,
}) => {
  return (
    <Modal
      title="Upload Profile Picture"
      btnTitle={loading ? 'Uploading Image...' : 'Save Profile Picture'}
      btnFunc={onSaveImage}
    >
      <StyledUpload>
        <StyledUploadImageWrapper>
          {imageRef?.current?.files[0]?.name && (
            <img
              className="avatar"
              ref={avatarRef}
              src={imgLink}
              alt="uploaded image"
            />
          )}
          <InputWrapper>
            <label className="upload" htmlFor="uploadFile">
              <p>
                {imageRef?.current?.files[0]?.name || 'No File Choosen yet'}
              </p>
              <p className="choose">
                <HiOutlinePaperClip className="icon" />
                Choose File
              </p>
              <input
                type="file"
                id="uploadFile"
                ref={imageRef}
                onChange={onChangeImage}
                hidden
              />
            </label>
          </InputWrapper>
        </StyledUploadImageWrapper>
      </StyledUpload>
    </Modal>
  )
}

export default ImageUploaderModal

const StyledUpload = styled.div`
  .upload {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.625rem;
    width: 100%;
    border: 1px solid #aeb3c9;
    padding: 0 1.125rem;
    cursor: pointer;

    p.choose {
      font-size: 0.75rem;
      color: #858aa0;

      .icon {
        margin-right: 0.4rem;
      }
    }
  }
`

const StyledUploadImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;

  .avatar {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
`
