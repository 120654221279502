import React, { useState, useEffect } from 'react'

import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import Loader from 'react-loader-spinner'

import { LoginContainer } from './Login'
import AuthLayouts from '../layouts/AuthLayouts'
import { Input } from '../components/Input'
import { Button } from '../components/Button'
import Error from '../components/Error'
import ScrollToTop from '../components/ScrollRestoration'

import ReactGA4 from '../lib/ReactGA4'

import { baseUrl } from '../constants/constant'

const ForgetPassword = () => {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    const reactGA4 = new ReactGA4()
    reactGA4.sendPageView('Forgot Password')
  }, [])

  const handleEmail = (value) => {
    setEmail(value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    // check if email passes regex check
    if (email !== '') {
      setIsLoading(true)

      const data = {
        email,
      }

      axios
        .post(`${baseUrl}/user/reset_password`, data)
        .then(() => {
          localStorage.setItem('email', email)
          history.push('/forget-password/verify')
          setIsLoading(false)
        })
        .catch((error) => {
          setError('oops, something is wrong try again')
          if (error.response) {
            if (error.response.data.message !== undefined) {
              setError(error.response.data.message)
            } else {
              setError(error.response.data)
            }
          }
          setIsLoading(false)
        })
    } else {
      setError('Please fill all fields')
    }
  }

  return (
    <AuthLayouts>
      <ScrollToTop />
      <LoginContainer>
        <h2 className="newSpace">Forgot your password?</h2>
        {error && <Error error={error} />}
        <p className="subTitle">
          Enter the email address associated with your account and we’ll send
          you a link to reset your password
        </p>
        <Input value={email} onChange={handleEmail} title="Email" />
        <Button handleClick={handleSubmit}>
          {isLoading ? (
            <Loader type="ThreeDots" color="#FFF" height={32} width={32} />
          ) : (
            'Send'
          )}
        </Button>

        <p className="mt-3">
          Don&apos;t have an account?&nbsp;
          <Link className="iconLink" to="/signup">
            Create an account here
          </Link>
        </p>
        <p className="linkContainer">
          Already have an account?&nbsp;
          <Link className="iconLink" to="/login">
            Log in Here
          </Link>
        </p>
      </LoginContainer>
    </AuthLayouts>
  )
}

export default ForgetPassword
