import React from 'react'

import Collapsible from 'react-collapsible'
import styled from 'styled-components'
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'

import H2 from '../../components/typography/H2'

const FAQ = () => {
  const currentURL = window.location.origin

  return (
    <StyledCollapsible>
      <div className="h2__wrapper">
        <H2 size="20px" weight="400">
          Frequently Asked Questions
        </H2>
      </div>

      <Collapsible
        trigger={
          <div className="trigger">
            <p>
              <strong>What is HOPLA all about?</strong>
            </p>
            <AiOutlineDown className="arrow__icons" />
          </div>
        }
        triggerWhenOpen={
          <div className="trigger">
            <p>
              <strong>What is HOPLA all about?</strong>
            </p>
            <AiOutlineUp className="arrow__icons" />
          </div>
        }
        classParentString="collapsible"
        className="collapsible__closed"
        contentInnerClassName="collapssible__inner__content"
      >
        <p>
          HOPLA, established in 2016, is a company specializing in outsourcing
          and offshoring services. We offer solutions and create remote teams
          for businesses worldwide.
        </p>
      </Collapsible>

      <Collapsible
        trigger={
          <div className="trigger">
            <p>
              <strong>
                Does HOPLA only work with Full-time resource requirements?
              </strong>
            </p>
            <AiOutlineDown className="arrow__icons" />
          </div>
        }
        triggerWhenOpen={
          <div className="trigger">
            <p>
              <strong>
                Does HOPLA only work with Full-time resource requirements?
              </strong>
            </p>
            <AiOutlineUp className="arrow__icons" />
          </div>
        }
        classParentString="collapsible"
        className="collapsible__closed"
        contentInnerClassName="collapssible__inner__content"
      >
        <p>
          Yes, HOPLA&apos;s commitment to our talents is to provide them with
          full-time career opportunities while working-from-home. Through our
          full-time dedicated model, we are able to solicit better commitment
          from our team members to stay on for the long-term and create a
          culture where team members truly feel an extension of the
          client&apos;s in-house operations.
        </p>
      </Collapsible>

      <Collapsible
        trigger={
          <div className="trigger">
            <p>
              <strong>
                What are the most common roles within the HOPLA community?
              </strong>
            </p>
            <AiOutlineDown className="arrow__icons" />
          </div>
        }
        triggerWhenOpen={
          <div className="trigger">
            <p>
              <strong>
                What are the most common roles within the HOPLA community?
              </strong>
            </p>
            <AiOutlineUp className="arrow__icons" />
          </div>
        }
        classParentString="collapsible"
        className="collapsible__closed"
        contentInnerClassName="collapssible__inner__content"
      >
        <p>
          Due to our remote nature, we are able to cast our nets much wider and
          can support any roles that can be done successfully while working from
          home. Check out our <a href={`${currentURL}`}>Careers Page</a> to get
          more insights on the most common roles we have within our team today.
        </p>
      </Collapsible>

      <Collapsible
        trigger={
          <div className="trigger">
            <p>
              <strong>Where are the HOPLA talents from?</strong>
            </p>
            <AiOutlineDown className="arrow__icons" />
          </div>
        }
        triggerWhenOpen={
          <div className="trigger">
            <p>
              <strong>Where are the HOPLA talents from?</strong>
            </p>
            <AiOutlineUp className="arrow__icons" />
          </div>
        }
        classParentString="collapsible"
        className="collapsible__closed"
        contentInnerClassName="collapssible__inner__content"
      >
        <p>
          HOPLA&apos;s fully remote nature allows us to source talents from any
          location globally. In spite of this flexibility, we have predetermined
          strategic sourcing locations globally that allow us to source
          cost-effective, highly capable, and remote fitting talents for our
          clients. The Philippines for example is one of our primary talent
          sourcing locations given the many benefits of working with talents
          from this region including effective english communication skills.
        </p>
      </Collapsible>

      <Collapsible
        trigger={
          <div className="trigger">
            <p>
              <strong>
                How do I know I am getting the right person for my team?
              </strong>
            </p>
            <AiOutlineDown className="arrow__icons" />
          </div>
        }
        triggerWhenOpen={
          <div className="trigger">
            <p>
              <strong>
                How do I know I am getting the right person for my team?
              </strong>
            </p>
            <AiOutlineUp className="arrow__icons" />
          </div>
        }
        classParentString="collapsible"
        className="collapsible__closed"
        contentInnerClassName="collapssible__inner__content"
      >
        <p>
          HOPLA&apos;s experts will be guiding you and sharing our expertise and
          best practices in identifying the best-fitting talents for your team.
          Before the start of the sourcing process, we will be supporting you in
          identifying your ideal candidate avatar based on your business support
          requirements. Once this has been identified, HOPLA will be initiating
          our proven effective, multi-layered, and personalized vetting approach
          to provide you with the top candidate options for you to consider to
          join your team. <br />
          <br />
          Once you have identified the talent you feel best fits your
          requirements, HOPLA&apos;s service delivery expert will continue to
          guide and collaborate with you to continue to get the best output from
          your HOPLA dedicated team members.
        </p>
      </Collapsible>

      <Collapsible
        trigger={
          <div className="trigger">
            <p>
              <strong>
                How does HOPLA support their clients in maintaining staff
                satisfaction & retention?
              </strong>
            </p>
            <AiOutlineDown className="arrow__icons" />
          </div>
        }
        triggerWhenOpen={
          <div className="trigger">
            <p>
              <strong>
                How does HOPLA support their clients in maintaining staff
                satisfaction & retention?
              </strong>
            </p>
            <AiOutlineUp className="arrow__icons" />
          </div>
        }
        classParentString="collapsible"
        className="collapsible__closed"
        contentInnerClassName="collapssible__inner__content"
      >
        <p>
          HOPLA&apos;s “<i>managing people in</i>” philosophy is the cornerstone
          of all the benefits, incentive packages & regular engagement programs
          we have for our team members. Overall staff satisfaction is constantly
          achieved through investments in making regular personalized
          interactions and career mapping progression support; providing our
          team members with industry-grade and remote-appropriate benefits
          packages; and conducting regular engagement programs that are created
          to provide opportunities for holistic development of our team members.
        </p>
      </Collapsible>

      <Collapsible
        trigger={
          <div className="trigger">
            <p>
              <strong>Why would I want to work with HOPLA?</strong>
            </p>
            <AiOutlineDown className="arrow__icons" />
          </div>
        }
        triggerWhenOpen={
          <div className="trigger">
            <p>
              <strong>Why would I want to work with HOPLA?</strong>
            </p>
            <AiOutlineUp className="arrow__icons" />
          </div>
        }
        classParentString="collapsible"
        className="collapsible__closed"
        contentInnerClassName="collapssible__inner__content"
      >
        <p>
          Being a pioneer in distributed team building, you can be guaranteed
          that HOPLA has got an array of battle tested solutions: talent vetting
          approach, people management support, operational workflows, remote
          tools, and human resource programs that would allow you to build an
          effective fully distributed team seamlessly.
          <br />
          <br />
          As a company that has a serious passion for innovation, you will see
          that HOPLA will not just support the build-out of your remote team but
          continue to find opportunities to improve your remote operations.
        </p>
      </Collapsible>

      <Collapsible
        trigger={
          <div className="trigger">
            <p>
              <strong>Will the staff be shared with other clients?</strong>
            </p>
            <AiOutlineDown className="arrow__icons" />
          </div>
        }
        triggerWhenOpen={
          <div className="trigger">
            <p>
              <strong>Will the staff be shared with other clients?</strong>
            </p>
            <AiOutlineUp className="arrow__icons" />
          </div>
        }
        classParentString="collapsible"
        className="collapsible__closed"
        contentInnerClassName="collapssible__inner__content"
      >
        <p>
          While HOPLA holds legal engagement with the staff, it&apos;s crucial
          to recognize that their wholehearted dedication is focused on
          providing support to you and your organization. We consistently
          prioritize your company&apos;s requirements and place them at the
          forefront of our efforts.
        </p>
      </Collapsible>

      <Collapsible
        trigger={
          <div className="trigger">
            <p>
              <strong>
                Why haven&apos;t I received an email confirmation after
                submitting my application?
              </strong>
            </p>
            <AiOutlineDown className="arrow__icons" />
          </div>
        }
        triggerWhenOpen={
          <div className="trigger">
            <p>
              <strong>
                Why haven&apos;t I received an email confirmation after
                submitting my application?
              </strong>
            </p>
            <AiOutlineUp className="arrow__icons" />
          </div>
        }
        classParentString="collapsible"
        className="collapsible__closed"
        contentInnerClassName="collapssible__inner__content"
      >
        <p>
          We recommend checking your spam or junk email folder as well.
          Sometimes, our confirmation emails may end up there. If you don&apos;t
          find the email in your spam folder or have any further concerns,
          please contact our <a href={`${currentURL}/contact`}>support team</a>,
          and we&apos;ll be happy to assist you in resolving the issue.
        </p>
      </Collapsible>

      <Collapsible
        trigger={
          <div className="trigger">
            <p>
              <strong>
                Why can&apos;t I apply for another vacancy after submitting my
                application for a different job?
              </strong>
            </p>
            <AiOutlineDown className="arrow__icons" />
          </div>
        }
        triggerWhenOpen={
          <div className="trigger">
            <p>
              <strong>
                Why can&apos;t I apply for another vacancy after submitting my
                application for a different job?
              </strong>
            </p>
            <AiOutlineUp className="arrow__icons" />
          </div>
        }
        classParentString="collapsible"
        className="collapsible__closed"
        contentInnerClassName="collapssible__inner__content"
      >
        <p>
          In our job portal, we aim for a fair and efficient application
          process. Users can submit only one application at a time. If
          you&apos;ve already applied, you can&apos;t submit another one for a
          different position. To withdraw an application, please email us at
          <a href="mailto: careers_support@hopla.online">
            {' '}
            careers_support@hopla.online
          </a>
        </p>
      </Collapsible>
    </StyledCollapsible>
  )
}

export default FAQ

const StyledCollapsible = styled.div`
  a {
    text-decoration: none;
    cursor: pointer;
  }

  .trigger {
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    p {
      width: 90%;
    }

    .arrow__icons {
      font-size: 20px;
      color: #31374f;
      margin-left: 1rem;
    }
  }

  .h2__wrapper {
    padding: 1rem 0.5rem;
  }

  .collapsible {
    padding: 0.5rem;
    border-bottom: #aeb3c9 solid 1px;
  }

  .collapsible__closed {
    padding: 0.5rem;
  }

  .collapssible__inner__content {
    padding: 1rem 0;
  }
`
