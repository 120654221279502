import React, { useEffect } from 'react'

import { useMediaQuery } from 'react-responsive'

import FAQ from './FAQ'
import ContactForm from './ContactForm'
import AuthLayouts from '../../layouts/AuthLayouts'

import ReactGA4 from '../../lib/ReactGA4'

const Contact = () => {
  useEffect(() => {
    const reactGA4 = new ReactGA4()
    reactGA4.sendPageView('Contact Us')
  }, [])

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  return (
    <>
      <AuthLayouts width={`${isMobile ? '100%' : '80%'}`}>
        <FAQ />
        <ContactForm width={`${isMobile ? '100%' : '60%'}`} />
      </AuthLayouts>
    </>
  )
}

export default Contact
