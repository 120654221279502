import React from 'react'
import moment from 'moment'
import { RiDeleteBinLine } from 'react-icons/ri'
import { StyledProfileDetails } from './ProfileDetails'

import pencil from '../assets/icons/pencil.svg'

const ExperienceDetails = ({
  jobTitle,
  companyName,
  companyLocation,
  from,
  to,
  handleDelete,
  handleEdit,
  id,
  jobDescriptionHtml,
  experienceCurrentStatus,
}) => {
  return (
    <StyledProfileDetails>
      <p className="paragraph">
        <b>{jobTitle}</b>
      </p>

      <p className="paragraph">
        {companyName} -{' '}
        {companyLocation?.label ? companyLocation?.label : companyLocation}
      </p>
      <p className="paragraph">
        {moment(from).format('MMMM YYYY')} -{' '}
        {experienceCurrentStatus === 'Currently employed'
          ? 'Present'
          : moment(to).format('MMMM YYYY')}
      </p>

      <p
        className="paragraph first"
        dangerouslySetInnerHTML={{ __html: jobDescriptionHtml }}
      />

      <div className="cta">
        <RiDeleteBinLine className="icon" onClick={() => handleDelete(id)} />
        <img
          src={pencil}
          onClick={() => handleEdit(id)}
          alt="pencil icon"
          className="pencil"
        />
      </div>
    </StyledProfileDetails>
  )
}

export default ExperienceDetails
