import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import ReactSlider from 'react-slider'

const Thumb = (props, state) => {
  return (
    <StyledThumb {...props}>
      <p className='thumb-foot'>
        {
          props.key === "thumb-0" ? "Min" : "Max"
        }
      </p>  
    </StyledThumb>
  )
}

const Track = (props, state) => <StyledTrack {...props} index={state.index} />

const MultiRangeSlider = ({ min, max, onChange, value, defaultValue }) => {
  return (
    <StyledSlider
      defaultValue={defaultValue}
      max={max}
      min={min}
      renderTrack={Track}
      renderThumb={Thumb}
      minDistance={500}
      step={500}
      value={value}
      onChange={(value) => onChange(value)}
    />
  )
}

export default MultiRangeSlider

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.number).isRequired,
}

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 25px;
  position: relative;
`

const StyledThumb = styled.div`
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  background-color: #31374F;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
  position: absolute;
  outline: none;

  .thumb-foot {
    position: absolute;
    bottom: -1.8rem;
    color: #31374F;
    font-size: 0.875rem;
    font-weight: 300;
  }
`

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${(props) =>
    props.index === 2 ? '#DFE2EC' : props.index === 1 ? '#31374F' : '#DFE2EC'};
  border-radius: 0;
  height: 8px;
  position: absolute;
  top: 50%;
  border-radius: 100px;
  transform: translateY(-50%);
`
