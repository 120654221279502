import React from 'react'

import styled from 'styled-components'

const Input = ({ type, onChange, value, placeholder, name, style }) => {
  return (
    <InputContainer
      type={type}
      onChange={onChange}
      value={value}
      placeholder={placeholder || ''}
      name={name}
      style={style}
    />
  )
}

export const TextArea = ({ onChange, value, placeholder, maxLength }) => {
  return (
    <TextAreaContainer
      onChange={onChange}
      as="textarea"
      value={value}
      placeholder={placeholder || ''}
      maxLength={maxLength}
    />
  )
}

export default Input

const InputContainer = styled.input`
  width: 100%;
  height: 100%;
  max-height: 2.625rem;
  padding: 0.75rem 0.6rem;
  border: 1px solid #aeb3c9;
  color: #aeb3c9;
  background: none;
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0.03em;

  &:focus,
  &:active,
  &:hover {
    outline: none;
    border: 1px solid #0e1324;
    color: #0e1324;
  }
`

const TextAreaContainer = styled(InputContainer)`
  min-height: 150px;
  max-height: 150px;
`
