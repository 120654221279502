import React, { useEffect, useState } from 'react'
import AuthLayouts from '../layouts/AuthLayouts'

import styled from 'styled-components'

// icons
import mail from '../assets/images/mail.svg'
import ScrollToTop from '../components/ScrollRestoration'
import axios from 'axios'
import Loader from 'react-loader-spinner'
import { useHistory } from 'react-router'
import { baseUrl } from '../constants/constant'

const PasswordVerification = () => {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setEmail(localStorage.getItem('email'))
  }, [])

  const handleRetry = () => {
    setLoading(true)
    axios
      .post(`${baseUrl}/user/reset_password`, { email: email })
      .then((res) => {
        setLoading(false)
        history.push('/forget-password/verify')
      })
  }

  return (
    <AuthLayouts width="500px">
      <ScrollToTop />
      <StyledVerify>
        <img src={mail} alt="mail" className="icon" />
        <h1>Please Check Your Mail</h1>
        <p>
          To continue with the forget password, please verify your email by
          clicking on the link we sent to your registered email: <b>{email}</b>
        </p>
        <p>
          <span onClick={handleRetry}>
            {loading ? (
              <Loader type="ThreeDots" color="#404040" height={16} width={16} />
            ) : (
              'Click here'
            )}
          </span>{' '}
          to resend a verification email
        </p>
      </StyledVerify>
    </AuthLayouts>
  )
}

export default PasswordVerification

export const StyledVerify = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .errorImge {
    font-size: 5rem;
    color: #f44336;
  }

  .icon {
    width: 8rem;
    height: auto;
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 300;
    letter-spacing: 5%;
    text-align: center;

    &.error {
      color: #f44336;
    }
  }

  p {
    font-size: 0.9rem;
    text-align: center;
    letter-spacing: 3%;
    line-height: 2rem;
    color: #404040;
    font-weight: 300;
    margin-top: 2rem;
    margin-bottom: 1rem;

    span {
      color: #fd2055;
      cursor: pointer;
    }
  }
`
