import { v4 as uuidv4 } from 'uuid'
import { React, createContext, useState, useEffect } from 'react'

export const PagesContext = createContext(null)
export const PagesProvider = ({ children }) => {
  const [page, setPage] = useState(
    () => Number(localStorage.getItem('page')) || 0.2
  )
  const [reachPage, setReachPage] = useState(
    () => Number(localStorage.getItem('reachPage')) || 0.2
  )
  const [from, setFrom] = useState(new Date())
  const [to, setTo] = useState(new Date())
  const [educationLevel, setEducationLevel] = useState('')
  const [fieldOfStudy, setFieldOfStudy] = useState('')
  const [school, setSchool] = useState('')
  const [currentStatus, setCurrentStatus] = useState('graduated')
  const [schoolLocation, setSchoolLocation] = useState('')
  const [cvPhoto, setCvPhoto] = useState(true)
  const [cvData, setCvData] = useState(true)
  const [wfh, setWfh] = useState(true)
  const [remoteFitness, setRemoteFitness] = useState(true)
  const [contactDetails, setContactDetails] = useState(true)
  const [toggleCharacterRef, setToggleCharacterRef] = useState(true)

  const [experienceFrom, setExperienceFrom] = useState(new Date())
  const [experienceTo, setExperienceTo] = useState(new Date())
  const [jobTitle, setJobTitle] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companyLocation, setCompanyLocation] = useState('')
  const [industry, setIndustry] = useState('')
  const [workType, setWorkType] = useState('Permanent Work From Home')
  const [experienceCurrentStatus, setExperienceCurrentStatus] =
    useState('No longer employed')
  const [jobDescriptionHtml, setJobDescriptionHtml] = useState('')
  const [summaryToggle, setSummaryToggle] = useState(false)
  const [testSkill, setTextSkill] = useState([])
  const [check, setCheck] = useState(false)
  const [option, setOption] = useState([])
  const [softSkillOption, setSoftSkillOption] = useState([])
  const [updateSummary, setUpdateSummary] = useState(false)
  const [professionalHeadlinesId, setProfessionalHeadlinesId] = useState('')

  const [toggleNoCharRef, setToggleNoCharRef] = useState(false)
  const [crName, setCrName] = useState('')
  const [crEmail, setCrEmail] = useState('')
  const [crPhone, setCrPhone] = useState('')

  const [file, setFile] = useState(null)

  const [items, setItems] = useState([])

  const [phrases, setPhrases] = useState([
    {
      id: uuidv4(),
      item: 'Dedicated Customer Service Representative committed to providing quality care for the ultimate customer satisfaction.',
      status: 'current',
    },
    {
      id: uuidv4(),
      item: 'Experienced and effective recruiter adept in all aspects of recruitment, support and solutions.',
      status: 'current',
    },
    {
      id: uuidv4(),
      item: 'Bringing forth expertise in web design with a track record of leading design projects with high levels of client satisfaction.',
      status: 'current',
    },
  ])

  const [cvUserData, setCvUserData] = useState({})

  useEffect(() => {
    setCvUserData(
      (localStorage.getItem('applicant-cv-data') &&
        localStorage.getItem('applicant-cv-data') !== 'undefined' &&
        JSON.parse(localStorage.getItem('applicant-cv-data'))) ||
        {}
    )
  }, [])

  const markAsMoved = (id) => {
    const newItem = items.find((item) => item.id === id)
    newItem.status = 'moved'

    const filteredItems = items.filter((item) => item.id !== id)

    setItems([...filteredItems, newItem])
  }

  const handleDelete = (id) => {
    // remove filteredList from items
    const newItems = items.filter((item) => item.id !== id)
    const selectedItem = items.find((item) => item.id === id)
    const updatedHeadlines = items.filter(
      (headline) => headline.status === 'moved'
    )
    selectedItem.status = 'current'

    setItems([selectedItem, ...newItems])

    setUserDetails({
      ...userDetails,
      professionalHeadlines: updatedHeadlines,
    })
  }

  const markAsCompleted = (id) => {
    const newItem = phrases.find((item) => item.id === id)
    newItem.status = 'done'

    const filteredItems = phrases.filter((item) => item.id !== id)

    setPhrases([...filteredItems, newItem])
  }

  const userData =
    (localStorage.getItem('applicant-cv-data') &&
      JSON.parse(localStorage.getItem('applicant-data'))) ||
    {}

  const meta = cvUserData && cvUserData.meta ? JSON.parse(cvUserData.meta) : []

  const [userDetails, setUserDetails] = useState(() => {
    if (cvUserData) {
      return {
        id: cvUserData ? cvUserData.id : '',
        user_id: cvUserData?.user_id,
        email: cvUserData.email || userData.email,
        name: cvUserData ? cvUserData.name : '',
        first_name: cvUserData?.first_name,
        middle_name: cvUserData?.middle_name,
        last_name: cvUserData?.last_name,
        professionalHeadlines:
          cvUserData && cvUserData.professional_headline
            ? JSON.parse(cvUserData.professional_headline)
            : [],
        professionalSummary:
          cvUserData &&
          cvUserData.professional_summary &&
          cvUserData.professional_summary !== ' '
            ? JSON.parse(cvUserData.professional_summary)
            : '',
        country: userData ? userData.country : '',
        city: userData ? userData.city : '',
        contact: userData ? userData.phone_number : '',
        phone_number: userData ? userData.phone_number : '',

        streetAddress: meta && meta.streetAddress ? meta.streetAddress : '',
        ZIPCode: meta && meta.ZIPCode ? meta.ZIPCode : '',
        profileImage: userData && userData.image_url ? userData.image_url : '',
        cvImage: userData?.image_url || cvUserData?.cv_image_url,

        character_reference: cvUserData?.character_reference,
      }
    } else {
      return {
        id: userData.id || '',
        user_id: userData?.user_id,
        email: userData.email,
        first_name: userData.firstName || '',
        middle_name: userData.middleName || '',
        last_name: userData.lastName || '',
        professionalHeadlines:
          userData && userData.professionalHeadlines
            ? JSON.parse(userData.professionalHeadlines)
            : [],
        professionalSummary:
          userData && userData.professionalSummary
            ? userData.professionalSummary
            : '',
        country: userData.country || '',
        city: userData.city || '',
        contact: userData.contact || '',
        streetAddress: userData.streetAddress || '',
        ZIPCode: userData.ZIPCode || '',
        profileImage: userData.profileImage || '',
        cvImage: userData?.image_url || cvUserData?.cv_image_url,

        character_reference: cvUserData?.character_reference,
      }
    }
  })

  const [validCvImgUrl, setValidCvImgUrl] = useState(() => {
    if (userDetails?.cvImage) {
      fetch(userDetails.cvImage).then((res) => {
        return res.ok
      })
    } else {
      return false
    }
  })

  const [profesionalSum, setProfesionalSum] = useState(
    userDetails.professionalSummary
  )

  const [educationalData, setEducationalData] = useState(() => {
    if (cvUserData) {
      return meta ? meta.educationalData : []
    } else {
      const meta =
        userData &&
        userData.meta &&
        userData.meta !== '' &&
        JSON.parse(userData.meta)
      return meta?.educationalData || []
    }
  })

  const [experienceState, setExperienceState] = useState(() => {
    if (cvUserData) {
      return meta ? meta?.experienceState : []
    } else {
      const meta =
        userData &&
        userData?.meta &&
        userData?.meta !== '' &&
        JSON.parse(userData?.meta)
      return meta?.experienceState || []
    }
  })

  const [skill, setSkill] = useState('')
  const [skills, setSkills] = useState(() => {
    if (cvUserData) {
      return meta && meta.fullSkills && meta.fullSkills[0]
        ? meta?.fullSkills[0]
        : []
    } else {
      const meta =
        userData &&
        userData.meta &&
        userData.meta !== '' &&
        JSON.parse(userData.meta)
      if (meta && meta.fullSkills && meta.fullSkills[0]) {
        return meta.fullSkills[0]
      } else {
        return []
      }
    }
  })

  const [softSkills, setSoftSkills] = useState(() => {
    if (cvUserData) {
      return meta && meta.fullSkills && meta.fullSkills[1]
        ? meta.fullSkills[1]
        : []
    } else {
      const meta =
        userData &&
        userData.meta &&
        userData.meta !== '' &&
        JSON.parse(userData.meta)
      if (meta && meta.fullSkills && meta.fullSkills[1]) {
        return meta.fullSkills[1]
      } else {
        return []
      }
    }
  })

  const [fullSkills, setFullSkills] = useState(() => {
    if (userData.userCv) {
      return meta && meta.fullSkills ? meta.fullSkills : []
    } else {
      const meta =
        userData &&
        userData.meta &&
        userData.meta !== '' &&
        JSON.parse(userData.meta)
      return meta?.fullSkills || []
    }
  })

  const [characterReferences, setCharacterReferences] = useState(() => {
    if (cvUserData) {
      return cvUserData.character_reference
        ? JSON.parse(cvUserData.character_reference)
        : []
    } else {
      const cr =
        cvUserData &&
        cvUserData.character_reference &&
        cvUserData.character_reference !== '' &&
        JSON.parse(cvUserData.character_reference).characterReferences
      if (
        cvUserData &&
        cvUserData.character_reference &&
        cvUserData.character_reference !== undefined
      ) {
        return cr
      } else {
        return []
      }
    }
  })

  const [eduToggle, setEduToggle] = useState(() => {
    if (userData && educationalData?.length <= 0) {
      return true
    } else {
      return false
    }
  })
  const [toggle, setToggle] = useState(() => {
    if (userData && experienceState?.length <= 0) {
      return true
    } else {
      return false
    }
  })

  return (
    <PagesContext.Provider
      value={{
        items,
        page,
        setPage,
        reachPage,
        setReachPage,
        from,
        setFrom,
        to,
        setTo,
        educationLevel,
        setEducationLevel,
        fieldOfStudy,
        setFieldOfStudy,
        school,
        setSchool,
        currentStatus,
        setCurrentStatus,
        schoolLocation,
        setSchoolLocation,

        setUserDetails,
        userDetails,
        userData,

        cvUserData,
        setCvUserData,
        skill,
        setSkill,
        skills,
        setSkills,
        softSkills,
        setSoftSkills,

        check,
        setCheck,

        experienceFrom,
        setExperienceFrom,
        experienceTo,
        setExperienceTo,
        jobTitle,
        setJobTitle,
        companyName,
        setCompanyName,
        companyLocation,
        setCompanyLocation,
        industry,
        setIndustry,
        workType,
        setWorkType,
        experienceCurrentStatus,
        setExperienceCurrentStatus,
        jobDescriptionHtml,
        setJobDescriptionHtml,

        option,
        setOption,
        softSkillOption,
        setSoftSkillOption,

        crName,
        setCrName,
        crEmail,
        setCrEmail,
        crPhone,
        setCrPhone,
        toggleNoCharRef,
        setToggleNoCharRef,

        markAsMoved,
        setItems,
        phrases,
        setPhrases,
        markAsCompleted,
        summaryToggle,
        setSummaryToggle,
        testSkill,
        setTextSkill,
        educationalData,
        setEducationalData,
        experienceState,
        setExperienceState,
        fullSkills,
        setFullSkills,
        characterReferences,
        setCharacterReferences,
        handleDelete,
        file,
        setFile,
        toggle,
        setToggle,
        eduToggle,
        setEduToggle,
        meta,
        wfh,
        setWfh,
        remoteFitness,
        setRemoteFitness,
        toggleCharacterRef,
        setToggleCharacterRef,
        cvData,
        setCvData,
        contactDetails,
        setContactDetails,
        cvPhoto,
        setCvPhoto,
        updateSummary,
        setUpdateSummary,
        professionalHeadlinesId,
        setProfessionalHeadlinesId,
        profesionalSum,
        setProfesionalSum,
        setValidCvImgUrl,
        validCvImgUrl,
      }}
    >
      {children}
    </PagesContext.Provider>
  )
}
