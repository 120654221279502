import React from 'react'

import styled from 'styled-components'

function Select({ data, placeholder, handleSelect, value }) {
  return (
    <StyledSelect value={value} onChange={(e) => handleSelect(e.target.value)}>
      <option>{value || placeholder}</option>
      {data.map((item, index) => (
        <option key={index} value={item}>
          {item}
        </option>
      ))}
    </StyledSelect>
  )
}

export default Select

const StyledSelect = styled.select`
  width: 100%;
  height: 2.5rem;
  border-radius: 3px;
  border: 1px solid #aeb3c9;
  background: none;
  color: #858aa0;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 300;
`
