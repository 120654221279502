import React from 'react'

import ReCAPTCHA from 'react-google-recaptcha'

import { RECAPTCHA_API_KEY } from '../constants/constant'

const ReCAPTCHAComponent = ({ onChange, captchaRef }) => {
  return (
    <ReCAPTCHA
      sitekey={RECAPTCHA_API_KEY}
      onChange={onChange}
      ref={captchaRef}
    />
  )
}

export default ReCAPTCHAComponent
