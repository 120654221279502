import React, { useContext, useMemo, useState } from 'react'

import axios from 'axios'
import { useHistory, useLocation } from 'react-router'

import { Button, StyledScreen } from '../remoteSetup/RemoteScreenOne'
import FlexWrapper from '../../components/MiniComponents/FlexWrapper'
import ItemSelect from '../../components/MiniComponents/ItemSelect'

import { PagesContext } from '../../context/FormPagesContext'
import { ModalContext } from '../../context/ModalContext'
import { WorkEthicsContext } from '../../context/WorkEthics'

import { baseUrl } from '../../constants/constant'

import WelcomeModal from '../welcome/components/WelcomeModal'

const WorkEthicsStepSix = () => {
  const {
    timeTracker,
    setTimeTracker,
    workArea,
    device,
    fixSchedule,
    shift,
    attachment,
  } = useContext(WorkEthicsContext)
  const { setWorkEthicsPage, setReachWorkEthicsPage } = useContext(ModalContext)
  const { userDetails } = useContext(PagesContext)

  const history = useHistory()
  const location = useLocation()

  const [loading, setLoading] = useState(false)

  const getUserId = useMemo(() => localStorage.getItem('user_id'), [])

  const data = {
    workStation: workArea,
    device: device,
    workSchedule: fixSchedule,
    workShift: shift,
    timeTracker: timeTracker,
  }

  const onSubmit = () => {
    // addded id to api url
    const formData = new FormData()
    formData.append('remoteWorkMeta', JSON.stringify(data))
    if (attachment) {
      formData.append('attachment', attachment)
    }

    setLoading(true)

    axios
      .post(
        `${baseUrl}/user/${getUserId || userDetails.id}/remote-work`,
        formData,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': `multipart/form-data;`,
          },
        }
      )
      .then((res) => {
        const currentLocation = location.pathname.split('/')
        if (currentLocation[currentLocation.length - 1] === 'update') {
          const wfh = JSON.parse(localStorage.getItem('applicant-wfh'))
          const newWfh = {
            ...wfh,
            meta: res.data.resp.meta,
          }
          localStorage.setItem('applicant-wfh', JSON.stringify(newWfh))
          history.push('/profile')
        } else {
          history.push('/remote-fitness-level/success')
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  return (
    <StyledScreen maxWidth="456px">
      <WelcomeModal />
      <h2 className="subHeading">
        I am dedicated to building my work-from-home career and...
      </h2>
      <p className="paragraph">
        A struggle for most clients with remote staff is ensuring their team are
        actually working when they’re supposed to. We eliminate this concern by
        ensuring our remote professionals can easily track their time without
        hampering their day-to-day tasks.
      </p>

      <div className="mb-2" />
      <ItemSelect
        value="Understand how time-tracking can achieve full-transparency to build long-term trust"
        onClick={() =>
          setTimeTracker(
            'Understand how time-tracking can achieve full-transparency to build long-term trust'
          )
        }
        active={
          timeTracker ===
          'Understand how time-tracking can achieve full-transparency to build long-term trust'
        }
      />
      <ItemSelect
        value="Don’t think time-tracking is necessary"
        onClick={() => setTimeTracker('Don’t think time-tracking is necessary')}
        active={timeTracker === 'Don’t think time-tracking is necessary'}
      />
      <ItemSelect
        value="Would want to learn more about time-tracking"
        onClick={() =>
          setTimeTracker('Would want to learn more about time-tracking')
        }
        active={timeTracker === 'Would want to learn more about time-tracking'}
      />

      <FlexWrapper content="flex-end" mt="2rem">
        <Button
          onClick={() => {
            setWorkEthicsPage(3)
            setReachWorkEthicsPage(3)
          }}
        >
          Go Back
        </Button>
        <Button
          className="filled"
          disabled={timeTracker === '' || !timeTracker}
          onClick={onSubmit}
        >
          {loading ? 'Loading...' : 'Finish'}
        </Button>
      </FlexWrapper>
    </StyledScreen>
  )
}

export default WorkEthicsStepSix
