import React from 'react'

import { Link } from 'react-router-dom'

// compoenent
import { ContentWrapper } from '../../layouts/AuthLayouts'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import { Container } from '../remoteSetup/RemoteHome'

// icons
import Collaboration from '../../assets/images/workEthicsIcon.svg'
import { HiOutlineDocumentText } from 'react-icons/hi'
import { IoIosArrowForward } from 'react-icons/io'

const WorkEthicsHome = () => {
  return (
    <>
      <Header />
      <ContentWrapper>
        <Container>
          <img src={Collaboration} alt="remote" />

          <h2 className="sub-heading">Remote Fitness Level</h2>

          <p className="paragraph">
            This is a questionnaire with five quick questions about your work
            ethics
          </p>

          <Link className="btnIcon" to="/remote-fitness-level/create">
            <div className="detail">
              <HiOutlineDocumentText className="icon" />
              Proceed to questionnaire
            </div>
            <IoIosArrowForward className="forwardIcon" />
          </Link>
        </Container>
      </ContentWrapper>
      <Footer />
    </>
  )
}

export default WorkEthicsHome
