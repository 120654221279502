import React, { useContext, useEffect } from 'react'

import axios from 'axios'

import AuthLayouts from '../../layouts/AuthLayouts'

import RemoteScreenOne from './RemoteScreenOne'
import RemoteScreenThree from './RemoteScreenThree'
import RemoteScreenTwo from './RemoteScreenTwo'
import RemoteSidebar from './Sidebar'
import { StyledMain } from '../onboarding'

import { PagesContext } from '../../context/FormPagesContext'
import { ModalContext } from '../../context/ModalContext'
import { WorkEthicsContext } from '../../context/WorkEthics'

import { baseUrl } from '../../constants/constant'

const RemoteSetup = () => {
  const {
    remotePage,
    setPheripheral,
    setCompSpec,
    setUploadSpeed,
    setDownloadSpeed,
    setMajorSpeed,
    setPingTime,
    setTestCount,
  } = useContext(ModalContext)
  const { setOoklaLink } = React.useContext(WorkEthicsContext)
  const { userData } = useContext(PagesContext)

  // Get User past test results
  useEffect(() => {
    axios
      .get(`${baseUrl}/user/${userData.id}/work-home`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((res) => {
        const result = res.data.resp

        if (result) {
          setPheripheral(JSON.parse(result.peripherals) || [])
          setCompSpec(JSON.parse(result.computer_specification) || [])

          const { pingTime, downloadSpeed, uploadSpeed, testCount, ooklaLink } =
            JSON.parse(result.internet_speed_test)

          setOoklaLink(ooklaLink || '')
          setUploadSpeed(uploadSpeed || 0)
          setDownloadSpeed(downloadSpeed || 0)
          setMajorSpeed(downloadSpeed || 0)
          setPingTime(pingTime || 0)
          setTestCount(testCount || 0)
        }
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <AuthLayouts width="750px">
      <StyledMain>
        <RemoteSidebar />

        {remotePage === 1 && <RemoteScreenOne />}
        {remotePage === 2 && <RemoteScreenTwo />}
        {remotePage === 3 && <RemoteScreenThree />}
      </StyledMain>
    </AuthLayouts>
  )
}

export default RemoteSetup
