import React from 'react'
import styled from 'styled-components'

const ContentArea = ({ selectedPage }) => {
  return (
    <StyledContentArea>
      <div className="content-area">{selectedPage}</div>
    </StyledContentArea>
  )
}

export default ContentArea

export const StyledContentArea = styled.div`
  width: 100%;
  border-left: 1px solid #ccc;

  @media (max-width: 768px) {
    margin-top: 2rem;
    width: 100%;
    border-left: none;
    border-top: 1px solid #ccc;
  }
`
