import React, { useEffect } from 'react'

import { Toaster } from 'react-hot-toast'

import Profile from './Profile'

import ReactGA4 from '../../lib/ReactGA4'

const ProfileIndex = () => {
  useEffect(() => {
    const reactGA4 = new ReactGA4()
    reactGA4.sendPageView('My Profile')
  }, [])

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <Profile />
    </>
  )
}

export default ProfileIndex
