import styled from 'styled-components'

const H2 = styled.h2`
  font-size: ${({ size }) => size || '2rem'};
  font-weight: ${({ weight }) => weight || '300'};
  color: ${({ color }) => color || '#0E1324'};
  margin-bottom: ${({ mb }) => mb || '0'};
  margin-top: ${({ mt }) => mt || '0'};
  text-align: ${({ align }) => align || 'left'};
`

export default H2
