import React, { useContext } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { ModalContext } from '../../context/ModalContext'
import { StyledSidebar } from '../onboarding/Sidebar'
import { DropProgress } from '../remoteSetup/Sidebar'

const WorkEthicsSidebar = () => {
  const { workEthicsPage, setWorkEthicsPage, reachWorkEthicsPage } =
    useContext(ModalContext)

  return (
    <StyledSidebar>
      <div className="in">
        <CircularProgressbar
          value={workEthicsPage}
          maxValue={5}
          text={`${workEthicsPage} of 5`}
          strokeWidth={5}
          styles={buildStyles({
            strokeLinecap: 'butt',
            textSize: '.8rem',
            pathTransitionDuration: 0.5,
            pathColor: `#FF5880`,
            textColor: '#31374F',
            trailColor: '#E9EBF2',
          })}
        />
      </div>
      <div className="sidebar-content">
        <DropProgress
          active={workEthicsPage === 1}
          value={1}
          postActive={workEthicsPage > 1 || reachWorkEthicsPage > 1}
          text="Work Area"
          setter={setWorkEthicsPage}
          last={true}
          remotePage={workEthicsPage}
          reachRemotePage={reachWorkEthicsPage}
          setRemotePage={setWorkEthicsPage}
        />
        <DropProgress
          active={workEthicsPage === 2}
          value={2}
          postActive={workEthicsPage > 2 || reachWorkEthicsPage > 2}
          text="Device"
          setter={setWorkEthicsPage}
          remotePage={workEthicsPage}
          reachRemotePage={reachWorkEthicsPage}
          setRemotePage={setWorkEthicsPage}
        />
        <DropProgress
          active={workEthicsPage === 3}
          value={3}
          postActive={workEthicsPage > 3 || reachWorkEthicsPage > 3}
          text="Schedule"
          setter={setWorkEthicsPage}
          remotePage={workEthicsPage}
          reachRemotePage={reachWorkEthicsPage}
          setRemotePage={setWorkEthicsPage}
        />
        <DropProgress
          active={workEthicsPage === 4}
          value={4}
          postActive={workEthicsPage > 4 || reachWorkEthicsPage > 4}
          text="Shifts"
          setter={setWorkEthicsPage}
          remotePage={workEthicsPage}
          reachRemotePage={reachWorkEthicsPage}
          setRemotePage={setWorkEthicsPage}
        />
        <DropProgress
          active={workEthicsPage === 5 || reachWorkEthicsPage === 5}
          value={5}
          text="Time Tracker"
          setter={setWorkEthicsPage}
          remotePage={workEthicsPage}
          reachRemotePage={reachWorkEthicsPage}
          setRemotePage={setWorkEthicsPage}
        />
      </div>
    </StyledSidebar>
  )
}

export default WorkEthicsSidebar
