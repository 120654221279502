/* eslint-disable camelcase */
import React, { useContext } from 'react'

import axios from 'axios'
import { useHistory, useLocation } from 'react-router'

import FlexWrapper from '../../components/MiniComponents/FlexWrapper'
import InputWrapper from '../../components/MiniComponents/InputWrapper'
import RadioButton from '../../components/MiniComponents/RadioButton'
import { Button, StyledScreen } from './RemoteScreenOne'

import { WorkEthicsContext } from '../../context/WorkEthics'
import { ModalContext } from '../../context/ModalContext'
import { PagesContext } from '../../context/FormPagesContext'

import { baseUrl } from '../../constants/constant'

const RemoteScreenThree = () => {
  const {
    pheripheral,
    setPheripheral,
    compSpec,
    setCompSpec,
    pingTime,
    setPingTime,
    downloadSpeed,
    setDownloadSpeed,
    setUploadSpeed,
    uploadSpeed,
    testCount,
    setTestCount,
    setReachRemotePage,
    setRemotePage,
  } = useContext(ModalContext)
  const { ooklaLink, ooklaScreenshot } = React.useContext(WorkEthicsContext)
  const { userDetails } = React.useContext(PagesContext)

  const [loading, setLoading] = React.useState(false)

  const history = useHistory()

  const location = useLocation()

  const handleSubmit = () => {
    setLoading(true)

    const user_id = localStorage.getItem('user_id')

    const formData = new FormData()

    formData.append(
      'internetSpeedTest',
      JSON.stringify({
        pingTime,
        downloadSpeed,
        uploadSpeed,
        testCount,
        ooklaLink: ooklaLink,
      })
    )

    formData.append('computerSpecification', JSON.stringify(compSpec))

    formData.append('peripherals', JSON.stringify(pheripheral))

    if (ooklaScreenshot) {
      formData.append('attachment', ooklaScreenshot)
    }

    axios
      .post(
        `${baseUrl}/user/${user_id || userDetails?.id}/work-home`,
        formData,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
      .then((res) => {
        setLoading(false)
        setPheripheral(JSON.parse(res.data.resp.peripherals))
        setCompSpec(JSON.parse(res.data.resp.computer_specification))

        const currentLocation = location.pathname.split('/')

        const { pingTime, downloadSpeed, uploadSpeed, testCount } = JSON.parse(
          res.data.resp.internet_speed_test
        )

        setUploadSpeed(uploadSpeed)
        setDownloadSpeed(downloadSpeed)
        setPingTime(pingTime)
        setTestCount(parseInt(testCount) || 3)

        if (currentLocation[currentLocation.length - 1] === 'update') {
          const wfh = JSON.parse(localStorage.getItem('applicant-wfh'))
          const newWfh = {
            ...wfh,
            computer_specification: res.data.resp.computer_specification,
            peripherals: res.data.resp.peripherals,
            internet_speed_test: res.data.resp.internet_speed_test,
          }
          localStorage.setItem('applicant-wfh', JSON.stringify(newWfh))
          history.push('/profile')
        } else {
          history.push('/remote-fitness-level')
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err?.response)
      })
  }

  return (
    <StyledScreen>
      <h2 className="subHeading">Peripherals</h2>
      <p className="paragraph mt-b">
        Add other details needed here to proceed with the process
      </p>

      <InputWrapper mt="2.5rem" label="Do you currently own a web camera?">
        <FlexWrapper
          wrap="wrap"
          gap="1.5rem"
          mobileGap="1rem"
          content="flex-start"
        >
          <RadioButton
            name="camera"
            onChange={(e) =>
              setPheripheral((prevState) => ({
                ...prevState,
                webCamera: e.target.value,
              }))
            }
            value="Yes"
            label="Yes"
            checked={pheripheral.webCamera === 'Yes'}
          />
          <RadioButton
            name="camera"
            onChange={(e) =>
              setPheripheral((prevState) => ({
                ...prevState,
                webCamera: e.target.value,
              }))
            }
            value="No"
            label="No"
            checked={pheripheral.webCamera === 'No'}
          />
          <RadioButton
            name="camera"
            onChange={(e) =>
              setPheripheral((prevState) => ({
                ...prevState,
                webCamera: e.target.value,
              }))
            }
            value="No, but I am planning to buy in the future"
            label="No, but I am planning to buy in the future"
            checked={
              pheripheral.webCamera ===
              'No, but I am planning to buy in the future'
            }
          />
        </FlexWrapper>
      </InputWrapper>

      <InputWrapper mt="2.5rem" label="Do you own a headset?">
        <FlexWrapper wrap="wrap" gap="1.5rem" content="flex-start">
          <RadioButton
            name="headset"
            value="Yes"
            label="Yes"
            onChange={(e) =>
              setPheripheral((prevState) => ({
                ...prevState,
                headSet: e.target.value,
              }))
            }
            checked={pheripheral.headSet === 'Yes'}
          />
          <RadioButton
            name="headset"
            value="No"
            label="No"
            onChange={(e) =>
              setPheripheral((prevState) => ({
                ...prevState,
                headSet: e.target.value,
              }))
            }
            checked={pheripheral.headSet === 'No'}
          />
          <RadioButton
            name="headset"
            value="No, but I am planning to buy in the future"
            label="No, but I am planning to buy in the future"
            onChange={(e) =>
              setPheripheral((prevState) => ({
                ...prevState,
                headSet: e.target.value,
              }))
            }
            checked={
              pheripheral.headSet ===
              'No, but I am planning to buy in the future'
            }
          />
        </FlexWrapper>
      </InputWrapper>

      <InputWrapper mt="2.5rem" label="Do you own a microphone?">
        <FlexWrapper wrap="wrap" gap="1.5rem" content="flex-start">
          <RadioButton
            name="microphone"
            value="Yes"
            label="Yes"
            onChange={(e) =>
              setPheripheral((prevState) => ({
                ...prevState,
                microphone: e.target.value,
              }))
            }
            checked={pheripheral.microphone === 'Yes'}
          />
          <RadioButton
            name="microphone"
            value="No"
            label="No"
            onChange={(e) =>
              setPheripheral((prevState) => ({
                ...prevState,
                microphone: e.target.value,
              }))
            }
            checked={pheripheral.microphone === 'No'}
          />
          <RadioButton
            name="microphone"
            value="No, but I am planning to buy in the future"
            label="No, but I am planning to buy in the future"
            onChange={(e) =>
              setPheripheral((prevState) => ({
                ...prevState,
                microphone: e.target.value,
              }))
            }
            checked={
              pheripheral.microphone ===
              'No, but I am planning to buy in the future'
            }
          />
        </FlexWrapper>
      </InputWrapper>

      <FlexWrapper content="flex-end" mt="2rem">
        <Button
          onClick={() => {
            setRemotePage(1)
            setReachRemotePage(1)
          }}
        >
          Go Back
        </Button>
        <Button
          className="filled"
          disabled={
            pheripheral.webCamera === '' ||
            pheripheral.headSet === '' ||
            pheripheral.microphone === ''
          }
          onClick={handleSubmit}
        >
          {loading ? 'Loading...' : 'Finish'}
        </Button>
      </FlexWrapper>
    </StyledScreen>
  )
}

export default RemoteScreenThree
