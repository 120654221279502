import React from 'react'

import styled from 'styled-components'
import { SlQuestion } from 'react-icons/sl'

import Modal from '../Modal'

const ConfirmApplication = ({ onConfirmApply }) => {
  return (
    <Modal btnTitle="Continue" btnFunc={onConfirmApply}>
      <StyledConfirmApplication>
        <SlQuestion className="question_icon" />
        <h1>Confirm Application</h1>
        <p>Clicking the Continue button will confirm your application.</p>
      </StyledConfirmApplication>
    </Modal>
  )
}

const StyledConfirmApplication = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  backgound: #fff;
  align-items: center;
  justify-content: center;

  .question_icon {
    font-size: 5rem;
    color: #ffa500;
    margin: 0.5rem;
  }

  h1,
  p {
    text-align: center;
  }
`

export default ConfirmApplication
