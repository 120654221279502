import { useEffect } from 'react'

import { Crisp } from 'crisp-sdk-web'

import { CRISP_WEBSITE_ID } from '../constants/constant'

const CrispChat = () => {
  let crisp = null

  useEffect(() => {
    crisp = Crisp.configure(CRISP_WEBSITE_ID)
  }, [])

  return crisp
}

export default CrispChat
