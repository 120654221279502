import React from 'react'

import styled from "styled-components";
import Jobs from './Jobs';
import Footer from '../../../components/Footer'

const JobWrapper = () => {
  return (
    <StyledJobWrapper>
      <Jobs />
      <Footer />
    </StyledJobWrapper>
  )
}

export default JobWrapper

const StyledJobWrapper = styled.div`
    width: 100%;
    max-width: 1165px;
    margin: 0 auto;
    padding: 0 15px;
    height: calc(100vh - 140px);
`