import React from 'react'

import styled from 'styled-components'

const Tag = ({ text }) => {
  return <StyledTag>{text}</StyledTag>
}

export default Tag

const StyledTag = styled.span`
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  border: 0.5px solid #31374f;
  border-radius: 100px;
`
