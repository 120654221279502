import React from 'react'

import styled from 'styled-components'

import { GrDrag } from 'react-icons/gr'
import { IoCloseOutline } from 'react-icons/io5'
import { useDrag } from 'react-dnd'
import { ItemTypes } from '../utils/items'

const DraggableItem = ({ item, id, noClose, handleDelete }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: {
      id,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  return (
    <StyledDraggableItem
      className="draggable"
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
      }}
    >
      <GrDrag className="icon" />
      <p>{item.item}</p>
      {!noClose && (
        <IoCloseOutline
          onClick={() => handleDelete(id)}
          className="icon close"
        />
      )}
    </StyledDraggableItem>
  )
}

export default DraggableItem

const StyledDraggableItem = styled.div`
  padding: 0.5rem;
  background: #f2f3f8;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: grab;
  transition: 0.3s ease-in all;

  @media (max-width: 768px) {
    display: none;
  }

  &:hover {
    border-left: 4px solid #31374f;
  }

  .icon {
    font-size: 0.75rem;
    color: #676e92;

    &.close {
      cursor: pointer;
    }
  }

  p {
    padding: 0 0.5rem;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    letter-spacing: 0.03em;
    white-space: nowrap;

    color: #0e1324;
  }
`
