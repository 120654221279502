import React, { useRef } from 'react'

import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized'

// Reference: https://www.youtube.com/watch?v=UrgfPjX97Yg

const VirtualizedList = ({
  data,
  className,
  children,
  defaultHeight = 100,
}) => {
  const cache = useRef(
    new CellMeasurerCache({ fixedWidth: true, defaultHeight: defaultHeight })
  )

  return (
    <AutoSizer>
      {({ width, height }) => (
        <List
          className={className}
          width={width}
          height={height}
          rowHeight={cache.current.rowHeight}
          deferredMeasurementCache={cache.current}
          rowCount={data.length}
          rowRenderer={({ key, index, style, parent }) => {
            const record = data[index]
            return (
              <CellMeasurer
                key={record.id || key}
                cache={cache.current}
                parent={parent}
                columnIndex={0}
                rowIndex={index}
              >
                <div style={style}>{children({ item: record })}</div>
              </CellMeasurer>
            )
          }}
        />
      )}
    </AutoSizer>
  )
}

export default VirtualizedList
