import React from 'react'

import styled from "styled-components";

import EmptyIcon from "../../../assets/icons/empty_search.svg"

export const SimpleEmptyState = () => {
    return (
        <StyledEmptyState>
            <img
                src={EmptyIcon}
                className="empty_icon"
                alt="empty icon"
            />

            <div className='empty_wrapper'>
                <p>Select a job to view details</p>
            </div>
        </StyledEmptyState>
    )
}

const EmptyState = ({ findJobBy }) => {
    return (
        <StyledEmptyState>
            <img
                src={EmptyIcon}
                className="empty_icon"
                alt="empty icon"
            />

            <div className='empty_wrapper'>
                <p>Sorry, we couldn’t find any results for {findJobBy}</p>

                <ul>
                    <li>Check your search for typos</li>
                    <li>Use more generic terms</li>
                    <li>The listing you’re searching for may be unavailable</li>
                    <li>Still need help? Send us a message <a href="#">here</a></li>
                </ul>
            </div>
        </StyledEmptyState>
    )
}

export default EmptyState

const StyledEmptyState = styled.div`
    width: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 305px;
    margin: 0 auto;

    .empty_icon {
        width: 211.85px;
        height: auto;
    }

    .empty_wrapper {
        margin-top: 3rem;

        p {
            font-size: 1rem;
            font-weight: 300;
            color: #31374F;
        }

        ul {
            margin-top: 1.5rem;

            li {
                font-size: 0.875rem;
                font-weight: 300;
                color: #31374F;
                margin-bottom: 0.875rem;
                margin-left: 1rem;

                a {
                    color: #1877F2;
                    text-decoration: none;
                }
            }
        }
    }
`