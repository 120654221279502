import React, { useContext, useEffect, useState } from 'react'
import { PagesContext } from '../../context/FormPagesContext'

import { FiPlus } from 'react-icons/fi'
import PhraseContainer from './PhraseContainer'
import RichTextbox from '../RichTextbox'

const PhraseWrapper = ({ className, errorMessage = '' }) => {
  const {
    userDetails,
    setUserDetails,
    summaryToggle,
    setSummaryToggle,
    setCheck,
    phrases,
    profesionalSum,
    setProfesionalSum,
  } = useContext(PagesContext)

  const [update, setUpdate] = useState(false)

  const [newPhrases, setNewPhrases] = useState([])

  useEffect(() => {
    setUserDetails((prevState) => ({
      ...prevState,
      professionalSummary: profesionalSum,
    }))
    setUpdate((prevState) => !prevState)
  }, [profesionalSum])

  useEffect(() => {
    setNewPhrases(() => {
      const updatedPhrase = phrases.filter(
        (phrase) =>
          !userDetails?.professionalSummary
            ?.toLowerCase()
            .includes(phrase.item.toLowerCase())
      )

      updatedPhrase.forEach((phrase) => {
        phrase.status = 'current'
      })

      return updatedPhrase
    })
  }, [update])

  return (
    <>
      <PhraseContainer
        summaryToggle={summaryToggle}
        newPhrases={newPhrases}
        setNewPhrases={setNewPhrases}
      />
      <label htmlFor="firstName">
        Professional Summary<span className="required">*</span>
      </label>
      <div className="moreInfo">
        <p>2-3 sentences that describe your professional experience</p>
        <button
          onClick={() => {
            setSummaryToggle(!summaryToggle)
            setCheck(false)
          }}
          className="keywordBtn"
        >
          Pre-written Phrases
          <span>
            <FiPlus className="icon" />
          </span>
        </button>
      </div>
      <RichTextbox
        content={profesionalSum}
        setContent={setProfesionalSum}
        className={className}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default PhraseWrapper
