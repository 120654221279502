import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { IoIosClose } from 'react-icons/io'

import OutsideClickHandler from 'react-outside-click-handler'

import styled from 'styled-components'
import { StyledSoftSkill } from '../../pages/onboarding/Skill'

const MultiSelectDropdown = ({
  options,
  selected,
  setSelected,
  loading,
  disabled,
  handleClick,
  limit,
}) => {
  const [active, setActive] = useState(false)
  const [noOption, setNoOption] = useState(false)
  const [newOptions, setNewOptions] = useState([])

  const handleSelect = (e, item) => {
    if (selected.length === limit) {
      toast.error(`You can't add more than ${limit} items`)
      return
    }

    const opts = [...newOptions]
    const idx = newOptions.findIndex((x) => x.id === item.id)
    opts[idx].checked = !opts[idx].checked
    setNewOptions(opts)

    const selectedIdx = selected.findIndex((x) => x.id === item.id)
    if (selectedIdx >= 0) {
      setSelected((prevState) => prevState.filter((x) => x.id !== item.id))
    } else {
      setSelected([...selected, item])
    }
  }

  const handleChange = (e) => {
    e.preventDefault()

    const value = e.target.value
    setActive(true)
    const filteredOptions = options.filter((item) =>
      item.value.toLowerCase().includes(value.toLowerCase())
    )

    if (filteredOptions.length > 0) {
      setNoOption(false)
    } else {
      setNoOption(true)
    }

    setNewOptions(filteredOptions)
  }

  useEffect(() => {
    const opts = [...options]
    if (selected && selected.length > 0 && options.length > 0) {
      opts.forEach((item) => {
        const idx = selected?.findIndex(
          (x) => x.value.toLowerCase() === item.value.toLowerCase()
        )
        item.checked = false
        if (idx >= 0) {
          item.checked = true
        }
      })
    }
    setNoOption(opts?.length === 0)
    setNewOptions(opts)
  }, [options])

  return (
    <OutsideClickHandler onOutsideClick={() => setActive(false)}>
      <StyleMultiSelectDropdown>
        <div className="selectable-input" onClick={() => setActive(!active)}>
          {selected?.map(({ label, id }) => {
            return (
              <StyledSoftSkill key={id}>
                <span>{label}</span>
                <IoIosClose onClick={() => handleClick(id)} className="icon" />
              </StyledSoftSkill>
            )
          })}
          <input
            className="inputNew"
            type="text"
            placeholder="Press enter to add..."
            onChange={handleChange}
            onKeyPress={() => setActive(true)}
            disabled={disabled}
          />
        </div>

        <div className={active ? 'newDropdown active' : 'newDropdown'}>
          {loading && (
            <p
              style={{ fontSize: '.75rem', fontWeight: '400', padding: '2rem' }}
            >
              Loading...
            </p>
          )}

          {noOption ? (
            <p
              style={{ fontSize: '.75rem', fontWeight: '400', padding: '2rem' }}
            >
              No option with found
            </p>
          ) : (
            <>
              {newOptions
                ?.sort(function (a, b) {
                  if (a.label < b.label) {
                    return -1
                  }
                  if (a.label > b.label) {
                    return 1
                  }
                  return 0
                })
                .map((item) => (
                  <label
                    htmlFor={`item ${item.id}`}
                    className="option"
                    key={item.id}
                  >
                    <input
                      type="checkbox"
                      className="checked"
                      checked={item?.checked}
                      name="item"
                      onChange={(e) => handleSelect(e, item)}
                      value={item.label}
                      id={`item ${item.id}`}
                    />
                    <span>{item.label}</span>
                  </label>
                ))}
            </>
          )}
        </div>
      </StyleMultiSelectDropdown>
      <Toaster position="top-right" reverseOrder={false} />
    </OutsideClickHandler>
  )
}

export default MultiSelectDropdown

const StyleMultiSelectDropdown = styled.div`
  position: relative;
  width: 100%;
  min-height: 2.625rem;
  border-radius: 3px;
  border: 1px solid #aeb3c9;
  padding: 0.5rem;

  .selectable-input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .newDropdown {
    position: absolute;
    top: 110%;
    left: 0;
    width: 100%;
    height: 174px;
    background: #fff;
    box-shadow: 3px 3px 7px rgba(178, 178, 203, 0.2);
    border-radius: 3px;
    overflow-y: scroll;
    z-index: 1;
    padding: 1.2rem 0;
    display: none;

    &.active {
      display: block;
    }

    .option {
      display: flex;
      align-items: center;
      padding: 0.5rem 1.2rem;
      cursor: pointer;
      color: #0e1324;
      font-size: 0.75rem;

      &:hover {
        background: #0e1324;
        color: #fff;
      }

      .checked {
        width: 1rem;
        height: 1rem;
        accent-color: #0e1324;
        margin-right: 1rem;
      }
    }
  }

  .inputNew {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: transparent;
    font-size: 0.75rem;
    color: #0e1324;
    padding: 0 0.5rem;

    &::placeholder {
      color: #858aa0;
    }
  }
`
