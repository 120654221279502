import React, { useContext } from 'react'

import styled from 'styled-components'
import { AiFillCloseCircle } from 'react-icons/ai'

import CvWrapper from './CvWrapper'

import { ModalContext } from '../../../context/ModalContext'

const DownloadCVModal = () => {
  const { closeModal, setCloseModal } = useContext(ModalContext)

  return (
    <StyledDownloadCVModal className={closeModal ? 'show' : ''}>
      <div onClick={() => setCloseModal(false)} className="overlay" />
      <div className="content">
        <AiFillCloseCircle
          onClick={() => setCloseModal(false)}
          className="closeIcon"
        />
        <CvWrapper />
      </div>
    </StyledDownloadCVModal>
  )
}

export default DownloadCVModal

const StyledDownloadCVModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  max-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
  display: none;
  justify-content: center;
  align-items: flex-start;
  padding: 0 2rem;
  padding-top: 5rem;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }

  &.show {
    display: flex;
  }

  .closeIcon {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 12;
    width: 2rem;
    height: 2rem;
    color: red;
    cursor: pointer;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    max-width: 1582px;
    z-index: 1;
    background-color: #fff;
    padding: 2.5rem 2rem;

    @media (max-width: 768px) {
      padding: 2rem 1.5rem;
    }
  }
`
