import React from 'react'

import styled from 'styled-components'

import { P } from '../../../styles/Typograph.styles'
import TemplateWrapper from './templates/TemplateWrapper'

const Preview = ({ downloadRef, cvDetails }) => {
  return (
    <StyledPreview>
      <P size=".875rem" color="#31374F">
        Preview
      </P>
      <TemplateWrapper cvDetails={cvDetails} downloadRef={downloadRef} />
    </StyledPreview>
  )
}

export default Preview

const StyledPreview = styled.div`
  width: 100%;
`
