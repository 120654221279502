import React, { useContext, useEffect, useRef, useState, useMemo } from 'react'

import styled from 'styled-components'
import countries from 'all-countries-and-cities-json'
import toast, { Toaster } from 'react-hot-toast'
import PhoneInput from 'react-phone-input-2'
import Joi from 'joi'

import 'react-phone-input-2/lib/style.css'

import ScrollToTop from '../../components/ScrollRestoration'
import HeadLinesForm from '../../components/HeadLinesForm'
import PhraseContainer from '../../components/onboardingUpdate/PhraseContainer'
import PhraseWrapper from '../../components/onboardingUpdate/PhraseWrapper'

import { PagesContext } from '../../context/FormPagesContext'

import { getFullName, getFromLocalStorage, pickObject } from '../../utils/util'

import avatar from '../../assets/images/avatar-temp.png'

/**
 * First step in cv creation process. Insert/updates the following fields:
 * - First Name
 * - Middle Name
 * - Last Name
 * - Professional Headline
 * - Professional Summary
 * - Contact Number
 * - Country
 * - City
 * - Street Address
 * - Zip Code
 *
 */
const PersonalInfo = () => {
  const {
    setPage,
    userDetails,
    setUserDetails,
    summaryToggle,
    setSummaryToggle,
    setFile,
    setReachPage,
    cvUserData,
    setProfesionalSum,
  } = useContext(PagesContext)

  const [cities, setCities] = useState([])
  const imgLink = getFromLocalStorage('profileImgLink')
  const [, setLoading] = useState(false)
  const [fieldErrors, setFieldErrors] = useState({})

  const imageRef = useRef(null)
  const avatarRef = useRef(null)
  const formRef = useRef(null)

  let applicantCVData = localStorage.getItem('applicant-cv-data')
  applicantCVData = JSON.parse(applicantCVData)

  const applicantCVMeta = JSON.parse(applicantCVData.meta)

  let applicantData = localStorage.getItem('applicant-data')
  applicantData = JSON.parse(applicantData)

  const validationSchema = useMemo(() => {
    return Joi.object({
      first_name: Joi.string().label('First Name').required(),
      last_name: Joi.string().label('Last Name').required(),
      professionalHeadlines: Joi.array().label('Professional Headlines').min(1),
      professionalSummary: Joi.string()
        .label('Professional Summary')
        .required()
        .custom((value, helpers) => {
          // Check if the value is empty or contains only an empty paragraph
          if (value.trim() === '<p><br></p>') {
            return helpers.error('any.invalid', {
              label: 'Professional Summary',
            })
          }
          return value
        })
        .messages({
          'any.required': '"Professional Summary" is required',
          'any.invalid': '"Professional Summary" is not allowed to be empty',
        }),
      contact: Joi.string().label('Contact Number').required(),
      city: Joi.string().label('City').required(),
      country: Joi.string()
        .label('Country')
        .required()
        .custom((value, helpers) => {
          if (value.trim() === 'Choose Option') {
            return helpers.error('any.invalid', {
              label: 'Country',
            })
          }
          return value
        })
        .messages({
          'any.required': '"Country" is required',
          'any.invalid': 'Please select a "country"',
        }),
      streetAddress: Joi.string().label('Address').required(),
      ZIPCode: Joi.string().label('ZIP Code').required(),
    })
  }, [])

  /**
   * Updates the user details by adding a new professional headline.
   *
   * @param {string} headline - The new professional headline to be added.
   * @return {void}
   */
  const onChangeHeadlines = (headline) => {
    setUserDetails({
      ...userDetails,
      professionalHeadlines: [...userDetails.professionalHeadlines, headline],
    })
  }

  /**
   * Updates the user details based on the selected country and sets the cities accordingly.
   *
   * @param {Event} count - The event object representing the change in the selected country.
   * @return {void} This function does not return a value.
   */
  const handleCountryChange = (count) => {
    const country = count.target.value
    setUserDetails({ ...userDetails, country })

    // check is there is an array with the key of country
    for (const [key, value] of Object.entries(countries)) {
      if (key === country) {
        setCities(value)
      }
    }
  }

  useEffect(() => {
    setUserDetails((prevState) => ({
      ...prevState,
      first_name: applicantCVData.first_name,
      middle_name: applicantCVData.middle_name,
      last_name: applicantCVData.last_name,
      professionalSummary: applicantCVData.professional_summary,
      contact: applicantData.phone_number,
      country: applicantData.country,
      city: applicantData.city,
      streetAddress: applicantCVMeta?.streetAddress,
      ZIPCode: applicantCVMeta?.ZIPCode,
    }))
    setProfesionalSum(applicantCVData.professional_summary)
  }, [])

  useEffect(() => {
    for (const [key, value] of Object.entries(countries)) {
      if (key === userDetails.country) {
        setCities(value)
      }
    }
  }, [userDetails.country])

  /**
   * Handles the image upload event.
   *
   * @param {Event} e - The event object for the image upload.
   */
  const handleImageUpload = (e) => {
    const file = e.target.files[0]
    setLoading(true)

    // check if file is an image
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      toast.error('Please upload a valid image')
    } else if (file.size > 2000000) {
      toast.error('Image too big (max 3MB)')
    } else {
      if (imageRef.current.files[0]) {
        const fileReader = new FileReader()

        fileReader.onload = function (event) {
          avatarRef.current.src = event.target.result

          const link = event.target.result
          setUserDetails((prevState) => ({ ...prevState, cvImage: link }))
          localStorage.setItem('profileImgLink', event.target.result)
        }

        fileReader.readAsDataURL(imageRef.current.files[0])

        setFile(e.target.files[0])
      }
    }
  }

  /**
   * Handles the next step in the cv creation process.
   *
   * @return {void} This function does not return a value.
   */
  const handleNext = () => {
    const fields = [
      'first_name',
      'last_name',
      'professionalSummary',
      'professionalHeadlines',
      'contact',
      'city',
      'country',
      'streetAddress',
      'ZIPCode',
    ]

    const formFields = pickObject(userDetails, fields)
    const result = validationSchema.validate(formFields, {
      abortEarly: false,
    })

    if (!result.error) {
      const userData = localStorage.getItem('applicant-data')
        ? JSON.parse(localStorage.getItem('applicant-data'))
        : null
      const cvData =
        localStorage.getItem('applicant-cv-data') &&
        localStorage.getItem('applicant-cv-data') !== 'undefined'
          ? JSON.parse(localStorage.getItem('applicant-cv-data'))
          : null
      const oldMeta = cvData && cvData.meta ? JSON.parse(cvData.meta) : {}
      const meta = cvUserData
        ? {
            ...oldMeta,
            streetAddress: userDetails.streetAddress,
            ZIPCode: userDetails.ZIPCode,
          }
        : {
            ...oldMeta,
            streetAddress: userDetails.streetAddress,
            ZIPCode: userDetails.ZIPCode,
          }

      setPage(0.4)
      setReachPage(0.4)
      localStorage.setItem('page', 0.4)
      localStorage.setItem('reachPage', 0.4)
      localStorage.setItem(
        'applicant-data',
        JSON.stringify({
          ...userData,
          city: userDetails.city,
          country: userDetails.country,
          phone_number: userDetails.contact,
          streetAddress: userDetails.streetAddress,
        })
      )
      localStorage.setItem(
        'applicant-cv-data',
        JSON.stringify({
          ...cvData,
          professional_headline: JSON.stringify(
            userDetails.professionalHeadlines
          ),
          professional_summary: JSON.stringify(userDetails.professionalSummary),
          meta: JSON.stringify(meta),
          first_name: userDetails?.first_name,
          middle_name: userDetails?.middle_name,
          last_name: userDetails?.last_name,
          name: getFullName(
            userDetails?.first_name,
            userDetails?.middle_name,
            userDetails?.last_name
          ),
        })
      )
    } else {
      const validationErrors = result.error.details.reduce((acc, curr) => {
        acc[curr.context.key] = curr.message.replace(/"/g, '')
        return acc
      }, {})

      setFieldErrors(validationErrors)
      toast.error('Please correct the validation errors before proceeding.')
    }
  }

  return (
    <StyledPersonalInfo>
      <ScrollToTop />
      <h2>Personal Information</h2>
      <div className="grouped-input">
        <div className="input-group">
          <div className="input-wrapper">
            <label htmlFor="firstName">
              First Name<span className="required">*</span>
            </label>
            <input
              className={`new ${'first_name' in fieldErrors && 'has-error'}`}
              placeholder="First Name"
              type="text"
              id="firstName"
              onChange={(e) =>
                setUserDetails({ ...userDetails, first_name: e.target.value })
              }
              value={userDetails.first_name}
            />
            {'first_name' in fieldErrors && (
              <sub style={{ color: 'red' }}>{fieldErrors.first_name}</sub>
            )}
          </div>
          <div className="input-wrapper">
            <label htmlFor="middleName">Middle Name</label>
            <input
              className="new"
              placeholder="Middle Name"
              type="text"
              id="middleName"
              onChange={(e) =>
                setUserDetails({ ...userDetails, middle_name: e.target.value })
              }
              value={userDetails.middle_name}
            />
          </div>
          <div className="input-wrapper">
            <label htmlFor="lastName">
              Last Name<span className="required">*</span>
            </label>
            <input
              className={`new ${'last_name' in fieldErrors && 'has-error'}`}
              placeholder="Last Name"
              type="text"
              id="lastName"
              onChange={(e) =>
                setUserDetails({ ...userDetails, last_name: e.target.value })
              }
              value={userDetails.last_name}
            />
            {'last_name' in fieldErrors && (
              <sub style={{ color: 'red' }}>{fieldErrors.last_name}</sub>
            )}
          </div>
        </div>

        <div className="img-upload">
          <form ref={formRef} name="imageUpload" className="userImage">
            <label htmlFor="userImage">
              <img
                className="avatar"
                ref={avatarRef}
                src={imgLink || avatar}
                alt="uploaded image"
              />
            </label>
            <input
              className="input"
              type="file"
              name="profileImage"
              ref={imageRef}
              hidden
              id="userImage"
              onChange={handleImageUpload}
            />
          </form>
          <p>Upload CV Photo</p>
        </div>
      </div>

      <HeadLinesForm
        className={'professionalHeadlines' in fieldErrors && 'has-error'}
        onChangeHeadlines={onChangeHeadlines}
        headlines={userDetails.professionalHeadlines}
        errorMessage={
          'professionalHeadlines' in fieldErrors &&
          fieldErrors.professionalHeadlines
        }
      />

      <div className="inputs">
        <PhraseWrapper
          className={'professionalSummary' in fieldErrors && 'has-error'}
          errorMessage={
            'professionalSummary' in fieldErrors &&
            fieldErrors.professionalSummary
          }
        />
        {summaryToggle && (
          <Overlay onClick={() => setSummaryToggle(!summaryToggle)} />
        )}
        {summaryToggle && <PhraseContainer />}
      </div>
      <div className="inputs">
        <label htmlFor="firstName">
          Contact Number<span className="required">*</span>
        </label>

        <PhoneInput
          inputStyle={{ borderColor: 'contact' in fieldErrors && 'red' }}
          country={'ph'}
          value={userDetails.contact}
          onChange={(phone) => {
            setUserDetails({ ...userDetails, contact: phone })
          }}
        />
        {'contact' in fieldErrors && (
          <sub style={{ color: 'red' }}>{fieldErrors.contact}</sub>
        )}
      </div>
      <div className="inputs">
        <label htmlFor="country">
          Country/State<span className="required">*</span>
        </label>

        <select
          style={{ borderColor: 'country' in fieldErrors && 'red' }}
          value={userDetails.country}
          onChange={handleCountryChange}
          className="select"
          name=""
          id="country"
        >
          <option>Choose Option</option>
          {Object.keys(countries).map((country, index) => (
            <option key={index} value={country}>
              {country}
            </option>
          ))}
        </select>
        {'country' in fieldErrors && (
          <sub style={{ color: 'red' }}>{fieldErrors.country}</sub>
        )}
      </div>
      <div className="inputs">
        <label htmlFor="city">
          City/Municipality<span className="required">*</span>
        </label>

        <select
          style={{ borderColor: 'city' in fieldErrors && 'red' }}
          value={userDetails.city}
          onChange={(e) =>
            setUserDetails({ ...userDetails, city: e.target.value })
          }
          className="select"
          name=""
          id="city"
        >
          <option>
            {userDetails.city ? userDetails.city : 'Choose Option'}
          </option>
          {cities?.map((city, index) => (
            <option key={index} value={city}>
              {city}
            </option>
          ))}
        </select>
        {'city' in fieldErrors && (
          <sub style={{ color: 'red' }}>{fieldErrors.city}</sub>
        )}
      </div>
      <div className="inputs">
        <label htmlFor="streetAddress">
          Address<span className="required">*</span>
        </label>

        <input
          className={`input ${'streetAddress' in fieldErrors && 'has-error'}`}
          type="text"
          id="streetAddress"
          value={userDetails.streetAddress}
          placeholder="Address"
          onChange={(e) =>
            setUserDetails({ ...userDetails, streetAddress: e.target.value })
          }
        />
        {'streetAddress' in fieldErrors && (
          <sub style={{ color: 'red' }}>{fieldErrors.streetAddress}</sub>
        )}
      </div>
      <div className="inputs">
        <label htmlFor="zipCode">
          ZIP Code<span className="required">*</span>
        </label>

        <input
          id="zipCode"
          onChange={(e) =>
            setUserDetails({ ...userDetails, ZIPCode: e.target.value })
          }
          className={`input ${'ZIPCode' in fieldErrors && 'has-error'}`}
          type="text"
          placeholder="ZIP code"
          value={userDetails.ZIPCode}
        />
        {'ZIPCode' in fieldErrors && (
          <sub style={{ color: 'red' }}>{fieldErrors.ZIPCode}</sub>
        )}
      </div>

      <Toaster position="top-right" reverseOrder={false} />

      <div className="buttonContainer">
        <button
          className="btn"
          onClick={() => {
            handleNext()
          }}
        >
          Next Step
        </button>
      </div>
    </StyledPersonalInfo>
  )
}

export default PersonalInfo

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  z-index: 1;
`

export const StyledPersonalInfo = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 2rem;
    width: 100%;
  }

  .avatar {
    cursor: pointer;
  }

  .lightIcon {
    font-size: 1rem;
    color: #858aa0;
    cursor: pointer;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: 5%;
    color: #31374f;
  }

  .toggleBtnWrapper {
    margin: 2rem 0;
    display: flex;
    align-items: center;
  }

  .data-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    margin-top: 2rem;

    .react-datepicker-wrapper {
      display: block;
      margin-top: 1rem;
      width: 100%;

      .react-datepicker__input-container {
        width: 100%;

        input {
          width: 100%;
          height: 2.5rem;
          border-radius: 3px;
          border: 1px solid #aeb3c9;
          padding: 0 1rem;
          outline: none;
        }
      }
    }
  }

  .addContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 3rem;

    .btn {
      padding: 0.75rem 1.5rem;
      border-radius: 100px;
      background: none;
      border: 1px solid #ff7b9b;
      outline: none;
      color: #ff7b9b;
      font-size: 0.75rem;
      font-weight: 300;
      letter-spacing: 0.05em;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background: #ff7b9b;
        color: #fff;
      }

      &:disabled {
        border: 1px solid #aeb3c9;
        color: #aeb3c9;
      }

      &.filled {
        background: #ff7b9b;
        color: #fff;
        margin-left: 1rem;

        &:hover {
          background: none;
          color: #ff7b9b;
        }
      }
    }
  }

  .inputs {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;

    &.mt {
      margin-top: 1.5rem;
    }

    &.rad {
      margin-bottom: 2rem;
    }

    label {
      font-size: 0.7rem;
      font-weight: 300;
      color: #0e1324;
      letter-spacing: 3%;
      margin-bottom: 0.7rem;
      line-height: 1.7;

      &.small-subtitle {
        font-weight: 300;
        color: #0e1324;
        letter-spacing: 0.03em;
        line-height: 1.5rem;
      }
    }

    .css-1rhbuit-multiValue {
      background: #f2f3f8;
      border-radius: 3px;
      font-size: 0.75rem;
      padding: 0.3rem 0.4rem;
    }

    .select,
    .input {
      display: block;
      margin-top: 0.7rem;
      width: 100%;
      height: 2.5rem;
      border-radius: 3px;
      border: 1px solid #aeb3c9;
      padding: 0 1rem;
      outline: none;
      color: #858aa0;
      cursor: pointer;
      font-size: 0.875rem;

      &.sm {
        height: 2rem;
        margin-top: 0;
        margin-left: 0.75rem;
        min-width: 200px;

        @media (max-width: 768px) {
          min-width: 100%;
        }
      }

      &.unique {
        border: none;
        margin-top: 0;
        height: 100%;
      }

      &::placeholder {
        color: #858aa0;
        font-size: 0.875rem;
        font-weight: 300;
      }

      &.has-error {
        border-color: red;
      }
    }

    .react-tel-input {
      width: 100%;
      margin-top: 0.7rem;
      border-radius: 3px;

      .form-control {
        width: 100%;
        height: 2.5rem;
        border: 1px solid #aeb3c9;
        border-radius: 3px;
      }
    }

    .moreInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 0.7rem;
        font-weight: 300;
        color: #31374f;
        letter-spacing: 3%;
      }
    }

    .inputWrapper {
      position: relative;
      width: 100%;
      min-height: 60px;
      border: 1px solid #aeb3c9;
      border-radius: 3px;
      padding: 0.875rem;
      margin-top: 0.5rem;

      .cover {
        width: 100%;
        min-height: 100px;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 1;
      }

      .ck.ck-toolbar {
        background: none;
        border: none;
      }

      .ck.ck-toolbar__items {
        flex-wrap: wrap;
      }

      .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
      .ck.ck-editor__main > .ck-editor__editable.ck-focused {
        font-size: 0.75rem;
        color: #0e1324;
        font-weight: 300;
        letter-spacing: 0.03em;
        line-height: 1.5;
        border: none;
        outline: none;
        box-shadow: none;
      }

      .ck.ck-editor__editable_inline > :last-child {
        margin-bottom: var(--ck-spacing-large);
        word-break: break-word;
      }

      &.flex {
        display: flex;
        align-items: center;
        padding-bottom: 0.375rem;
      }

      input.new {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        font-size: 0.7rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  .radioBtns {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    gap: 2rem;

    &.row {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;
      gap: 1rem;
    }

    .radio {
      display: flex;
      align-items: center;

      .row {
        display: flex;
        align-items: center;
      }

      .radio-input {
        opacity: 1;
        accent-color: #ff7b9b;
        width: 19px;
        height: 19px;
      }

      label {
        margin: 0;
        padding: 0;
        margin-left: 10px;
      }
    }
  }

  .grouped-input {
    display: flex;
    align-items: flex-start;
    margin-top: 3rem;

    .img-upload {
      width: 122px;

      .userImage {
        width: 100%;
        height: 122px;
        border-radius: 50%;
        overflow: hidden;
        background: #d9d9ef;
        transition: all 0.3s ease;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &:hover {
          background: #676e92;
        }

        label {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .icon {
            font-size: 3rem;
            color: #fff;
          }
        }
      }

      p {
        font-size: 0.8rem;
        font-weight: 300;
        letter-spacing: 3%;
        text-align: center;
        margin-top: 10px;
      }
    }

    .input-group {
      flex-grow: 1;
      margin-right: 3rem;

      .input-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 1.5rem;

        label {
          font-size: 0.875rem;
          font-weight: 300;
          letter-spacing: 3%;
          margin-bottom: 8px;

          span {
            color: red;
          }
        }

        input.new {
          width: 100%;
          height: 2.5rem;
          border: 1px solid #aeb3c9;
          border-radius: 3px;
          padding: 0 1rem;
          font-size: 0.875rem;
          color: #31374f;
          outline: none;
          background: none;
        }

        input.has-error {
          border-color: red;
        }
      }
    }
  }

  button.keywordBtn {
    background: none;
    outline: none;
    border: none;
    font-size: 0.7rem;
    font-weight: 500;
    color: #858aa0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;

    &.remove {
      color: red;
    }

    &.mt {
      margin-top: 1rem;
    }

    &:hover {
      color: #31374f;
    }

    span {
      width: 0.875rem;
      height: 0.875rem;
      border-radius: 50%;
      background: #ffe2e9;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.5rem;

      &.mr {
        margin-left: 0;
        margin-right: 0.5rem;
      }

      .icon {
        font-size: 0.7rem;
        color: #fd2055;
      }
    }
  }

  .css-14el2xx-placeholder,
  .css-1wa3eu0-placeholder,
  .ck-placeholder {
    color: #858aa0;
    font-size: 0.875rem;
    font-weight: 300;
  }

  .required {
    color: red;
  }

  .mb-2 {
    margin-bottom: 2rem;
  }

  .small-subtitle {
    font-weight: 300;
    color: #0e1324;
    letter-spacing: 0.03em;
    line-height: 1.5rem;
    font-weight: 300;
  }

  .react-datepicker__input-container input:disabled,
  .css-1fhf3k1-control,
  .inputs input:disabled {
    font-weight: 300;
    color: #aeb3c9;
    font-size: 0.875rem;
    background: #f9faff;
    border: 1px solid #aeb3c9;
  }

  .css-1uccc91-singleValue {
    color: #31374f;
    font-weight: 300;
    font-size: 0.875rem;
  }

  .css-yk16xz-control {
    border: 1px solid #858aa0;
  }
`
