import React from 'react'

import styled from 'styled-components'
import { BiChevronDown } from 'react-icons/bi'
import { RxCross2 } from 'react-icons/rx'


const FilterTag = ({ label, text, onClick, onDelete, clearFilter }) => {
    const handleDeleteClick = (e) => {
        e.stopPropagation()
        onDelete()
    }

    const className = !text || text === 'null' ? 'inactive' : 'active'

    return <StyledFilterTag onClick={onClick} className={className}>
        <div className='filter-text'>{!text || text === 'null' ? label : text}</div>
        {!clearFilter && <span>{!text || text === 'null' ? <BiChevronDown className='icon' /> : <RxCross2 className='deleteIcon' onClick={handleDeleteClick} />}</span>}
    </StyledFilterTag>
}

const StyledFilterTag = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 500;
    padding: 0.5rem 0.75rem;
    border: 0.5px solid #ACB2CC;
    border-radius: 100px;
    white-space: nowrap;
    width: auto;

    &:hover {
        background-color: #F3F5FC;
    }

    &.active {
        border-color: #1877F2;
        color: #1877F2;
    }

    .filter-text {
        margin-right: 5px;
    }

    .icon {
        font-size: 20px;
        cursor: pointer;
    }

    .deleteIcon {
        margin-top: 3px;
        font-size: 15px;
        cursor: pointer;
    }
`

export default FilterTag
