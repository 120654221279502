import React, { useContext, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { IoIosClose } from 'react-icons/io'

import styled from 'styled-components'
import { PagesContext } from '../../context/FormPagesContext'
import { StyledSoftSkill } from '../../pages/onboarding/Skill'

import OutsideClickHandler from 'react-outside-click-handler'

const SelectableInput = ({
  newOption,
  setNewOption,
  loading,
  disabled,
  className,
  errorMessage = '',
}) => {
  const { skills, option, setSkills } = useContext(PagesContext)

  const [active, setActive] = useState(false)
  const [noSkill, setNoSkill] = useState(false)

  const handleChange = (e) => {
    e.preventDefault()

    const value = e.target.value

    setActive(true)

    if (
      option.filter((item) =>
        item.value.toLowerCase().includes(value.toLowerCase())
      ).length > 0
    ) {
      setNoSkill(false)
    } else {
      setNoSkill(true)
    }

    setNewOption(() =>
      option.filter((item) =>
        item.value.toLowerCase().includes(value.toLowerCase())
      )
    )
  }

  const handleSelect = (e, item) => {
    const filterNewOption = newOption.filter((option) => option.id !== item.id)
    const updatedOption = item
    // check if checkbox is checked
    if (e.target.checked) {
      // add to newOption
      if (skills.length < 5) {
        updatedOption.checked = true
        setSkills((prevState) => [...prevState, item])
      } else {
        updatedOption.checked = false
        toast.error("You can't add more than 5 skills")
        return null
      }
    } else {
      updatedOption.checked = false
      // remove from newOption
      setSkills((prevState) =>
        prevState.filter((skill) => skill.skill !== item.skill)
      )
    }

    setNewOption([...filterNewOption, updatedOption])
  }

  const handleClick = (id) => {
    const isInSkills = skills.filter((skill) => skill.id === id)
    const selectedSkill = newOption.find((item) => id === item.id)
    const otherSkills = newOption.filter((item) => id !== item.id)

    if (selectedSkill?.checked || isInSkills.length) {
      selectedSkill.checked = false
    } else {
      selectedSkill.checked = true
    }
    setActive(true)

    const clonedSkills = [...skills]

    setSkills(clonedSkills.filter((skill) => skill.id !== id))
    setNewOption([...otherSkills, selectedSkill])
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setActive(false)}>
      <StyledSelectableInput className={className}>
        <div className="selectable-input" onClick={() => setActive(!active)}>
          {skills.map(({ skill, id }) => {
            return (
              <StyledSoftSkill key={id}>
                <span>{skill}</span>

                <IoIosClose onClick={() => handleClick(id)} className="icon" />
              </StyledSoftSkill>
            )
          })}
          <input
            className="inputNew"
            type="text"
            placeholder="Press enter to add a skill..."
            onChange={handleChange}
            onKeyPress={() => setActive(true)}
            disabled={disabled}
          />
        </div>

        <div className={active ? 'newDropdown active' : 'newDropdown'}>
          {loading && (
            <p
              style={{ fontSize: '.75rem', fontWeight: '400', padding: '2rem' }}
            >
              Loading...
            </p>
          )}

          {noSkill ? (
            <p
              style={{ fontSize: '.75rem', fontWeight: '400', padding: '2rem' }}
            >
              No Skill with found
            </p>
          ) : (
            <>
              {newOption
                ?.sort(function (a, b) {
                  if (a.label < b.label) {
                    return -1
                  }
                  if (a.label > b.label) {
                    return 1
                  }
                  return 0
                })
                .map((item) => (
                  <label
                    htmlFor={`skill ${item.id}`}
                    className="option"
                    key={item.id}
                  >
                    <input
                      type="checkbox"
                      className="checked"
                      checked={item?.checked}
                      name="skill"
                      onChange={(e) => handleSelect(e, item)}
                      value={item.label}
                      id={`skill ${item.id}`}
                    />
                    <span>{item.label}</span>
                  </label>
                ))}
            </>
          )}
        </div>
      </StyledSelectableInput>
      {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
      <Toaster position="top-right" reverseOrder={false} />
    </OutsideClickHandler>
  )
}

export default SelectableInput

const StyledSelectableInput = styled.div`
  position: relative;
  width: 100%;
  min-height: 2.625rem;
  border-radius: 3px;
  border: 1px solid #aeb3c9;
  /* border: ${({ className }) =>
    className === 'has-error' ? '1px solid red' : '1px solid #aeb3c9'}; */
  padding: 0.5rem;

  &.has-error {
    border-color: red;
  }

  .selectable-input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .newDropdown {
    position: absolute;
    top: 110%;
    left: 0;
    width: 100%;
    height: 174px;
    background: #fff;
    box-shadow: 3px 3px 7px rgba(178, 178, 203, 0.2);
    border-radius: 3px;
    overflow-y: scroll;
    z-index: 1;
    padding: 1.2rem 0;
    display: none;

    &.active {
      display: block;
    }

    .option {
      display: flex;
      align-items: center;
      padding: 0.5rem 1.2rem;
      cursor: pointer;
      color: #0e1324;
      font-size: 0.75rem;

      &:hover {
        background: #0e1324;
        color: #fff;
      }

      .checked {
        width: 1rem;
        height: 1rem;
        accent-color: #0e1324;
        margin-right: 1rem;
      }
    }
  }

  .inputNew {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: transparent;
    font-size: 0.75rem;
    color: #0e1324;
    padding: 0 0.5rem;

    &::placeholder {
      color: #858aa0;
    }
  }
`
const StyledErrorMessage = styled.sub`
  color: red;
`
