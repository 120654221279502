import React from 'react'

import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import Loader from 'react-loader-spinner'

import AuthLayouts from '../layouts/AuthLayouts'

import Error from '../components/Error'
import ScrollToTop from '../components/ScrollRestoration'
import { Button } from '../components/Button'
import { PasswordInput } from '../components/Input'
import { LoginContainer } from './Login'

import { baseUrl } from '../constants/constant'

const ResetPassword = () => {
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')

  const { token } = useParams()
  const history = useHistory()
  const [error, setError] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  // split token with '-' and remove the last part of the array and join the array with '-'
  const tokenArray = token.split('-')
  const newToken = tokenArray.slice(1, tokenArray.length).join('-')
  const userId = tokenArray[0]

  const handleSubmit = (e) => {
    e.preventDefault()
    if (password === confirmPassword && password.length > 0) {
      if (
        password.length > 7 &&
        password.match('^(?=.*[0-9])') &&
        password.match('^(?=.*[0-9])') &&
        password.match('^(?=.*[a-z])(?=.*[A-Z])')
      ) {
        setIsLoading(true)
        axios
          .post(`${baseUrl}/change-password`, {
            userId,
            newPassword: password,
            code: newToken,
          })
          .then(() => {
            setIsLoading(false)
            history.push('/forget-password/success')
          })
          .catch((err) => {
            setIsLoading(false)
            setError(err.response.data)
          })
      } else {
        setError(
          'password must be at least 8 characters long and contain at least one number, one uppercase letter and one lowercase letter'
        )
      }
    } else {
      setError('Password and Confirm password does not match')
    }
  }

  return (
    <AuthLayouts>
      <ScrollToTop />
      <LoginContainer>
        <h2>Change your password</h2>
        {error !== '' && <Error error={error} />}
        <PasswordInput
          value={password}
          onChange={setPassword}
          title="New Password"
        />
        <PasswordInput
          value={confirmPassword}
          onChange={(value) => setConfirmPassword(value)}
          title="Confirm Password"
        />
        <Button handleClick={handleSubmit}>
          {isLoading ? (
            <Loader type="ThreeDots" color="#FFF" height={32} width={32} />
          ) : (
            'Change Password'
          )}
        </Button>
      </LoginContainer>
    </AuthLayouts>
  )
}

export default ResetPassword
