import React from 'react'

import styled from 'styled-components'

const CVImage = ({ url, pageNumber }) => {
  if (!url) return null

  const splitUrl = url.split('/')
  splitUrl[0] = 'http://'
  splitUrl[5] = `upload/w_800/q_60/pg_${pageNumber}/`

  const fileExt = splitUrl[7].slice(-4)
  let fileName = splitUrl[7].slice(0, -3)
  fileName = fileExt === '.jpg' ? splitUrl[7] : fileName + '.jpg'

  const newUrl =
    splitUrl &&
    splitUrl[0] +
      splitUrl[2] +
      '/' +
      splitUrl[3] +
      '/' +
      splitUrl[4] +
      '/' +
      splitUrl[5] +
      '/' +
      splitUrl[6] +
      '/' +
      fileName

  return (
    <StyledCVImage>
      <img height="895px" loading="lazy" src={newUrl} alt="cv image" />
    </StyledCVImage>
  )
}

export default CVImage

const StyledCVImage = styled.div`
  width: 100%;

  img {
    width: 100%;
    height: auto;
    max-height: 1000px;
    vertical-align: baseline;
  }
`
