import React, { useContext, useMemo, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import styled from 'styled-components'
import pluralize from 'pluralize'
import { sanitize } from 'dompurify'
import toast from 'react-hot-toast'
import { BsBookmarkFill, BsBookmark } from 'react-icons/bs'
import { IoClose } from 'react-icons/io5'
import { MdOutlineDateRange, MdOutlineSupervisorAccount } from 'react-icons/md'
import { FiCheckCircle, FiClock } from 'react-icons/fi'

import Modal from '../../../components/Modal'
import Tag from '../../home/components/Tag'

import { ModalContext } from '../../../context/ModalContext'

import { selectJob, setSavedJobsModal } from '../../../redux/feature/jobSlice'

import apiClient from '../../../helpers/apiClient'

import { numberCommaSeparator, capitalize } from '../../../utils/util'

const JobDetailsModal = () => {
  const dispatch = useDispatch()

  const { setModal } = useContext(ModalContext)

  const selectedJob = useSelector((state) => state.jobs.selectedJob)

  const updatedBenefit = useMemo(() => {
    if (selectedJob?.benefits) {
      return JSON.parse(selectedJob?.benefits)
    }
    return []
  }, [selectedJob])

  const technicalSkills = useMemo(() => {
    if (selectedJob?.technical_skills) {
      return JSON.parse(selectedJob?.technical_skills)
    }
    return []
  }, [selectedJob])

  const countryForVacancy = useMemo(() => {
    if (selectedJob?.countries) {
      return JSON.parse(selectedJob?.countries)
    }
    return []
  }, [selectedJob])

  const experience = useMemo(() => {
    if (selectedJob?.experience) {
      return JSON.parse(selectedJob?.experience)
    }
    return []
  }, [selectedJob])

  const languages = useMemo(() => {
    if (selectedJob?.language) {
      const newLangs = JSON.parse(selectedJob?.language)
      const langs = newLangs.map((lang) => lang.value)
      return langs.join(',')
    }
    return ''
  }, [selectedJob])

  const cvDetails = useMemo(
    () => JSON.parse(localStorage.getItem('applicant-cv-data')),
    []
  )

  const [hoverBookMarkIcon, setHoverBookmarkIcon] = useState(
    <BsBookmark className="icon" />
  )
  const [successMessage, setSuccessMessage] = useState('')

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage)
      setSuccessMessage('')
    }
  }, [successMessage])

  const handleApplyJob = () => {
    dispatch(setSavedJobsModal('applyJob'))
  }

  const handleSaveJob = () => {
    const reqBody = {
      applicant_id: cvDetails?.id?.toString(),
      job_id: selectedJob?.id?.toString(),
    }

    apiClient
      .post(`job/saved`, reqBody)
      .then((response) => {
        const updatedJob = { ...selectedJob }
        updatedJob.is_saved = response?.data?.resp?.savedJob?.is_saved
        dispatch(selectJob(updatedJob))
        setSuccessMessage(response?.data?.resp?.successMessage)
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
      })
  }

  const handleCloseModal = () => {
    setModal(false)
  }

  return (
    <Modal
      noCancel={true}
      btnTitle="Close"
      btnFunc={handleCloseModal}
      onOverlayClick={handleCloseModal}
      containerClass="job__details"
    >
      <StyledJobDetails>
        <div className="close-wrapper">
          <IoClose className="close-icon" onClick={handleCloseModal} />
        </div>
        <div className="title_section">
          <h2 className="title">{selectedJob?.title}</h2>
        </div>
        <p className="salary">
          {selectedJob?.currencies}{' '}
          {numberCommaSeparator(selectedJob.min_salary)} -{' '}
          {selectedJob?.currencies}{' '}
          {numberCommaSeparator(selectedJob.max_salary)}
        </p>
        <div className="more_details">
          <div className="details">
            <MdOutlineSupervisorAccount className="icon" />
            <p className="content">
              Hiring {selectedJob?.active_vacancy}{' '}
              {pluralize('Candidate', selectedJob?.active_vacancy)}
            </p>
          </div>
          <div className="details">
            <MdOutlineDateRange className="icon" />
            <p className="content">{capitalize(selectedJob?.job_type)}</p>
          </div>
          <div className="details">
            <FiClock className="icon" />
            <p className="content">{selectedJob?.time_shift}</p>
          </div>
        </div>
        {selectedJob?.skills_match && (
          <div className="matched_skills_highlight">
            <FiCheckCircle className="icon" />
            <p className="content">Your skills match this job</p>
          </div>
        )}

        <div className="button__group">
          <button onClick={handleApplyJob}>Apply Now</button>
          {selectedJob?.is_saved ? (
            <div className="action active" onClick={handleSaveJob}>
              <BsBookmarkFill className="icon" />
              <p>Saved</p>
            </div>
          ) : (
            <div
              className="action"
              onClick={handleSaveJob}
              onMouseEnter={() => {
                setHoverBookmarkIcon(<BsBookmarkFill className="icon" />)
              }}
              onMouseLeave={() => {
                setHoverBookmarkIcon(<BsBookmark className="icon" />)
              }}
            >
              {hoverBookMarkIcon}
              <p>Save</p>
            </div>
          )}
        </div>

        <div className="section">
          <div className="section_title">Benefits</div>
          <div className="section_flex">
            {updatedBenefit?.map((benefit) => (
              <Tag key={benefit} text={benefit} />
            ))}
          </div>
        </div>

        <div className="section">
          <div className="section_title">Required Technical Skills</div>
          <div className="section_flex">
            {technicalSkills?.map((skill) => (
              <Tag key={skill.value} text={skill.value} />
            ))}
          </div>
        </div>

        <div className="section">
          <div className="section_title">Vacancy available on countries</div>
          <div className="section_flex">
            {countryForVacancy?.map((country) => (
              <Tag key={country.value} text={country.value} />
            ))}
          </div>
        </div>

        <p
          className="desc"
          dangerouslySetInnerHTML={{
            __html: sanitize(selectedJob?.description),
          }}
        />

        <div className="section_grid">
          <div className="section">
            <div className="section_title">Experience</div>
            <div className="section_content">{`${experience[0].specification}: ${experience[0].years}`}</div>
          </div>
          {selectedJob.education && (
            <div className="section">
              <div className="section_title">Education</div>
              <div className="section_content">{selectedJob?.education}</div>
            </div>
          )}
        </div>
        {languages !== '' && (
          <div className="section">
            <div className="section_title">Language</div>
            <div className="section_content">{languages}</div>
          </div>
        )}
      </StyledJobDetails>
    </Modal>
  )
}

export default JobDetailsModal

const StyledJobDetails = styled.div`
  width: 100%;
  padding: 1.875rem 2rem;
  border: 1px solid #dfe2ec;
  border-radius: 3px;
  height: 96%;
  overflow-y: auto;

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    overflow-y: scroll;
    height: calc(100vh - 30px);
    padding: 120px 1.5rem;
    z-index: 101;
  }

  .close-wrapper {
    display: none;

    @media (max-width: 768px) {
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      justify-content: flex-end;
      padding: 1.8rem 1.5rem;
      background: #fff;
      width: 100%;
      border-bottom: 1px solid #e4e4e4;

      .close-icon {
        font-size: 1.5rem;
        cursor: pointer;
      }
    }
  }

  .title_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 5px;

    .title {
      font-size: 1.5rem;
      font-weight: 500;
      color: #31374f;
      max-width: 70%;

      @media (max-width: 768px) {
        margin-bottom: 1rem;
      }
    }
  }

  .button__group {
    display: flex;
    align-items: center;
    gap: 0.875rem;
    margin-top: 0.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #dfdfe9;

    @media (max-width: 768px) {
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      background: #fff;
      display: flex;
      justify-content: flex-end;
      padding: 1.85rem 1.5rem;
      box-shadow: 0px -5px 10px rgba(199, 199, 199, 0.25);
    }

    button {
      border: none;
      outline: none;
      background: #ff5880;
      color: #fff;
      padding: 0.75rem 1.5rem;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      height: 2.8rem;
      max-width: 130px;
    }

    .action {
      display: flex;
      align-items: center;
      width: 100px;
      height: 2.8rem;
      background-color: #fff;
      border: 1px solid #31374f;
      color: #31374f;
      cursor: pointer;
      gap: 0.3rem;
      padding-left: 0.5rem;

      .icon {
        width: 24px;
        height: 24px;
      }

      &:hover,
      &.active {
        background: #edf1ff;
        color: #31374f;
        font-weight: 500;
      }
    }
  }

  .salary {
    font-size: 1rem;
    font-weight: 300;
    color: #31374f;
  }

  .more_details {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 0.75rem;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .details {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .icon {
        font-size: 1.25rem;
        color: #31374f;
      }

      .content {
        font-size: 0.875rem;
        font-weight: 300;
        color: #31374f;
      }
    }
  }

  .section_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    max-width: 450px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .section {
    margin-top: 1.25rem;

    .section_title {
      font-size: 0.75rem;
      font-weight: 500;
      color: #31374f;
      margin-bottom: 0.5rem;
    }

    .section_flex {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.5rem;
    }

    .section_content {
      font-size: 0.75rem;
      font-weight: 300;
    }
  }

  .desc {
    margin-top: 1.25rem;
    font-size: 14px;
    font-family: 'Lato';
    font-weight: 300;
    color: #31374f;
    line-height: 1.5;
  }

  .matched_skills_highlight {
    display: flex;
    font-size: 0.75rem;
    font-weight: 400;
    color: #28a745;
    gap: 0.5rem;
    margin: 0.75rem 0;

    .icon {
      font-size: 1rem;
      color: #28a745;
    }
  }
`
