import React, { useContext } from 'react'

import styled from 'styled-components'
import { PagesContext } from '../../context/FormPagesContext'

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

const DropProgress = ({ last, text, active, postActive, value }) => {
  const { reachPage, page, setPage } = useContext(PagesContext)

  return (
    <StyledProgress as="button" disabled={page > reachPage} last={last}>
      <div
        onClick={() => setPage(value)}
        className={
          active
            ? 'circle-dot active'
            : postActive
            ? 'circle-dot postActive'
            : 'circle-dot'
        }
      />
      <p>{text}</p>
    </StyledProgress>
  )
}

const Sidebar = () => {
  const { page, reachPage } = useContext(PagesContext)

  const maxValue = 5

  return (
    <StyledSidebar>
      <div className="in">
        <CircularProgressbar
          value={page}
          maxValue={1.2}
          text={`${page * maxValue} of 6`}
          strokeWidth={5}
          styles={buildStyles({
            strokeLinecap: 'butt',
            textSize: '.8rem',
            pathTransitionDuration: 0.5,
            pathColor: `#FF5880`,
            textColor: '#31374F',
            trailColor: '#E9EBF2',
          })}
        />
      </div>

      {/* TODO: Display a validation message to ensure that users cannot skip steps if there are validation errors */}
      <div className="sidebar-content">
        <DropProgress
          active={page * maxValue === 1}
          value={0.2}
          postActive={page * maxValue > 1 || reachPage * maxValue > 1}
          last={true}
          text="Personal Information"
        />
        <DropProgress
          active={page * maxValue === 2}
          value={0.4}
          postActive={page * maxValue > 2 || reachPage * maxValue > 2}
          text="Educational Background"
        />
        <DropProgress
          active={page * maxValue === 3}
          value={0.6}
          postActive={page * maxValue > 3 || reachPage * maxValue > 3}
          text="Employment History"
        />
        <DropProgress
          active={page * maxValue === 4}
          value={0.8}
          postActive={page * maxValue > 4 || reachPage * maxValue > 4}
          text="Skill"
        />
        <DropProgress
          active={page * maxValue === 5}
          value={1}
          postActive={page * maxValue > 5 || reachPage * maxValue > 5}
          text="Character Reference"
        />
        <DropProgress
          active={page * maxValue === 6}
          value={1.2}
          postActive={page * maxValue === 6 || reachPage * maxValue === 6}
          text="Review your CV"
        />
      </div>
    </StyledSidebar>
  )
}

export default Sidebar

export const StyledSidebar = styled.aside`
  width: 100%;
  max-width: 190px;
  margin-right: 4rem;
  position: sticky;
  top: 8rem;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    top: 5rem;
  }

  @media (max-width: 768px) {
    background: #fff;
    max-width: 100vw;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 3;

    .in {
      width: 100%;
      max-width: 120px;
    }
  }

  .sidebar-content {
    margin-top: 2rem;

    @media (max-width: 768px) {
      display: flex;
      align-items: flex-start;
    }
  }
`

export const StyledProgress = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  background: none;
  outline: none;
  border: none;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .circle-dot {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background: #e9ebf2;
    position: relative;
    margin-bottom: 2.5rem;
    cursor: pointer;

    @media (max-width: 768px) {
      margin-bottom: 0.7rem;
    }

    &:after {
      content: '';
      width: 1px;
      height: 2.5rem;
      background: #e9ebf2;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      display: ${(p) => (p.last ? 'none' : 'block')};

      @media (max-width: 768px) {
        display: none;
      }
    }

    &.active {
      background: #ff5880;

      &:after {
        background: #ff5880;
      }
    }

    &.postActive {
      border: 1px solid #ff5880;
      background: #fff;

      &:after {
        background: #ff5880;
      }
    }
  }

  p {
    font-size: 0.75rem;
    font-weight: 300;
    margin-left: 2rem;

    @media (max-width: 768px) {
      text-align: center;
      margin-left: 0;
      padding: 0.5rem;
    }
  }
`
