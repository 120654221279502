import React from 'react'

import styled from 'styled-components'

export const Button = ({
  handleClick,
  children,
  outline,
  mb,
  height,
  width,
  radius,
  btnClass,
  type = 'button',
}) => {
  return (
    <StyledButton
      type={type}
      mb={mb}
      width={width}
      height={height}
      radius={radius}
      onClick={handleClick}
      className={btnClass}
    >
      {children}
    </StyledButton>
  )
}

export const SocilButton = ({ handleClick, title, children, color }) => {
  return (
    <StyledButton color={color} className="social" onClick={handleClick}>
      {children}
      {title}
    </StyledButton>
  )
}

export const IconButton = ({ handleClick, children, color }) => {
  return (
    <StyledIconButton color={color} onClick={handleClick}>
      {children}
    </StyledIconButton>
  )
}

const StyledButton = styled.button`
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '3.375rem')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  font-weight: 500;
  color: #fff;
  border-radius: ${(props) => (props.radius ? props.radius : '100px')};
  background-color: #fd2055;
  cursor: pointer;
  outline: none;
  border: none;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0')};

  &.secondary {
    color: #31374f;
    background-color: #ffffff;
    border: 1px solid #31374f;
  }

  &.social {
    justify-content: flex-start;
    font-size: 0.75rem;
    color: ${(p) => (p.color ? p.color : '#31374F')};
    padding: 0 1.5rem;
    background: none;
    border: 1px solid;
    border-color: ${(p) => (p.color ? p.color : '#AEB3C9')};
    margin-bottom: 1.5rem;
  }
`

const StyledIconButton = styled.button`
  position: relative;
  width: 100%;
  height: 4.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.3rem 1.5rem;
  border-radius: 100px;
  border: 1px solid #858aa0;
  font-size: 0.85rem;
  font-weight: 300;
  letter-spacing: 0.03em;
  color: #31374f;
  background: none;
  cursor: pointer;

  &:hover {
    border-color: #31374f;
  }

  .leftIcon {
    font-size: 1.3rem;
    color: #ff7b9b;
  }

  .rightIcon {
    font-size: 1.3rem;
    color: #858aa0;
  }
`
