import React, { useContext, useEffect, useState, useMemo } from 'react'

import { FiPlus } from 'react-icons/fi'
import toast, { Toaster } from 'react-hot-toast'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import Joi from 'joi'

import 'react-datepicker/dist/react-datepicker.css'

import Modal from '../Modal'
import ProfileDetails from '../ProfileDetails'
import ToggleBtn from '../ToggleBtn'
import { StyledProfileDetailsUpdate } from './ProfileDetailsUpdate'

import { PagesContext } from '../../context/FormPagesContext'
import { ModalContext } from '../../context/ModalContext'

import { levels } from '../../constants/levelOfStudy'

const EducationalBackgroundUpdate = ({
  handleHardUpdate,
  handleHardDeleteItem,
}) => {
  const { handleModalToggle } = useContext(ModalContext)
  const {
    from,
    setFrom,
    to,
    setTo,
    educationLevel,
    setEducationLevel,
    fieldOfStudy,
    setFieldOfStudy,
    school,
    setSchool,
    currentStatus,
    setCurrentStatus,
    schoolLocation,
    setSchoolLocation,
    educationalData,
    setEducationalData,
    eduToggle,
    setEduToggle,
  } = useContext(PagesContext)

  const validationSchema = useMemo(() => {
    if (currentStatus === 'graduated') {
      return Joi.object({
        educationLevel: Joi.string().label('Level of Eucation').required(),
        fieldOfStudy: Joi.string().label('Field of Study').required(),
        school: Joi.string().label('School').required(),
        schoolLocation: Joi.string().label('School Location'),
        currentStatus: Joi.string().label('Status').required(),
        from: Joi.date()
          .label('Date From')
          .less('now')
          .less(new Date(to))
          .message('Date From must be lesser than Date To and the current date')
          .required(),
        to: Joi.date()
          .label('Date To')
          .less('now')
          .greater(new Date(from))
          .message('Date To must be greater than Date From')
          .required(),
        id: Joi.date().not().required(),
      })
    } else {
      return Joi.object({
        educationLevel: Joi.string().label('Level of Eucation').required(),
        fieldOfStudy: Joi.string().label('Field of Study').required(),
        school: Joi.string().label('School').required(),
        schoolLocation: Joi.string().label('School Location'),
        currentStatus: Joi.string().label('Status').required(),
        from: Joi.date()
          .less('now')
          .message('Date From must not be from the future')
          .required(),
        to: Joi.date().not().required(),
        id: Joi.date().not().required(),
      })
    }
  }, [currentStatus, to, from])

  const [show, setShow] = useState(false)
  const [clearField, setClear] = useState(false)
  const [fieldErrors, setFieldErrors] = useState({})

  const data = {
    educationLevel,
    school,
    schoolLocation,
    fieldOfStudy,
    from,
    to,
    currentStatus,
    id: new Date(),
  }

  const clear = () => {
    setEducationLevel('')
    setFieldOfStudy('')
    setSchool('')
    setSchoolLocation('')
    setTo('')
    setFrom('')
  }

  useEffect(() => {
    if (educationalData?.length > 0) {
      setShow(false)
    } else {
      setShow(true)
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem('educationData' && eduToggle === true)) {
      localStorage.deleteItem('educationData')
    }

    if (eduToggle === true) {
      clear()
    }
  }, [eduToggle])

  const handleStoreData = () => {
    const cvData = JSON.parse(localStorage.getItem('applicant-cv-data'))
    const oldMeta = cvData && cvData.meta ? JSON.parse(cvData.meta) : {}

    if (handleHardUpdate) {
      handleHardUpdate(data)
      clear()
      setClear(true)
      setShow(false)
    } else {
      const result = validationSchema.validate(data, { abortEarly: false })

      if (!result.error) {
        setEducationalData((prevState) => {
          if (prevState) {
            return [...prevState, data]
          } else {
            return [data]
          }
        })
        localStorage.setItem(
          'educationalData',
          JSON.stringify([...educationalData, data])
        )
        const meta = JSON.stringify({
          educationalData: [...educationalData, data],
          ...oldMeta,
        })
        localStorage.setItem(
          'applicant-cv-data',
          JSON.stringify({
            ...cvData,
            meta: meta,
          })
        )
        clear()
        handleModalToggle()
      } else if (!show) {
        setEducationalData((prevState) => [...prevState])
        handleModalToggle()
      } else {
        if (eduToggle) {
          setEducationalData([])
          const meta = JSON.stringify({
            educationalData: [],
            ...oldMeta,
          })
          localStorage.setItem(
            'applicant-cv-data',
            JSON.stringify({
              ...cvData,
              meta: meta,
            })
          )
          handleModalToggle()
        } else {
          const validationErrors = result.error.details.reduce((acc, curr) => {
            acc[curr.context.key] = curr.message.replace(/"/g, '')
            return acc
          }, {})

          setFieldErrors(validationErrors)
          toast.error('Please correct the validation errors before proceeding.')
        }
      }
    }
  }

  const handleDelete = (id) => {
    const filteredItems = educationalData.filter((item) => item.id !== id)
    setEducationalData(filteredItems)
    if (handleHardDeleteItem) {
      handleHardDeleteItem(filteredItems)
    }
  }

  const handleEdit = (id) => {
    const newData = educationalData.find((item) => item.id === id)
    const filteredData = educationalData.filter((item) => item.id !== id)

    setShow(true)

    setEducationLevel(newData.educationLevel)
    setFieldOfStudy(newData.fieldOfStudy)
    setSchool(newData.school)
    setSchoolLocation(newData.schoolLocation)
    setTo(moment(newData.to).toDate())
    setFrom(moment(newData.from).toDate())
    setEducationalData(filteredData)
  }

  const handleAddEduBackground = () => {
    if (!show) {
      setClear(true)
      setShow(true)
    } else {
      handleStoreData()
    }
  }

  const handleRemoveEduBackground = () => {
    setShow(false)
    setClear(false)
  }

  return (
    <Modal
      title="Educational Background"
      btnTitle="Update"
      btnFunc={handleStoreData}
    >
      <StyledProfileDetailsUpdate>
        {educationalData?.map(
          (
            {
              educationLevel,
              currentStatus,
              school,
              id,
              schoolLocation,
              from,
              to,
            },
            index
          ) => (
            <ProfileDetails
              key={index}
              educationLevel={educationLevel}
              currentStatus={currentStatus}
              school={school}
              schoolLocation={schoolLocation}
              from={from}
              to={to}
              handleDelete={handleDelete}
              id={id}
              handleEdit={handleEdit}
            />
          )
        )}

        {/* toggle button */}
        {educationalData?.length < 1 && (
          <div className="toggleBtnWrapper">
            <ToggleBtn toggle={eduToggle} setToggle={setEduToggle} />
            <p className="small-subtitle">I have no educational background</p>
          </div>
        )}

        <div className="inputs">
          {show && (
            <>
              {/* Level of Education */}
              <div className="inputs">
                <label htmlFor="level">
                  Level of Education <span className="required">*</span>
                </label>

                <select
                  onChange={(e) => setEducationLevel(e.target.value)}
                  disabled={eduToggle}
                  style={{
                    borderColor: 'educationLevel' in fieldErrors && 'red',
                  }}
                  className="select"
                  value={educationLevel}
                  name=""
                  id="level"
                >
                  <option>Choose Option</option>
                  {levels.map((level) => (
                    <option key={level} value={level}>
                      {level}
                    </option>
                  ))}
                </select>
                {'educationLevel' in fieldErrors && (
                  <sub style={{ color: 'red' }}>
                    {fieldErrors.educationLevel}
                  </sub>
                )}
              </div>

              {/* Field of Study */}
              <div className="inputs">
                <label htmlFor="fieldOfStudy">
                  Field of Study <span className="required">*</span>
                </label>

                <input
                  className={`input ${
                    'fieldOfStudy' in fieldErrors && 'has-error'
                  }`}
                  disabled={eduToggle}
                  id="fieldOfStudy"
                  type="text"
                  placeholder="e.g. Agriculture, Law, Chemistry"
                  onChange={(e) => setFieldOfStudy(e.target.value)}
                  value={fieldOfStudy}
                />
                {'fieldOfStudy' in fieldErrors && (
                  <sub style={{ color: 'red' }}>{fieldErrors.fieldOfStudy}</sub>
                )}
              </div>

              {/* School */}
              <div className="inputs">
                <label htmlFor="school">
                  School <span className="required">*</span>
                </label>

                <input
                  className={`input ${'school' in fieldErrors && 'has-error'}`}
                  disabled={eduToggle}
                  id="school"
                  type="text"
                  placeholder="School"
                  onChange={(e) => setSchool(e.target.value)}
                  value={school}
                />
                {'school' in fieldErrors && (
                  <sub style={{ color: 'red' }}>{fieldErrors.school}</sub>
                )}
              </div>

              {/* School Location */}
              <div className="inputs">
                <label htmlFor="schoolLocation">
                  School Location <span className="required">*</span>
                </label>

                <input
                  className={`input ${
                    'schoolLocation' in fieldErrors && 'has-error'
                  }`}
                  disabled={eduToggle}
                  id="school-location"
                  type="text"
                  placeholder="School Location"
                  onChange={(e) => setSchoolLocation(e.target.value)}
                  value={
                    schoolLocation.label ? schoolLocation.label : schoolLocation
                  }
                />
                {'schoolLocation' in fieldErrors && (
                  <sub style={{ color: 'red' }}>
                    {fieldErrors.schoolLocation}
                  </sub>
                )}
              </div>

              {/* Current Status */}
              <div className="inputs">
                <label htmlFor="currentStatus">Current Status</label>

                <div className="radioBtns">
                  <div className="radio">
                    <input
                      disabled={eduToggle}
                      className="radio-input"
                      type="radio"
                      name="status"
                      id="graduated"
                      value="graduated"
                      checked={currentStatus === 'graduated'}
                      onChange={(e) => setCurrentStatus(e.target.value)}
                    />
                    <label htmlFor="graduated" className="small-subTitle">
                      Graduated
                    </label>
                  </div>
                  <div className="radio">
                    <input
                      disabled={eduToggle}
                      className="radio-input"
                      type="radio"
                      name="status"
                      id="currently"
                      value="currently enrolled"
                      checked={currentStatus === 'currently enrolled'}
                      onChange={(e) => setCurrentStatus(e.target.value)}
                    />
                    <label htmlFor="currently" className="small-subTitle">
                      Currently Enrolled
                    </label>
                  </div>
                </div>
              </div>

              {/* Data Grid */}
              <div className="data-grid">
                <div className="inputs">
                  <label htmlFor="">
                    Date From <span className="required">*</span>
                  </label>
                  <DatePicker
                    className={'from' in fieldErrors && 'has-error'}
                    disabled={eduToggle}
                    selected={from}
                    onChange={(date) => setFrom(date)}
                    showMonthYearPicker
                    dateFormat="MMMM yyyy"
                    placeholderText="MM/YYYY"
                  />
                  {'from' in fieldErrors && (
                    <sub style={{ color: 'red' }}>{fieldErrors.from}</sub>
                  )}
                </div>
                <div className="inputs">
                  <label htmlFor="">
                    Date To <span className="required">*</span>
                  </label>
                  <DatePicker
                    className={'to' in fieldErrors && 'has-error'}
                    disabled={
                      eduToggle || currentStatus === 'currently enrolled'
                    }
                    selected={
                      currentStatus === 'currently enrolled' ? undefined : to
                    }
                    onChange={(date) => setTo(date)}
                    showMonthYearPicker
                    placeholderText="MM/YYYY"
                    dateFormat="MMMM yyyy"
                  />
                  {'to' in fieldErrors && (
                    <sub style={{ color: 'red' }}>{fieldErrors.to}</sub>
                  )}
                </div>
              </div>
            </>
          )}
          <Toaster position="top-right" reverseOrder={false} />

          {!eduToggle && (
            <div className="addContainer mt">
              <button onClick={handleAddEduBackground} className="keywordBtn">
                <span className="mr">
                  <FiPlus className="icon" />
                </span>
                Add another educational background
              </button>

              {clearField && (
                <button
                  onClick={handleRemoveEduBackground}
                  className="keywordBtn remove"
                >
                  Remove
                </button>
              )}
            </div>
          )}
        </div>
      </StyledProfileDetailsUpdate>
    </Modal>
  )
}

export default EducationalBackgroundUpdate
