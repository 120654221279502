import axios from 'axios'
import React, { useContext, useEffect } from 'react'
import FlexWrapper from '../../components/MiniComponents/FlexWrapper'
import ItemSelect from '../../components/MiniComponents/ItemSelect'
import SubFileUpload from '../../components/MiniComponents/SubFileUpload'
import { baseUrl } from '../../constants/constant'
import { PagesContext } from '../../context/FormPagesContext'
import { ModalContext } from '../../context/ModalContext'
import { WorkEthicsContext } from '../../context/WorkEthics'
import { Button, StyledScreen } from '../remoteSetup/RemoteScreenOne'

const WorkEthicsStepOne = () => {
  const { setWorkEthicsPage, setReachWorkEthicsPage } = useContext(ModalContext)
  const {
    workArea,
    setWorkArea,
    attachment,
    setAttachment,
    setDevice,
    setFixSchedule,
    setShift,
    setJobHours,
    setTimeTracker,
  } = useContext(WorkEthicsContext)

  const { userData } = useContext(PagesContext)

  // Get User past test results
  useEffect(() => {
    axios
      .get(`${baseUrl}/user/${userData.id}/remote-work`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((res) => {
        const data = JSON.parse(res.data.resp.meta)
        setWorkArea(data.workStation)
        setDevice(data.device)
        setFixSchedule(data.workSchedule)
        setShift(data.workShift)
        setJobHours(data.workHours)
        setTimeTracker(data.timeTracker)
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <StyledScreen maxWidth="456px">
      <h2 className="subHeading">
        I am ready to work from home permanently. I will be working from...
      </h2>
      <p className="paragraph">
        A conducive work environment is crucial in building a successful remote
        work career.
      </p>

      <div className="mb-2" />
      <ItemSelect
        value="The Bedroom"
        onClick={() => setWorkArea('The Bedroom')}
        active={workArea === 'The Bedroom'}
      />
      <ItemSelect
        value="The living area"
        onClick={() => setWorkArea('The living area')}
        active={workArea === 'The living area'}
      />
      <ItemSelect
        value="My home office"
        onClick={() => setWorkArea('My home office')}
        active={workArea === 'My home office'}
      />
      <ItemSelect
        value="None of the above"
        onClick={() => setWorkArea('None of the above')}
        active={workArea === 'None of the above'}
      />

      {/* Upload workarea */}
      {!workArea.includes('None') && (
        <SubFileUpload attachment={attachment} setAttachment={setAttachment} />
      )}

      <FlexWrapper content="flex-end" mt="2rem">
        <Button
          className="filled"
          disabled={workArea === '' || !workArea}
          onClick={() => {
            setWorkEthicsPage(2)
            setReachWorkEthicsPage(2)
          }}
        >
          Next Step
        </Button>
      </FlexWrapper>
    </StyledScreen>
  )
}

export default WorkEthicsStepOne
