import React from 'react'

import CreatableSelect from 'react-select/creatable'
import styled from 'styled-components'

const CustomCreatableSelect = ({
  components,
  isMulti = true,
  isValidNewOption,
  value,
  options,
  onChange,
  placeholder,
  errorMessage = '',
}) => {
  return (
    <StyledCreatableSelect>
      <CreatableSelect
        components={{ components }}
        isMulti={isMulti}
        isValidNewOption={isValidNewOption}
        value={value}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        className="has-danger"
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: errorMessage ? 'red' : '#cccccc',
          }),
        }}
      />
      {errorMessage && <sub className="error__message">{errorMessage}</sub>}
    </StyledCreatableSelect>
  )
}

const StyledCreatableSelect = styled.div`
  .error__message {
    color: red;
  }
`

export default CustomCreatableSelect
