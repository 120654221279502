import React from 'react'

import { RiDeleteBinLine } from 'react-icons/ri'

import pencil from '../assets/icons/pencil.svg'

import { StyledProfileDetails } from './ProfileDetails'

const CharacterRefDetails = ({
  crName,
  crEmail,
  crPhone,
  id,
  handleDelete,
  handleEdit,
}) => {
  return (
    <StyledProfileDetails>
      <p className="paragraph">
        <b>{crName}</b>
      </p>
      <p className="paragraph">{crEmail}</p>
      <p className="paragraph">{crPhone}</p>

      <div className="cta">
        <RiDeleteBinLine className="icon" onClick={() => handleDelete(id)} />
        <img
          src={pencil}
          alt="pencil icon"
          onClick={() => handleEdit(id)}
          className="pencil"
        />
      </div>
    </StyledProfileDetails>
  )
}

export default CharacterRefDetails
