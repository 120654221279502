import React from 'react'

import styled from 'styled-components'
import { useSelector } from 'react-redux'

import CVCustomizer from './CVCustomizer'

const OtherInfo = ({ handlePrint, handleCVUpload }) => {
  const uploadedCV = useSelector((state) => state.cv.uploadedCV)

  return (
    <StyledOtherInfo>
      <button onClick={handlePrint} className="downloadBtn">
        Download CV
      </button>

      {uploadedCV && uploadedCV.public_id ? (
        <button onClick={handleCVUpload} className="downloadBtn">
          Replace CV
        </button>
      ) : (
        <CVCustomizer />
      )}
    </StyledOtherInfo>
  )
}

export default OtherInfo

const StyledOtherInfo = styled.div`
  width: 100%;
  max-width: 250px;

  @media (max-width: 768px) {
    max-width: 768px;
  }

  .downloadBtn {
    width: 100%;
    height: 3.75rem;
    border: none;
    border-radius: 3px;
    background: #ff5880;
    color: #fff;
    font-weight: 500;
    font-size: 1.125rem;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 1rem;
  }
`
