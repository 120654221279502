import React, { useEffect, useState } from 'react'

import moment from 'moment'
import toast from 'react-hot-toast'
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
import { FcGoogle } from 'react-icons/fc'
import { GoCircleSlash } from 'react-icons/go'
import { MdPersonSearch } from 'react-icons/md'
import { TbCalendarTime } from 'react-icons/tb'
import styled from 'styled-components'

import apiClient from '../../../helpers/apiClient'

import { formatTimeDifference } from '../../../utils/util'

import noFile from '../../../assets/images/nofile.PNG'

const MyApplicationsTab = () => {
  const cvData = JSON.parse(localStorage.getItem('applicant-cv-data'))
  const stagesBinding = {
    Screening: <MdPersonSearch className="icon__status" />,
    '1st Interview': <TbCalendarTime className="icon__status" />,
    '2nd Interview': <TbCalendarTime className="icon__status" />,
    'Skill Assessment': <TbCalendarTime className="icon__status" />,
    'Client Profiling': <MdPersonSearch className="icon__status" />,
    'Client Interview': <TbCalendarTime className="icon__status" />,
    Offer: <TbCalendarTime className="icon__status" />,
    Closed: <GoCircleSlash className="icon__status" />,
  }

  const [jobs, setJobs] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    apiClient
      .get('/applicant/jobs', {
        params: {
          applicantId: cvData.id,
        },
      })
      .then((res) => {
        const result = res.data.resp.map((data) => {
          return {
            ...data,
            isOpen: false,
          }
        })
        setJobs(result)
        setLoading(false)
      })
      .catch((err) => {
        toast.error(err.message)
        setLoading(false)
      })
  }, [cvData.id])

  const fetchActivities = async (selectedJob) => {
    apiClient
      .get(`/applicant/${cvData.id}/interviewActivities`, {
        params: {
          interview_id: selectedJob.interviewId,
        },
      })
      .then((res) => {
        const result = res.data.resp
        const updatedJobs = jobs.map((job) => {
          if (job.interviewId === selectedJob.interviewId) {
            return {
              ...job,
              activities: result,
              isOpen: !selectedJob.isOpen,
            }
          } else {
            return job
          }
        })
        setJobs(updatedJobs)
      })
      .catch((err) => {
        toast.error(err?.message)
        setLoading(false)
      })
  }

  return (
    <StyledProfile>
      <br />
      <>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            {jobs.map((job, index) => (
              <div className="jobs" key={index}>
                {job?.isOpen && job?.activities?.length > 0 ? (
                  <AiOutlineUp
                    className="arrow__icons"
                    onClick={() => fetchActivities(job)}
                  />
                ) : (
                  <AiOutlineDown
                    className="arrow__icons"
                    onClick={() => fetchActivities(job)}
                  />
                )}
                <div className="job">
                  <h4 className="job__title">{job?.title}</h4>
                  <p className="job__desc">
                    Applied {moment(job?.created_at).format('MMMM DD, YYYY')}
                  </p>
                  <div className="job__stages">
                    {job?.stages && (
                      <span>
                        {' '}
                        {stagesBinding[job?.stages]}
                        {job?.stages}
                      </span>
                    )}
                  </div>
                  {job?.status === 'active'
                    ? job?.meet_url &&
                      (job?.stages === '1st Interview' ||
                        job?.stages === '2nd Interview') && (
                        <>
                          <div className="schedule">
                            <span>
                              <b>Schedule:</b>&nbsp;
                            </span>
                            <span className="schedule__time">
                              {moment(job?.start_time).format('MMMM DD, YYYY')}{' '}
                              • {moment(job?.start_time).format('hh:mmA')} -{' '}
                              {moment(job?.end_time).format('hh:mmA')} GMT +8
                            </span>
                          </div>
                          <div className="calendly__buttons">
                            <a
                              className="calendly__link"
                              href={job?.meet_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FcGoogle className="google__icon" />
                              Join Google Meet
                            </a>
                          </div>
                        </>
                      )
                    : job?.meet_url !== null &&
                      job?.status === 'closed' &&
                      job?.stages === 'first_interview' && (
                        <>
                          <div className="schedule">
                            <span>
                              <b>Schedule:</b>&nbsp;
                            </span>
                            <span className="schedule__time">
                              {moment(job?.start_time).format('MMMM DD, YYYY')}{' '}
                              • {moment(job?.start_time).format('hh:mmA')} -{' '}
                              {moment(job?.end_time).format('hh:mmA')} GMT +8
                            </span>
                          </div>
                          <div className="calendly__buttons">
                            <a
                              className="calendly__link"
                              href={job?.meet_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FcGoogle className="google__icon" />
                              Join Google Meet
                            </a>
                          </div>
                        </>
                      )}
                  <br />
                  <div className="job__activities">
                    {job.isOpen && job?.activities?.length > 0 && (
                      <h4 className="job__activities__title">Job Activity</h4>
                    )}
                    {job.isOpen &&
                      job?.activities?.map((activity, index) => (
                        <div className="activity__item" key={index}>
                          <div
                            className={`circle__dot ${index === 0 && 'active'}`}
                          ></div>
                          <span className="activity__item__title">
                            {activity.activities}
                            <br />
                            <span className="activity__item__time">
                              {formatTimeDifference(
                                new Date(activity.created_at),
                                new Date()
                              )}
                            </span>
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ))}
            <br />
            {jobs.length === 0 && (
              <div className="empty">
                <img src={noFile} className="empty__icon" />
                <p>
                  You currently have no active applications,
                  <br /> search for a job to apply to{' '}
                  <a
                    href="/home"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text"
                  >
                    here
                  </a>
                </p>
              </div>
            )}
          </>
        )}
      </>
      <br />
      <br />
    </StyledProfile>
  )
}

export const StyledProfile = styled.section`
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .empty {
    text-align: center;
  }

  .jobs {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    .arrow__icons {
      font-size: 20px;
      color: #31374f;
      cursor: pointer;
    }

    .job {
      .job__title {
        font-weight: 600;
        font-size: 20px;
        color: #31374f;
      }

      .job__desc {
        margin-top: 10px;
        margin-bottom: 10px;
        color: #31374f;
        font-size: 14px;
      }

      .job__stages {
        color: #1a1a1a;
        background: #dedede;
        max-width: 150px;
        text-align: center;
        padding: 8px 8px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 600;

        .icon__status {
          margin-right: 6px;
          font-size: 18px;
          margin-bottom: -4px;
        }
      }

      .job__activities {
        .job__activities__title {
          margin-top: 10px;
          margin-bottom: 10px;
          color: #31374f;
          font-size: 14px;
        }

        .activity__item {
          display: flex;
          margin-bottom: 15px;

          .circle__dot {
            background: red;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: #e9ebf2;
            position: relative;
            margin-right: 10px;

            &.active {
              background: #1aaf8b;

              &:after {
                background: #fff;
              }
            }

            &:after {
              content: '';
              width: 1px;
              height: 32px;
              background: #e9ebf2;
              position: absolute;
              bottom: 100%;
              left: 50%;
              -webkit-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              -webkit-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              transform: translateX(-50%);
              display: block;
              z-index: -1;
            }
          }

          span {
            color: #31374f;
            margin-top: -4px;
          }

          .activity__item__title {
            font-size: 14px;
          }

          .activity__item__time {
            font-size: 10px;
          }
        }
      }
    }
  }

  .schedule {
    margin: 10px 0;

    .schedule__time {
      padding: 0.5rem;
      font-weight: 400;
    }
  }

  .calendly__buttons {
    display: flex;
    gap: 0.25rem;

    @media (max-width: 768px) {
      flex-direction: column;
      max-width: 220px;
    }

    .calendly__link {
      border: 1px solid #aeb3c9;
      padding: 1rem;
      display: flex;
      text-decoration: none;
      color: #0e1324;

      .google__icon {
        margin-right: 8px;
        font-size: 22px;
      }
    }
  }

  .empty {
    p {
      color: #31374f;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }

    .empty__icon {
      width: 100px;
    }
  }
`

export default MyApplicationsTab
