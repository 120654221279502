import React, { useRef, useContext, useEffect, useState } from 'react'

import styled from 'styled-components'
import { useReactToPrint } from 'react-to-print'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'

import OtherInfo from './OtherInfo'
import Preview from './Preview'

import { P } from '../../../styles/Typograph.styles'

import { PagesContext } from '../../../context/FormPagesContext'
import { ModalContext } from '../../../context/ModalContext'

import { uploadCV } from '../../../redux/feature/cvSlice'

import apiClient from '../../../helpers/apiClient'

const CvWrapper = () => {
  const downloadRef = useRef(null)
  const fileUploadRef = useRef(null)

  const uploadedCV = useSelector((state) => state.cv.uploadedCV)

  const dispatch = useDispatch()

  const [cvDetails, setCvData] = useState(null)
  const [loading, setLoading] = useState(false)

  const { cvUserData } = useContext(PagesContext)
  const { closeModal } = useContext(ModalContext)

  // get cv details
  useEffect(() => {
    setLoading(true)
    apiClient.get(`${cvUserData?.id}/uploaded-cv`).then((res) => {
      if (res.data.resp) {
        const data = res.data.resp

        dispatch(uploadCV(res.data.resp))

        const slicedUrl =
          data && data.url ? data?.url?.slice(0, -3) + 'jpg' : ''

        const cvDetails = {
          url: data.url,
          slicedUrl: slicedUrl,
          id: data.public_id,
          totalPageNumber: data.pages,
        }

        setCvData(cvDetails)
        setLoading(false)
      } else {
        setCvData(null)
        setLoading(false)
      }
    })
  }, [closeModal])

  const handlePrint = useReactToPrint({
    content: () => downloadRef.current,
  })

  const handleCVUpload = () => {
    fileUploadRef.current.click()
  }

  const handleCVChanged = async (e) => {
    try {
      e.stopPropagation()
      e.preventDefault()

      // validate uploaded file (Must be PDF and not more than 5mb)
      const filePath = e.target.value

      const allowedExtension = /(\.pdf)$/i
      if (!allowedExtension.exec(filePath)) {
        toast.error('Invalid file extension, only PDF files are accepted')
        e.target.value = ''
        return
      }

      // upload file to cloudinary
      setLoading(true)

      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('cv', file)

      const uploadedFile = await apiClient.put(
        `/${uploadedCV.public_id}/replace-cv`,
        formData
      )
      const data = uploadedFile.data.resp
      const cvDetails = {
        url: data.url,
        slicedUrl: data.url.slice(0, -3) + 'jpg',
        id: data.public_id,
        totalPageNumber: data.pages,
      }
      dispatch(uploadCV(data))
      localStorage.setItem('cvDetails', JSON.stringify(cvDetails))
      toast.success(uploadedFile.data.message)
    } catch (err) {
      toast.error(
        err?.response?.message ||
          err?.response?.data?.message ||
          'Something went wrong. Please try again later.'
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <StyledWrapper>
      <P size="1.5rem" color="#0E1324">
        Preview CV
      </P>

      <div className="contentWrapper">
        {loading ? (
          <>Loading...</>
        ) : (
          <>
            <Preview cvDetails={cvDetails} downloadRef={downloadRef} />
            <OtherInfo
              cvDetails={cvDetails}
              handlePrint={handlePrint}
              handleCVUpload={handleCVUpload}
            />
            <HiddenFileUpload
              ref={fileUploadRef}
              onChange={handleCVChanged}
              type="file"
              id="file__upload"
            />
          </>
        )}
      </div>
    </StyledWrapper>
  )
}

export default CvWrapper

const StyledWrapper = styled.section`
  width: 100%;

  .contentWrapper {
    display: flex;
    align-items: flex-start;
    margin-top: 1.5rem;
    gap: 2.5rem;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }
`

const HiddenFileUpload = styled.input`
  display: none;
`
