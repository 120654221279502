import React, { useRef } from 'react'

import JoditEditor from 'jodit-react'
import styled from 'styled-components'

const RichTextbox = ({
  content,
  setContent,
  isReadOnly,
  className = '',
  errorMessage = '',
}) => {
  const editor = useRef(null)

  const config = {
    buttons: [
      'paragraph',
      'bold',
      'underline',
      'italic',
      '|',
      'ul',
      'ol',
      '|',
      'link',
      '|',
      'undo',
      'redo',
    ],
    readonly: isReadOnly | false,
    toolbarAdaptive: false,
  }

  return (
    <StyledJoditEditor>
      <JoditEditor
        className={className}
        ref={editor}
        value={content}
        config={config}
        tabIndex={1} // tabIndex of textarea
        onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
      />
      {className === 'has-error' && (
        <sub style={{ color: 'red' }}>{errorMessage}</sub>
      )}
    </StyledJoditEditor>
  )
}

const StyledJoditEditor = styled.div`
  .jodit-container {
    &.has-error {
      border: 1px solid red;
    }
  }
`

export default RichTextbox
