import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import styled from 'styled-components'
import moment from 'moment'
import toast from 'react-hot-toast'
import { sanitize } from 'dompurify'
import { useDispatch } from 'react-redux'

import pencil from '../../../assets/icons/pencil.svg'

import SkillsUpdate from '../../../components/onboardingUpdate/SkillsUpdate'
import EducationalBackgroundUpdate from '../../../components/onboardingUpdate/EducationalBackgroundUpdate'
import EmploymentHistory from '../../../components/onboardingUpdate/EmploymentHistory'
import CharacterRefUpdate from '../../../components/onboardingUpdate/CharacterRefUpdate'

import { ModalContext } from '../../../context/ModalContext'
import { PagesContext } from '../../../context/FormPagesContext'

import { setHasSkillChange } from '../../../redux/feature/jobSlice'

import useFeatureFlag from '../../../hooks/useFeatureFlag'

import apiClient from '../../../helpers/apiClient'

import { getExperienceLevel, getFromLocalStorage } from '../../../utils/util'

const MyCvTab = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [modal, setModal] = useState('')

  const {
    experienceState,
    setExperienceState,
    educationalData,
    setEducationalData,
    skills,
    softSkills,
    setFullSkills,
    toggle,
    eduToggle,
    characterReferences,
    setCharacterReferences,
    items,
  } = useContext(PagesContext)

  const { handleModalToggle, setRemotePage, setWorkEthicsPage } =
    useContext(ModalContext)

  const { activeFlag } = useFeatureFlag('FF_APPLICANT_MYCV_LOCAL_STORAGE')

  const wfh = activeFlag
    ? getFromLocalStorage('applicant-wfh')
    : JSON.parse(localStorage.getItem('applicant-wfh'))
  const cvData = activeFlag
    ? getFromLocalStorage('applicant-cv-data')
    : JSON.parse(localStorage.getItem('applicant-cv-data'))
  const userId = activeFlag
    ? getFromLocalStorage('user_id')
    : localStorage.getItem('user_id')
  const meta = cvData.meta !== null ? JSON.parse(cvData.meta) : null

  const characterReferenceMeta =
    cvData?.character_reference && JSON.parse(cvData.character_reference)
  const computerSpec =
    wfh !== null ? JSON.parse(wfh.computer_specification) : wfh
  const internetSpeedTest =
    wfh !== null ? JSON.parse(wfh.internet_speed_test) : wfh
  const peripherals = wfh !== null ? JSON.parse(wfh.peripherals) : wfh
  const remoteFitness = wfh !== null ? JSON.parse(wfh.meta) : wfh

  const professionalHeadlines = getFromLocalStorage('professionalHeadlinesId')
    ? JSON.parse(cvData.professional_headline).map((hl) => hl.id)
    : []

  const handleUpdateEmploymentHistory = (data) => {
    const newMeta = {
      ...meta,
    }

    if (toggle) {
      setExperienceState([])
      newMeta.experienceState = []
    } else if (data?.jobTitle === '') {
      return toast.error('Please add an employment history')
    } else {
      setExperienceState((prevState) => [...prevState, data])
      newMeta.experienceState = [...experienceState, data]
    }

    const reqBody = {
      meta: JSON.stringify(newMeta),
    }
    apiClient
      .put(`/applicant/${userId}/update`, reqBody)
      .then((resp) => {
        localStorage.setItem(
          'applicant-cv-data',
          JSON.stringify({
            ...cvData,
            meta: resp.data.data.meta,
          })
        )
        toast.success(resp.data.message)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }
  const handleDeleteEmploymentHistoryItem = (filteredItems) => {
    const newMeta = {
      ...meta,
      experienceState: filteredItems,
    }

    const reqBody = {
      meta: JSON.stringify(newMeta),
    }
    apiClient
      .put(`/applicant/${userId}/update`, reqBody)
      .then((resp) => {
        localStorage.setItem(
          'applicant-cv-data',
          JSON.stringify({
            ...cvData,
            meta: resp.data.data.meta,
          })
        )
        toast.success(resp.data.message)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }
  const handleUpdateEducationalBackground = (data) => {
    const newMeta = {
      ...meta,
    }

    if (eduToggle) {
      setEducationalData([])
      newMeta.educationalData = []
    } else if (data?.educationLevel === '') {
      return toast.error('Please add an educational background')
    } else {
      setEducationalData((prevState) => [...prevState, data])
      newMeta.educationalData = [...educationalData, data]
    }

    const reqBody = {
      meta: JSON.stringify(newMeta),
    }

    apiClient
      .put(`/applicant/${userId}/update`, reqBody)
      .then((resp) => {
        localStorage.setItem(
          'educationalData',
          JSON.stringify([...educationalData, data])
        )

        localStorage.setItem(
          'applicant-cv-data',
          JSON.stringify({
            ...cvData,
            meta: resp.data.data.meta,
          })
        )
        toast.success(resp.data.message)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }
  const handleDeleteEducationalBackgroundItem = (filteredItems) => {
    const newMeta = {
      ...meta,
      educationalData: filteredItems,
    }

    const reqBody = {
      meta: JSON.stringify(newMeta),
    }
    apiClient
      .put(`/applicant/${userId}/update`, reqBody)
      .then((resp) => {
        localStorage.setItem(
          'applicant-cv-data',
          JSON.stringify({
            ...cvData,
            meta: resp.data.data.meta,
          })
        )
        toast.success(resp.data.message)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }
  const handleUpdateSkills = () => {
    setFullSkills([skills, softSkills])
    const newMeta = JSON.stringify({
      ...meta,
      fullSkills: [skills, softSkills],
    })

    const mappedHeadlines = items?.filter((item) =>
      professionalHeadlines.includes(item.id)
    )

    const reqBody = {
      meta: newMeta,
      professional_headline: JSON.stringify(mappedHeadlines),
    }

    apiClient
      .put(`/applicant/${userId}/update`, reqBody)
      .then((resp) => {
        localStorage.setItem(
          'applicant-cv-data',
          JSON.stringify({
            ...cvData,
            meta: resp.data.data.meta,
            professional_headline: resp.data.data.professional_headline,
          })
        )

        dispatch(setHasSkillChange(true))
        toast.success(resp.data.message)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }

  const handleUpdateCharacterRef = (data) => {
    setCharacterReferences(data)

    const newMeta = JSON.stringify(data)
    const reqBody = {
      character_reference: newMeta,
    }
    apiClient
      .put(`/applicant/${userId}/update`, reqBody)
      .then((resp) => {
        localStorage.setItem(
          'applicant-cv-data',
          JSON.stringify({
            ...cvData,
            character_reference: resp.data.data.character_reference,
          })
        )
        setModal('')
        toast.success(resp.data.message)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }

  const handleDeleteCharacterRef = (data) => {
    setCharacterReferences(data)
    const reqBody = {
      character_reference: JSON.stringify(data),
    }
    apiClient
      .put(`/applicant/${userId}/update`, reqBody)
      .then((resp) => {
        localStorage.setItem(
          'applicant-cv-data',
          JSON.stringify({
            ...cvData,
            character_reference: resp.data.data.character_reference,
          })
        )

        toast.success(resp.data.message)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }

  useEffect(() => {
    setCharacterReferences(characterReferenceMeta)
  }, [])

  // FIXME: WFH Setup and Remote Fitness Level not appearing after going to /profile immediately upon user account creation (uploaded cv)

  return (
    <StyledProfile>
      <br />
      <div className="section work-container">
        <h4 className="section-title">Employment History</h4>

        {meta?.experienceState?.length > 0 ? (
          meta?.experienceState?.map((job, index) => (
            <div className="section-article" key={job.id || index}>
              <div className="section40">
                <p className="section-btext">
                  {moment(job.experienceFrom).format('MMMM YYYY')} -{' '}
                  {job.experienceCurrentStatus !== 'Currently employed'
                    ? moment(job.experienceTo).format('MMMM YYYY')
                    : 'Present'}
                </p>
                <p className="section-ltext">
                  {job.companyName}
                  &nbsp; - &nbsp;
                  {job.companyLocation}
                </p>
              </div>
              <div className="section60">
                <p className="section-btext">{job.jobTitle}</p>
                <p
                  className="section-rtext"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(job?.jobDescriptionHtml),
                  }}
                ></p>
              </div>
            </div>
          ))
        ) : (
          <p>No prior employment history</p>
        )}
        <img
          src={pencil}
          alt="pencil icon"
          className="icon-edit"
          onClick={() => {
            handleModalToggle()
            setModal('employment')
          }}
        />
      </div>

      <div className="section work-container">
        <h4 className="section-title">Education</h4>
        {meta?.educationalData?.length > 0 ? (
          meta?.educationalData?.map((educ, index) => (
            <div className="section-article" key={educ.id || index}>
              <div className="section40">
                <p className="section-btext">
                  {moment(educ.from).format('MMMM YYYY')} -{' '}
                  {educ.currentStatus !== 'currently enrolled'
                    ? moment(educ.to).format('MMMM YYYY')
                    : 'Present'}
                </p>
              </div>
              <div className="section60">
                <p className="section-btext">
                  {educ.educationLevel} in {educ.fieldOfStudy}
                </p>
                <p className="section-rtext">
                  {educ.school}, {educ.schoolLocation}
                </p>
              </div>
            </div>
          ))
        ) : (
          <p>No prior educational background</p>
        )}

        <img
          src={pencil}
          alt="pencil icon"
          className="icon-edit"
          onClick={() => {
            handleModalToggle()
            setModal('education')
          }}
        />
      </div>

      {meta !== null && (
        <div className="section work-container section-article">
          {meta?.fullSkills !== null && (
            <div className="section50 pad20">
              <h4 className="section-title">Technical Skills</h4>
              <br />
              <ul className="technicalSkillList">
                {meta.fullSkills[0].map((skill) => (
                  <li key={skill.id}>
                    <div className="skill-section-article">
                      <div className="section40">
                        <span className="section-stext">{skill.skill}</span>
                        <br />
                        <span className="section-ltext">
                          {getExperienceLevel(parseInt(skill.experience))}
                        </span>
                        <br />
                      </div>
                      <div className="section60">
                        <div className="progress-container">
                          <div
                            className={`progress-bar skill${skill.experience}`}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {meta?.fullSkills !== null && meta?.fullSkills.length > 1 && (
            <div className="section50">
              <h4 className="section-title">Personal Skills</h4>
              <br />
              <div className="list-container">
                {meta.fullSkills[1].map((skill) => (
                  <div className="personal-skill-item" key={skill.value}>
                    {skill.label}
                  </div>
                ))}
              </div>
            </div>
          )}
          <img
            src={pencil}
            alt="pencil icon"
            className="icon-edit"
            onClick={() => {
              handleModalToggle()
              setModal('skill')
            }}
          />
        </div>
      )}

      {characterReferences && (
        <div className="section work-container section-article">
          {characterReferences !== null && (
            <div className="section50 pad20">
              <h4 className="section-title">Character References</h4>
              <br />
              <ul className="technicalSkillList">
                {characterReferences?.map((cr) => (
                  <li key={cr.id}>
                    <div className="skill-section-article">
                      <div className="section60">
                        <span className="section-stext">{cr.crName}</span>
                        <br />
                        <span className="section-ltext">{cr.crEmail}</span>
                        <br />
                        <span className="section-ltext">{cr.crPhone}</span>
                        <br />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <img
            src={pencil}
            alt="pencil icon"
            className="icon-edit"
            onClick={() => {
              handleModalToggle()
              setModal('characterRef')
            }}
          />
        </div>
      )}

      {wfh !== null && (
        <div className="section work-container">
          <h4 className="section-title">WFH Setup</h4>
          <br />
          <div className="list-container-other">
            <ul>
              <li>
                <span className="section-stext">Download (MBPS)</span>
                <span className="section-btext">
                  {internetSpeedTest?.downloadSpeed}
                </span>
              </li>
              <li>
                <span className="section-stext">Upload (MBPS)</span>
                <span className="section-btext">
                  {internetSpeedTest?.uploadSpeed}
                </span>
              </li>
              <li>
                <span className="section-stext">Ping (MS)</span>
                <span className="section-btext">
                  {internetSpeedTest?.pingTime}
                </span>
              </li>
              <li>
                <span className="section-stext">Operating System</span>
                <span className="section-btext">{computerSpec?.os}</span>
              </li>
              <li>
                <span className="section-stext">OS Version</span>
                <span className="section-btext">{computerSpec?.osVersion}</span>
              </li>
              <li>
                <span className="section-stext">Processor</span>
                <span className="section-btext">{computerSpec?.processor}</span>
              </li>
              <li>
                <span className="section-stext">Installed RAM</span>
                <span className="section-btext">{computerSpec?.ram} GB</span>
              </li>
              <li>
                <span className="section-stext">Webcam</span>
                <span className="section-btext">{peripherals?.webCamera}</span>
              </li>
              <li>
                <span className="section-stext">Headset</span>
                <span className="section-btext">{peripherals?.headSet}</span>
              </li>
              <li>
                <span className="section-stext">Microphone</span>
                <span className="section-btext">{peripherals?.microphone}</span>
              </li>
            </ul>
            <img
              src={pencil}
              alt="pencil icon"
              className="icon-edit"
              onClick={() => {
                setRemotePage(1)
                history.push('/remote-setup/update')
              }}
            />
          </div>
        </div>
      )}

      {remoteFitness !== null && (
        <div className="section work-container">
          <h4 className="section-title">Remote Fitness Level</h4>
          <br />
          <div className="remote__fitness">
            <ul>
              <li>
                I am ready to work from home permanently. I will be working from{' '}
                <strong>{remoteFitness?.workStation?.toLowerCase()}</strong>.
              </li>
              <li>
                My hardware set-up is perfect for working from home. I have a{' '}
                <strong>{remoteFitness?.device?.toLowerCase()}</strong>
              </li>
              <li>
                I have the same mindset and am currently looking for a{' '}
                <strong>{remoteFitness?.workSchedule?.toLowerCase()}</strong>.
              </li>
              <li>
                Considering my personal lifestyle and professional experience, I
                work best during{' '}
                <strong>{remoteFitness?.workShift?.toLowerCase()}</strong>.
              </li>
              <li>
                I am dedicated to building my work-from-home career and{' '}
                <strong>{remoteFitness?.timeTracker?.toLowerCase()}</strong>.
              </li>
            </ul>
            <img
              src={pencil}
              alt="pencil icon"
              className="icon-edit"
              onClick={() => {
                setWorkEthicsPage(1)
                history.push('/remote-fitness-level/update')
              }}
            />
          </div>
        </div>
      )}

      {modal === 'employment' && (
        <EmploymentHistory
          handleHardUpdate={handleUpdateEmploymentHistory}
          handleHardDeleteItem={handleDeleteEmploymentHistoryItem}
        />
      )}
      {modal === 'education' && (
        <EducationalBackgroundUpdate
          handleHardUpdate={handleUpdateEducationalBackground}
          handleHardDeleteItem={handleDeleteEducationalBackgroundItem}
        />
      )}
      {modal === 'skill' && (
        <SkillsUpdate handleHardUpdate={handleUpdateSkills} />
      )}
      {modal === 'characterRef' && (
        <CharacterRefUpdate
          handleHardUpdate={handleUpdateCharacterRef}
          handleHardDelete={handleDeleteCharacterRef}
        />
      )}
    </StyledProfile>
  )
}

export const StyledProfile = styled.section`
  .section-title {
    font-weight: 600;
    font-size: 16px;
    color: #31374f;
  }

  .section {
    padding: 10px 0;
    border-bottom: 1px solid #eaeaf8;
    position: relative;
  }

  .icon-edit {
    position: absolute;
    top: 10px;
    right: 0px;
    color: #31374f;
    cursor: pointer;
  }

  .section-article {
    display: flex;
    padding: 10px 0;
  }

  .skill-section-article {
    display: flex;
    padding: 5px 0;
  }

  .section-title {
    width: 100%;
  }

  .section40 {
    width: 40%;
  }

  .section60 {
    width: 60%;
  }

  .section-btext {
    font-size: 14px;
    color: #31374f;
    font-weight: 600;
  }

  .section-rtext {
    font-size: 12px;
    color: #31374f;
    font-weight: 400;
    line-height: 22.5px;
    text-align: justify;
  }

  .section-stext {
    font-size: 14px;
    color: #31374f;
    font-weight: 400;
  }

  .section-ltext {
    font-size: 12px;
    color: #7d849e;
    font-weight: 500;
  }

  .section50 {
    width: 50%;
  }

  .technicalSkillList {
    list-style-type: none;
  }

  .progress-container {
    width: 100%;
    background-color: #f3f3f3;
    height: 8px;
    border-radius: 10px;
    margin-top: 10px;
  }

  .progress-bar {
    width: 0;
    height: 30px;
    background: linear-gradient(to right, #c2de50, #5cca95);
    text-align: center;
    line-height: 30px;
    color: white;
    height: 8px;
    border-radius: 10px;
    margin-top: 10px;
  }

  .progress-bar.skill5 {
    width: 100%;
  }
  .progress-bar.skill4 {
    width: 80%;
  }
  .progress-bar.skill3 {
    width: 60%;
  }
  .progress-bar.skill2 {
    width: 40%;
  }
  .progress-bar.skill1 {
    width: 20%;
  }

  .pad20 {
    padding-right: 20px;
  }

  .personal-skill-item {
    padding: 8px 12px;
    border: 1px solid #31374f;
    border-radius: 20px;
    font-size: 14px;
    margin-right: 5px;
    margin-bottom: 10px;
    display: inline;
    color: #31374f;
  }

  .list-container {
    display: flex;
    flex-wrap: wrap;
  }

  .list-container-other {
    display: flex;
    flex-wrap: wrap;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
    }

    li {
      flex-basis: calc(50% - 10px);
      margin-bottom: 5px;
    }

    li span {
      width: 50%;
      display: inline-block;
      padding-right: 5px;
    }
  }

  .remote__fitness {
    display: flex;
    flex-wrap: wrap;

    ul {
      padding-left: 1rem;
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    .section40 {
      width: 100%;
    }
    .section60 {
      width: 100%;
    }
    .section-article {
      display: block;
    }

    .list-container-other ul {
      display: block;
      width: 100%;
    }
    .section50 {
      width: 100%;
    }
  }
  p {
    margin-top: 10px;
  }
`

export default MyCvTab
