import styled from 'styled-components'

const P = styled.p`
  font-size: ${({ size }) => size || '.875rem'};
  font-weight: ${({ weight }) => weight || '300'};
  color: ${({ color }) => color || '#0E1324'};
  margin-bottom: ${({ mb }) => mb || '0'};
  margin-top: ${({ mt }) => mt || '0'};
  text-align: ${({ align }) => align || 'left'};
  line-height: ${({ lineHeight }) => lineHeight || '1.5'};
`

export default P
