import React from 'react'

import styled from 'styled-components'

import { RiAlertLine } from 'react-icons/ri'

const Error = ({ error }) => {
  return (
    <ErrorContainer>
      <RiAlertLine className="icons" />
      {error}
    </ErrorContainer>
  )
}

export default Error

const ErrorContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 300;
  text-align: center;
  color: #dc2929;
  margin-bottom: 1.5rem;

  .icons {
    font-size: 0.85rem;
    color: #dc2929;
    margin-right: 5px;
  }
`
