import ReactGA from 'react-ga4'

import { GA4_TRACKING_ID } from '../constants/constant'

class ReactGA4 {
  initialize() {
    ReactGA.initialize(GA4_TRACKING_ID)
  }

  sendPageView(title) {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: title,
    })
  }
}

export default ReactGA4
