import React, { useContext } from 'react'

import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { BsLaptop } from 'react-icons/bs'
import { IoIosArrowForward } from 'react-icons/io'

import remote from '../../assets/images/remote.svg'

import { ContentWrapper } from '../../layouts/AuthLayouts'

// component
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import ViewDetails from './components/ViewDetails'

import { ModalContext } from '../../context/ModalContext'

const RemoteHome = () => {
  const [modal, setModal] = React.useState(false)
  const { handleModalToggle, compSpec } = useContext(ModalContext)

  const handleShowModal = () => {
    handleModalToggle()
    setModal(!modal)
  }

  return (
    <>
      {modal && <ViewDetails modal={modal} setModal={setModal} />}
      <ViewDetails />
      <Header />
      <ContentWrapper>
        <Container>
          <img src={remote} alt="remote" />

          <h2 className="sub-heading">Work From Home Setup</h2>

          <p className="paragraph">
            Tell us about your internet speed, computer specifications and other
            tools
          </p>

          <Link className="btnIcon" to="/remote-setup/create">
            <div className="detail">
              <BsLaptop className="icon" />
              Proceed to WFH Setup
            </div>
            <IoIosArrowForward className="forwardIcon" />
          </Link>

          {compSpec.os !== '' && (
            <button onClick={handleShowModal} className="skipBtn">
              View Current Setup
            </button>
          )}
        </Container>
      </ContentWrapper>
      <Footer />
    </>
  )
}

export default RemoteHome

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 325px;
    height: auto;
  }

  .sub-heading {
    font-size: 1.5rem;
    font-weight: 300;
    color: #31374f;
    line-height: 1.5;
    letter-spacing: 0.05em;
    text-align: center;
    margin-top: 2rem;
  }

  .paragraph {
    font-size: 1rem;
    font-weight: 300;
    color: #31374f;
    letter-spacing: 0.03em;
    text-align: center;
    margin-top: 0.5rem;
  }

  .btnIcon {
    width: 100%;
    max-width: 409px;
    height: 4rem;
    border-radius: 100px;
    border: 1px solid #858aa0;
    padding: 1.25rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: #31374f;
    font-size: 1.125rem;
    font-weight: 300;
    letter-spacing: 0.03em;
    margin-top: 2rem;

    .detail {
      display: flex;
      align-items: center;

      @media (max-width: 768px) {
        font-size: 1rem;
      }

      .icon {
        color: #ff7b9b;
        margin-right: 0.75rem;
      }
    }

    &:hover {
      border-color: #31374f;
    }
  }

  .skipBtn {
    margin-top: 1rem;
    text-align: center;
    color: #1877f2;
    font-size: 0.875rem;
    font-weight: 300;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;

    &:hover {
      color: #1877f2;
      font-weight: 500;
    }
  }
`
