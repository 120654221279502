import React, { useContext, useEffect, useState } from 'react'

import axios from 'axios'
import styled from 'styled-components'

import Modal from '../../../components/Modal'

import { baseUrl } from '../../../constants/constant'

import { PagesContext } from '../../../context/FormPagesContext'
import { ModalContext } from '../../../context/ModalContext'

import { P } from '../../../styles/Typograph.styles'

const ViewDetails = ({ setModal }) => {
  const {
    handleModalToggle,
    setPheripheral,
    setCompSpec,
    compSpec,
    pheripheral,
  } = useContext(ModalContext)

  const { userDetails } = useContext(PagesContext)

  const [remoteFitness, setRemoteFitness] = useState({})

  // Get User past test results
  useEffect(() => {
    axios
      .get(`${baseUrl}/user/${userDetails?.user_id}/work-home`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((res) => {
        const result = res.data.resp

        if (result) {
          setPheripheral(JSON.parse(result.peripherals) || [])
          setCompSpec(JSON.parse(result.computer_specification) || [])
          setRemoteFitness(
            JSON.parse(result.meta) || {
              workStation: '',
              device: '',
              workSchedule: '',
              workShift: '',
              timeTracker: '',
            }
          )
        }
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <Modal
      title="Your WFH Setup"
      btnTitle={'Close'}
      btnFunc={() => {
        handleModalToggle()
        setModal(false)
      }}
      noCancel
    >
      <div>
        <P size="1rem" weight="500">
          Remote Work Ethics
        </P>
        <WFHListWrapper>
          <P>Operating System</P>
          <P weight="500">
            {compSpec?.os && compSpec?.os !== ''
              ? compSpec?.os
              : 'Not Specified'}
          </P>

          <P>Processor</P>
          <P weight="500">
            {compSpec?.processor && compSpec?.processor !== ''
              ? compSpec?.processor
              : 'Not Specified'}
          </P>

          <P>Installed RAM</P>
          <P weight="500">
            {compSpec?.ram && compSpec?.ram !== ''
              ? `${compSpec?.ram} RAM`
              : 'Not Specified'}
          </P>

          <P>Webcam</P>
          <P weight="500">{pheripheral?.webCamera || 'No'}</P>

          <P>Headset</P>
          <P weight="500">{pheripheral?.headSet || 'No'}</P>

          <P>Microphone</P>
          <P weight="500">{pheripheral?.microphone || 'No'}</P>
        </WFHListWrapper>

        <P size="1rem" weight="500">
          Remote Fitness Level
        </P>
        <RemoteFitnessListWrapper>
          <P>
            I am ready to work from home permanently. I will be working from
            &nbsp;<strong>{remoteFitness.workStation}</strong>
          </P>
          <P>
            My hardware set-up is perfect for working from home. I have a &nbsp;
            <strong>{remoteFitness.device}</strong>
          </P>
          <P>
            I have the same mindset and am currently looking for a &nbsp;
            <strong>{remoteFitness.workSchedule}</strong>
          </P>
          <P>
            Considering my personal lifestyle and professional experience, I
            work best during &nbsp;<strong>{remoteFitness.workShift}</strong>
          </P>
          <P>
            I am dedicated to building my work-from-home career and &nbsp;
            <strong>{remoteFitness.timeTracker}</strong>
          </P>
        </RemoteFitnessListWrapper>
      </div>
    </Modal>
  )
}

export default ViewDetails

const WFHListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  margin: 1rem 0;
`
const RemoteFitnessListWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
