import React from 'react'

import styled from 'styled-components'
import { IoClose } from 'react-icons/io5'

const Tutorial = ({ closeTutorial }) => {
  return (
    <StyledTutorial>
      <div className="tutorial-header">
        <h4 className="tutorial-title">Welcome to HOPLA!</h4>
        <IoClose className="close-icon" onClick={closeTutorial} />
      </div>
      <div className="tutorial-steps">
        <div className="tutorial-step">
          <h3 className="tutorial-step-number">01</h3>
          <h4 className="tutorial-step-header">Search Jobs</h4>
          {localStorage.getItem('applicant-data') === null ? (
            <p>Find a vacancy that suits your skills.</p>
          ) : (
            <p>Select vacancy that suits your skills</p>
          )}
        </div>
        <div className="tutorial-step">
          <h3 className="tutorial-step-number">02</h3>
          <h4 className="tutorial-step-header">Click Apply</h4>
          {localStorage.getItem('applicant-data') === null ? (
            <p>Create an account and tell us more about yourself</p>
          ) : (
            <p>Your next career move is just a click away</p>
          )}
        </div>
        <div className="tutorial-step">
          <h3 className="tutorial-step-number">03</h3>
          <h4 className="tutorial-step-header">Wait for Confirmation</h4>
          <p>Sit back and wait for our email confirmation.</p>
        </div>
      </div>
    </StyledTutorial>
  )
}

const StyledTutorial = styled.div`
  flex-direction: column;
  height: 198px;
  margin-bottom: 1rem;
  padding-top: 1.3125rem;
  border: 1px solid #dfe2ec;
  font-size: 1.25rem;

  @media (max-width: 768px) {
    display: none;
  }

  .tutorial-header {
    display: flex;

    .tutorial-title {
      margin: 0 auto;
      font-weight: 400;
    }

    .close-icon {
      font-size: 1.5rem;
      color: #31374f;
      cursor: pointer;
      margin-right: 1.6875rem;
    }
  }

  .tutorial-steps {
    display: flex;
    justify-content: space-between;
    height: 70%;
    padding: 2rem;

    .tutorial-step {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      justify-content: space-around;
      gap: 0.5rem;

      .tutorial-step-number {
        font-weight: 500;
      }

      .tutorial-step-header {
        font-weight: 500;
        font-size: 1.25rem;
      }

      p {
        font-weight: 400;
        font-size: 0.75rem;
      }
    }
  }
`

export default Tutorial
