import React, { useContext, useEffect, useState } from 'react'

import styled from 'styled-components'
import { useSelector } from 'react-redux'

import UploadedCV from './UploadedCV'
import ManualCV from './ManualCV'

import { PagesContext } from '../../../../context/FormPagesContext'
import { ModalContext } from '../../../../context/ModalContext'

const TemplateWrapper = ({ downloadRef }) => {
  const { userDetails } = useContext(PagesContext)

  const { setCompSpec, setPheripheral, setRemoteWork } =
    useContext(ModalContext)

  const uploadedCV = useSelector((state) => state.cv.uploadedCV)

  const [headLines, setHeadlines] = useState([])

  useEffect(() => {
    let wfh = localStorage.getItem('applicant-wfh')

    if (wfh && wfh !== 'null') {
      wfh = JSON.parse(wfh)
      setRemoteWork(JSON.parse(wfh?.meta))
      setCompSpec(JSON.parse(wfh?.computer_specification))
      setPheripheral(JSON.parse(wfh?.peripherals))
    }
  }, [])

  useEffect(() => {
    const list = []
    userDetails &&
      userDetails.professionalHeadlines &&
      userDetails.professionalHeadlines.map((headline) =>
        list.push(headline.item)
      )
    setHeadlines(list.splice(',').join(' • '))
  }, [userDetails])

  return (
    <StyledTemplateWrapper ref={downloadRef}>
      {uploadedCV && uploadedCV.public_id ? (
        <UploadedCV />
      ) : (
        <ManualCV headLines={headLines} />
      )}
    </StyledTemplateWrapper>
  )
}

export default TemplateWrapper

const StyledTemplateWrapper = styled.div`
  width: 100%;
  margin-top: 0.5rem;
  border: 1px solid #aeb3c9;
  border-radius: 3px;
`
