import { useState } from 'react'
import moment from 'moment'

import PDFService from '../lib/PDFService'
import OCRService from '../lib/OCRService'

import { baseUrl } from '../constants/constant'
// import { syncCVData } from './data'

const CVWorker = new Worker('/workers/cv.worker.js')

const useSyncCV = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [images, setImages] = useState([])

  const handleSyncCV2 = async (cvFile) => {
    try {
      console.time('Sync CV Timer')
      setLoading(true)
      // 1. Convert cv file into URL
      const pdfUrl = URL.createObjectURL(cvFile)

      // 2. Convert uploaded CV into image(s) using a web worker
      const pdfService = new PDFService()
      const pageImages = await pdfService.convertPDFToImages(pdfUrl)
      setImages(pageImages)

      // 3. Get all text from images
      const ocrService = new OCRService()
      /* eslint-disable-next-line no-unused-vars */
      const mergedText = await ocrService.convertImagesToText(pageImages)

      // 4. Pass merged text on a web worker. The worker will call the server and create a prompt to Claude AI
      CVWorker.postMessage({
        ocrText: mergedText,
        baseUrl: baseUrl,
        token: localStorage.getItem('token'),
      })

      // 5. Upon completion, Worker will submit the AI-generated response
      let parsedApplicantSkills, parsedApplicantProfile

      CVWorker.onmessage = (message) => {
        parsedApplicantSkills = message.data.response.skills
        parsedApplicantProfile = message.data.response.profile

        let applicantCVData = localStorage.getItem('applicant-cv-data')
        const currentTime = moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        applicantCVData = JSON.parse(applicantCVData)

        applicantCVData.character_reference = JSON.stringify(
          parsedApplicantProfile?.character_reference
        )
        applicantCVData.meta = JSON.stringify({
          ...parsedApplicantProfile?.meta,
          fullSkills: parsedApplicantSkills,
        })

        applicantCVData.first_name = parsedApplicantProfile?.first_name
        applicantCVData.middle_name = parsedApplicantProfile?.middle_name
        applicantCVData.last_name = parsedApplicantProfile?.last_name
        applicantCVData.name = parsedApplicantProfile?.name
        applicantCVData.professional_headline = JSON.stringify(
          parsedApplicantProfile?.professional_headline
        )
        applicantCVData.professional_summary =
          parsedApplicantProfile?.professional_summary
        applicantCVData.years_of_experience =
          parsedApplicantProfile?.years_of_experience
        applicantCVData.updated_at = currentTime

        let applicantData = localStorage.getItem('applicant-data')
        applicantData = JSON.parse(applicantData)

        applicantData.city = parsedApplicantProfile.city
        applicantData.country = parsedApplicantProfile.country
        applicantData.phone_number = parsedApplicantProfile.phone_number

        localStorage.setItem(
          'applicant-cv-data',
          JSON.stringify(applicantCVData)
        )
        localStorage.setItem('applicant-data', JSON.stringify(applicantData))
      }

      setSuccess(true)
      console.timeEnd('Sync CV Timer')

      // // Results: Sync CV Timer: 30545.453857421875 ms
      return {
        profile: parsedApplicantProfile,
        skills: parsedApplicantSkills,
      }
    } catch (processError) {
      setError(processError)
    } finally {
      setLoading(false)
    }
  }

  return { loading, error, handleSyncCV2, success, images }
}

export default useSyncCV
