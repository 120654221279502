import React, { useEffect } from 'react'

import AuthLayouts from '../layouts/AuthLayouts'
import { StyledVerify } from './Verify'

import { useHistory, useParams } from 'react-router'

import checked from '../assets/icons/check-circle.svg'
import axios from 'axios'
import { IoCloseCircleOutline } from 'react-icons/io5'
import ScrollToTop from '../components/ScrollRestoration'
import { baseUrl } from '../constants/constant'

const VerifySuccessful = () => {
  const { token } = useParams()
  const history = useHistory()
  const [email] = React.useState(localStorage.getItem('email'))
  const [error, setError] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  // split token with '-' and remove the last part of the array and join the array with '-'
  const tokenArray = token.split('-')
  const userId = tokenArray[0]
  const newToken = tokenArray.slice(1, tokenArray.length).join('-')

  useEffect(() => {
    setLoading(true)
    axios
      .post(`${baseUrl}/user/verify-user`, { code: newToken, userId })
      .then((res) => {
        setMessage('Your account has been verified!')
        // set timeout to redirect to login page
        setLoading(false)
        setTimeout(() => {
          history.push('/login')
        }, 4000)
      })
      .catch((err) => {
        console.log(err)
        setError('')

        if (err.response.data === 'User already verified proceed to login') {
          setError('User already verified proceed to login')
          setMessage('User already verified proceed to login')
          setTimeout(() => {
            history.push('/login')
          }, 5000)
        } else {
          setMessage('Oops, Something went wrong.')
          setError('Something went wrong!')
        }
        setLoading(false)
      })
  }, [])

  const handleRetry = () => {
    if (error === 'User already verified proceed to login') {
      history.push('/login')
    } else {
      axios
        .post(`${baseUrl}/user/retry-verification`, { email: email })
        .then((res) => {
          history.push('/verification')
        })
    }
  }

  return (
    <AuthLayouts width="500px">
      <ScrollToTop />
      {loading ? (
        <p>Loading .....</p>
      ) : (
        <StyledVerify>
          {error !== '' ? (
            <IoCloseCircleOutline className="errorImge" />
          ) : (
            <img src={checked} alt="checked icon" className="icon" />
          )}
          <h1 className={error ? 'error' : ''}>{message}</h1>
          {error !== '' ? (
            <p>
              <span onClick={handleRetry}>
                {error === 'User already verified proceed to login'
                  ? 'Go to Login'
                  : 'Click here to try again,'}{' '}
              </span>
              Seems the link as expired or something else is wrong with it.
            </p>
          ) : (
            <p>
              <span onChange={() => history.push('/login')}>Click here</span> if
              you’re not automatically redirected to the login page in 5
              seconds. Login to start using your HOPLA Careers account
            </p>
          )}
        </StyledVerify>
      )}
    </AuthLayouts>
  )
}

export default VerifySuccessful
