import React, { useContext, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { RiVipCrownLine } from 'react-icons/ri'
import { IoIosAdd } from 'react-icons/io'
import { StyledKeywordSearcher } from '../KeywordSearcher'

import styled from 'styled-components'
import { PagesContext } from '../../context/FormPagesContext'

const Phrase = ({ item, id }) => {
  const { markAsCompleted, phrases, setProfesionalSum } =
    useContext(PagesContext)

  const handleAdd = async () => {
    markAsCompleted(id)

    const newItems = await phrases.filter((phrase) => phrase.status === 'done')

    let newSummaries = ''

    await newItems.forEach((phrase) => {
      newSummaries = newSummaries + `<p>${phrase.item}</p>`
    })

    setProfesionalSum((prevState) => (prevState += newSummaries))
  }

  return (
    <StyledPhrase onClick={handleAdd}>
      <div className="icon-wrapper">
        <IoIosAdd className="icon" />
      </div>
      <div className="text">{item}</div>
    </StyledPhrase>
  )
}

const PhraseContainer = ({ newPhrases, setNewPhrases, summaryToggle }) => {
  const { phrases } = useContext(PagesContext)

  const [emptyFilter, setEmptyFilter] = useState(false)

  const handleFilter = (e) => {
    const filteredPhrases = phrases.filter((phrase) =>
      phrase.item.toLowerCase().includes(e.target.value.toLowerCase())
    )

    if (filteredPhrases.length === 0) {
      setEmptyFilter(true)
    } else {
      setEmptyFilter(false)
    }

    setNewPhrases(filteredPhrases)
  }

  return (
    <StyledKeywordSearcher
      style={{ display: `${summaryToggle ? 'block' : 'none'}` }}
      summaryToggle={summaryToggle}
    >
      <div className="heading">
        <FiSearch className="icon" />
        <input
          className="input2"
          type="text"
          onChange={handleFilter}
          placeholder="Filter phrases with keywords and job titles"
        />
      </div>

      {emptyFilter ? (
        <span className="notFound">Summary not found</span>
      ) : (
        <>
          <div className="subTitle">
            <RiVipCrownLine className="icon crown" />
            <span className="text">Popular Phrases</span>
          </div>

          <div className="lists">
            {newPhrases?.map(({ item, id }) => (
              <Phrase key={id} id={id} item={item} />
            ))}
          </div>
        </>
      )}
    </StyledKeywordSearcher>
  )
}

export default PhraseContainer

const StyledPhrase = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.5rem;
  cursor: pointer;

  .icon-wrapper {
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #f2f3f8;
    margin-top: 0.4rem;
  }

  .icon {
    font-size: 1rem;
    color: #8e93a6;
  }

  .text {
    margin-left: 0.75rem;
    font-size: 0.75rem;
    color: #31374f;
    letter-spacing: 0.03em;
    line-height: 1.5;
  }
`
