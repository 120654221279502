import React, { useContext } from 'react'
import { ModalContext } from '../../context/ModalContext'

import AuthLayouts from '../../layouts/AuthLayouts'
import { StyledMain } from '../onboarding'
import WorkEthicsSidebar from './WorkEthicsSidebar'
import WorkEthicsStepFive from './WorkEthicsStepFive'
import WorkEthicsStepFour from './WorkEthicsStepFour'
import WorkEthicsStepOne from './WorkEthicsStepOne'
import WorkEthicsStepThree from './WorkEthicsStepThree'
import WorkEthicsStepTwo from './WorkEthicsStepTwo'

const WorkEthicsStart = () => {
  const { workEthicsPage } = useContext(ModalContext)

  return (
    <AuthLayouts width="750px">
      <StyledMain>
        <WorkEthicsSidebar />

        {workEthicsPage === 1 && <WorkEthicsStepOne />}
        {workEthicsPage === 2 && <WorkEthicsStepTwo />}
        {workEthicsPage === 3 && <WorkEthicsStepThree />}
        {workEthicsPage === 4 && <WorkEthicsStepFour />}
        {workEthicsPage === 5 && <WorkEthicsStepFive />}
      </StyledMain>
    </AuthLayouts>
  )
}

export default WorkEthicsStart
