import React, { useContext, useEffect, useState, useMemo } from 'react'

import CharacterRefDetails from '../CharacterRefDetails'
import ScrollToTop from '../ScrollRestoration'
import PhoneInput from 'react-phone-input-2'
import Modal from '../Modal'
import { StyledProfileDetailsUpdate } from './ProfileDetailsUpdate'
import { FiPlus } from 'react-icons/fi'
import toast, { Toaster } from 'react-hot-toast'
import Joi from 'joi'

import { PagesContext } from '../../context/FormPagesContext'
import { ModalContext } from '../../context/ModalContext'

const CharacterRefUpdate = ({ handleHardUpdate, handleHardDelete }) => {
  const [characterReferencesTemp, setCharacterReferencesTemp] = useState([])
  const [show, setShow] = useState(true)
  const [clearField, setClear] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [editItem, setEditItem] = useState(null)
  const [fieldErrors, setFieldErrors] = useState({})

  const { handleModalToggle } = useContext(ModalContext)
  const {
    characterReferences,
    setCharacterReferences,
    crName,
    setCrName,
    crEmail,
    setCrEmail,
    crPhone,
    setCrPhone,
  } = useContext(PagesContext)

  const validationSchema = useMemo(() => {
    return Joi.object({
      id: Joi.date().not().required(),
      crName: Joi.string().label('Full Name').required(),
      crEmail: Joi.string()
        .label('Email')
        .email({ tlds: { allow: false } })
        .required(),
      crPhone: Joi.string().label('Contact Number').required(),
    })
  }, [])

  useEffect(() => {
    setCharacterReferencesTemp(characterReferences)
  }, [])

  useEffect(() => {
    setIsEdit(clearField)
  }, [clearField])

  const clear = () => {
    setCrName('')
    setCrEmail('')
    setCrPhone('')
  }

  const addNewCharacterRef = () => {
    if (!show) {
      setShow(true)
    }
  }

  const displayErrors = (result) => {
    const validationErrors = result.error.details.reduce((acc, curr) => {
      acc[curr.context.key] = curr.message.replace(/"/g, '')
      return acc
    }, {})

    setFieldErrors(validationErrors)
    toast.error('Please correct the validation errors before proceeding.')
  }

  const handleEdit = (id) => {
    setShow(true)
    setClear(true)

    if (editItem !== null) {
      setCharacterReferencesTemp((prevState) => [...prevState, editItem])
    }
    setCharacterReferencesTemp((prevState) =>
      prevState.filter((x) => x.id !== id)
    )

    setEditItem(characterReferencesTemp.filter((x) => x.id === id)[0])

    const item = characterReferencesTemp.filter((x) => x.id === id)[0]
    setCrName(item.crName)
    setCrEmail(item.crEmail)
    setCrPhone(item.crPhone)
  }

  const handleDelete = (id) => {
    setCharacterReferencesTemp((prevState) =>
      prevState.filter((x) => x.id !== id)
    )
  }

  const handleSave = () => {
    const formData = {
      id: new Date(),
      crName: crName,
      crEmail: crEmail,
      crPhone: crPhone,
    }
    const result = validationSchema.validate(formData, { abortEarly: false })
    if (!result.error) {
      setCharacterReferencesTemp((prevState) => [...prevState, formData])
      // setCharacterReferences((prevState) => [...prevState, formData])

      setEditItem(null)
      clear()
      setShow(false)
      setClear(false)
    } else {
      displayErrors(result)
    }
  }

  const handleStoreData = () => {
    if (handleHardUpdate) {
      if (clearField && (crName !== '' || crEmail !== '' || crPhone !== '')) {
        toast.error('Save or remove your changes')
      } else if (
        clearField &&
        (crName === '' || crEmail === '' || crPhone === '')
      ) {
        toast.error(
          'Fill all neccessary fields or click remove button to minimize the fields'
        )
      } else {
        handleHardUpdate(characterReferencesTemp)
      }
    } else {
      const formData = {
        id: new Date(),
        crName: crName,
        crEmail: crEmail,
        crPhone: crPhone,
      }
      const result = validationSchema.validate(formData, { abortEarly: false })

      if (!result.error || !show) {
        setCharacterReferences(() => [
          ...characterReferencesTemp,
          {
            id: new Date(),
            crName: crName,
            crEmail: crEmail,
            crPhone: crPhone,
          },
        ])
        clear()
        handleModalToggle()
      } else {
        displayErrors(result)
      }
    }
  }

  const handleAddCharRef = () => {
    setClear(true)
    setShow(true)
    addNewCharacterRef()
  }

  const handleRemove = () => {
    setShow(false)
    setClear(false)
    clear()
    setIsEdit(false)
  }

  // const isValidInputs = () => {
  //   if (crName === '' || crEmail === '' || crPhone === '') {
  //     toast.error(
  //       'Fill all neccessary fields or click remove button to minimize the fields'
  //     )
  //     return false
  //   } else if (!isValidEmail()) {
  //     toast.error('Invalid email address')
  //     return false
  //   } else {
  //     return true
  //   }
  // }

  // function isValidEmail() {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  //   return emailRegex.test(crEmail)
  // }

  return (
    <Modal
      title="Character Reference"
      btnTitle="Update"
      btnFunc={handleStoreData}
    >
      <StyledProfileDetailsUpdate>
        <ScrollToTop />
        {characterReferencesTemp?.map((c) => (
          <CharacterRefDetails
            key={c.id}
            crName={c.crName}
            crEmail={c.crEmail}
            crPhone={c.crPhone}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            id={c.id}
          />
        ))}

        {show && (
          <>
            <div className="inputs">
              <label htmlFor="jobTitle">
                Full Name <span className="required">*</span>
              </label>
              <input
                className={`input ${'crName' in fieldErrors && 'has-error'}`}
                id="name"
                type="text"
                placeholder="Full Name"
                onChange={(e) => setCrName(e.target.value)}
                value={crName}
              />
              {'crName' in fieldErrors && (
                <sub style={{ color: 'red' }}>{fieldErrors.crName}</sub>
              )}
            </div>
            <div className="inputs">
              <label htmlFor="jobTitle">
                Email <span className="required">*</span>
              </label>
              <input
                className={`input ${'crEmail' in fieldErrors && 'has-error'}`}
                id="email"
                type="text"
                placeholder="Email"
                onChange={(e) => setCrEmail(e.target.value)}
                value={crEmail}
              />
              {'crEmail' in fieldErrors && (
                <sub style={{ color: 'red' }}>{fieldErrors.crEmail}</sub>
              )}
            </div>
            <div className="inputs">
              <label htmlFor="firstName">
                Contact Number<span className="required">*</span>
              </label>
              <PhoneInput
                inputStyle={{ borderColor: 'crPhone' in fieldErrors && 'red' }}
                country={'ph'}
                value={crPhone}
                onChange={(phone) => setCrPhone(phone)}
              />
              {'crPhone' in fieldErrors && (
                <sub style={{ color: 'red' }}>{fieldErrors.crPhone}</sub>
              )}
            </div>
          </>
        )}

        <Toaster position="top-right" reverseOrder={false} />

        <div className="addContainer mt">
          {!clearField && (
            <button onClick={handleAddCharRef} className="keywordBtn">
              <span className="mr">
                <FiPlus className="icon" />
              </span>
              Add another character reference
            </button>
          )}
          {isEdit && (
            <button onClick={handleSave} className="keywordBtn save-edit">
              Save
            </button>
          )}
          {clearField && (
            <button onClick={handleRemove} className="keywordBtn remove">
              Remove
            </button>
          )}
        </div>
      </StyledProfileDetailsUpdate>
    </Modal>
  )
}

export default CharacterRefUpdate
