import React, { useEffect } from 'react'
import AuthLayouts from '../layouts/AuthLayouts'

// icons
import check from '../assets/images/check-circle.svg'
import { StyledVerify } from './PasswordVerification'
import { useHistory } from 'react-router'
import ScrollToTop from '../components/ScrollRestoration'

const PasswordSuccessful = () => {
  const history = useHistory()

  useEffect(() => {
    // redirect to login page after 5 seconds
    setTimeout(() => {
      history.push('/login')
    }, 3000)
  }, [])

  const handleRedirect = () => {
    history.push('/login')
  }

  return (
    <AuthLayouts width="500px">
      <ScrollToTop />
      <StyledVerify>
        <img src={check} alt="mail" className="icon" />
        <h1>Password Changed Successfully</h1>
        <p>
          <span onClick={handleRedirect}>Click here</span> if you’re not
          automatically redirected to the login page in 5 seconds. Login to
          start using your HOPLA Careers account
        </p>
      </StyledVerify>
    </AuthLayouts>
  )
}

export default PasswordSuccessful
