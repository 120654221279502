import React from 'react'
import styled from 'styled-components'

import Footer from '../components/Footer'

import logo from '../assets/images/logo.svg'

const Maintenance = () => {
  return (
    <Body>
      <Header>
        <img src={logo} alt="logo" />
      </Header>
      <Article>
        <Heading>We&rsquo;ll be back soon!</Heading>
        <p>
          Exciting updates are on the way! We’re enhancing our site to better
          connect you with career opportunities at HOPLA. Check back soon for a
          smoother path to your next career.
        </p>
        <p>&mdash; The Team</p>
      </Article>
      <Footer />
    </Body>
  )
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  padding: 5vh 5vw;
  font-family: Helvetica, sans-serif;
  color: #333;
  font-size: 1.25rem;
`

const Header = styled.div`
  height: 2.2rem;

  img {
    height: 100%;
    width: auto;
  }
`

const Heading = styled.h1`
  font-size: 3rem;
  padding-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`

const Article = styled.article`
  display: block;
  text-align: left;
  max-width: 650px;
  width: 100%;
  margin: 0 auto;

  p {
    padding-bottom: 1.5rem;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`

export default Maintenance
