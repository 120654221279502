import React from 'react'

import ReactPaginate from 'react-paginate'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import JobCard from './JobCard'

import { addFilter } from '../../../redux/feature/jobSlice'

const JobList = ({ jobs }) => {
  const dispatch = useDispatch()
  const pagination = useSelector(state => state.jobs.pagination);

  function handlePageClick(page) {
    dispatch(addFilter({ page: page.selected + 1 }))
  }

  return (
    <StyledJobList>
      <div className="job_container">
        {
          jobs && jobs?.data?.jobs.length && jobs?.data?.jobs?.map(job => (
            <JobCard key={job.id} job={job} />
          ))
        }
      </div>

      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        onNextClick={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pagination?.pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageClassName="page-item"
        previousClassName="previous"
        nextClassName="next"
        activeClassName="active"
        disabledClassName="disabled"
        initialPage={pagination?.page - 1}
      />
    </StyledJobList>
  )
}

export default JobList

const StyledJobList = styled.div`
  width: 100%;
  padding: 3px;

  .list_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.125rem;

    .list_header_title {
      font-size: 0.875rem;
      font-weight: 400;
      color: #31374f;
    }
  }

  .job_container {
    width: 100%;
    height: calc(100vh - 250px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (max-width: 768px) {
      height: auto;
    }
  }

  .pagination {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    max-width: 266px;
    height: 38px;
    list-style: none;
    margin-top: 1rem;
  }

  .page-item, .next, .previous {
    height: 100%;
    width: 100%;
    background: #FFFFFF;
    border: .5px solid #AEB3C9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    font-weight: 300;
    color: #858AA0;
    cursor: pointer;

    &:hover, .active {
      border: .5px solid #FF5880;
      color: #0E1324;
    }
  }

  .active {
    border: .5px solid #FF5880;
    color: #0E1324;
  }

  .disabled {
    cursor: not-allowed;
    background: #F3F3F8;
    border: .5px solid #AEB3C9;
    color: #AEB3C9;
  }
`