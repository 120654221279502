import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist'
GlobalWorkerOptions.workerSrc =
  window.location.origin + '/workers/pdf.worker.min.mjs'

class PDFService {
  async convertPagesToImages(pageData) {
    const imagesList = []
    const canvas = document.createElement('canvas')
    canvas.setAttribute('className', 'canv')

    try {
      const pdf = await getDocument({ data: pageData }).promise

      for (let i = 1; i <= pdf.numPages; i++) {
        try {
          const page = await pdf.getPage(i)
          const viewport = page.getViewport({ scale: 1.5 })
          canvas.height = viewport.height
          canvas.width = viewport.width

          const renderContext = {
            canvasContext: canvas.getContext('2d'),
            viewport: viewport,
          }

          await page.render(renderContext).promise

          const img = canvas.toDataURL('image/png')
          imagesList.push(img)
        } catch (pageError) {
          throw new Error(`Failed to render page ${i}`)
        }
      }

      return imagesList
    } catch (error) {
      throw new Error('Failed to convert PDF pages to images')
    }
  }

  async convertPDFToImages(url) {
    try {
      const response = await fetch(url)
      const blob = await response.blob()

      const reader = new FileReader()
      const dataURL = await new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result)
        reader.onerror = () => reject(new Error('Error reading blob'))
        reader.readAsDataURL(blob)
      })

      const data = atob(dataURL.replace(/.*base64,/, ''))
      const images = await this.convertPagesToImages(data)

      return images
    } catch (error) {
      throw new Error('Failed to convert PDF to images')
    }
  }
}

export default PDFService
