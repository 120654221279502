import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  uploadedCV: {},
}

const cvSlice = createSlice({
  name: 'cv',
  initialState,
  reducers: {
    uploadCV: (state, action) => {
      state.uploadedCV = action.payload
    },
  },
})

export const { uploadCV } = cvSlice.actions

export default cvSlice.reducer
