import React, { useContext, useState, useEffect, useMemo } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { CiSearch } from 'react-icons/ci'
import { FiFilter } from 'react-icons/fi'
import { IoMdNotificationsOutline } from 'react-icons/io'

import Logo from '../../assets/icons/applicantLogo.svg'
import user from '../../assets/images/user.svg'

import SalaryFilter from './components/SalaryFilter'
import JobTypeFilter from './components/JobTypeFIlter'
import ShiftScheduleFilter from './components/ShiftScheduleFilter'
import CountryFilter from './components/CountryFilter'
import DatePostedFilter from './components/DatePostedFilter'
import MobileFormFilter from './components/MobileFilterForm'
import FilterTag from './components/FilterTag'
import HeaderDropdown from '../../components/header/HeaderDropdown'
import NotificationDropdown from '../../components/notification/NotificationDropdown'

import SocketIOClient from '../../lib/SocketIOClient'

import { ModalContext } from '../../context/ModalContext'

import {
  selectFilter,
  addFilter,
  deleteFilter,
  resetFilters,
} from '../../redux/feature/jobSlice'

import { nFormatter } from '../../utils/nformatter'
import { getFromLocalStorage } from '../../utils/util'

const SearchHeader = ({ isNotFilter }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const socket = useMemo(() => new SocketIOClient(), [])

  const currentPage = window.location.pathname
  const profileImgLink = getFromLocalStorage('profileImgLink')
  const userId = getFromLocalStorage('user_id')

  const { filters, selectedFilter, pagination } = useSelector(
    (state) => state.jobs
  )

  const [searchTerm, setSearchTerm] = useState('')
  const [activeFilters, setActiveFilters] = useState(0)
  const [notViewedNotifications, setNotViewedNotifications] = useState(0)
  const [imgLink, setImgLink] = useState('')

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const {
    handleHeaderToggle,
    headerToggle,
    handleNotificationToggle,
    notificationToggle,
    mobileJobFilterForm,
    setMobileJobFilterForm,
  } = useContext(ModalContext)

  useEffect(() => {
    setImgLink(profileImgLink)
  }, [profileImgLink])

  useEffect(() => {
    if (userId) {
      handleAddJobFilter('user_id', userId)
    } else {
      handleDeleteJobFilter('user_id')
    }
  }, [userId])

  useEffect(() => {
    if (userId) {
      socket.emit('join_room', {
        prefix: 'applicant',
        userId: userId,
      })

      socket.on('joined_room', (data) => {
        socket.emit('get_notifications', {
          userId: data.userId,
          source: 'applicant',
        })
      })

      socket.on('receive_applicant_notifications', (data) => {
        setNotViewedNotifications(data.notViewedNotifications)
      })
    }

    return () => {
      socket.off('joined_room')
      socket.off('receive_applicant_notifications')
    }
  }, [])

  useEffect(() => {
    let totalParams = Object.keys(filters).length
    if (totalParams) {
      const excludedParams = ['page', 'findJobByText']
      for (const param of excludedParams) {
        if (param in filters) {
          totalParams--
        }
      }
    }
    setActiveFilters(totalParams)
  }, [filters])

  const handleNotificationUrlClick = (id) => {
    socket.emit('click_notification_url', {
      notificationId: id,
      userId: userId,
      source: 'applicant',
    })
    handleNotificationToggle(false)
  }

  function handleOnChange(e) {
    setSearchTerm(e.target.value)
  }

  function handleSelectFilter(filter) {
    dispatch(selectFilter(filter))
  }

  function handleAddJobFilter(key, value) {
    dispatch(addFilter({ [key]: value }))
    dispatch(selectFilter(''))
  }

  function handleDeleteJobFilter(key) {
    dispatch(deleteFilter(key))
  }

  function handleResetJobFilter(key) {
    dispatch(deleteFilter(key))
    dispatch(selectFilter(''))
  }

  function handleClearFilters() {
    dispatch(resetFilters())
    history.push(`/home`)
  }

  function handleSubmit(e) {
    e.preventDefault()
    handleAddJobFilter('findJobByText', searchTerm)
  }

  function handleMobileFormFilter() {
    setMobileJobFilterForm(!mobileJobFilterForm)
  }

  function setCountryFilterText(filteredCountries) {
    const countryFieldText =
      filteredCountries === undefined
        ? ''
        : typeof filteredCountries === 'string' ||
          filteredCountries.length === 1
        ? filteredCountries
        : filteredCountries.length + ' countries selected'
    return countryFieldText
  }

  return (
    <>
      {mobileJobFilterForm && <MobileFormFilter />}
      {currentPage !== '/remote-fitness-level/success' ? (
        <StyledHeader>
          <div className="header_wrapper">
            <img className="header_logo" src={Logo} alt="logo" />

            <div className="search_bar web">
              <form onSubmit={handleSubmit} className="search_wrapper">
                <input
                  onChange={handleOnChange}
                  className="input"
                  type="text"
                  placeholder="Search for a job title"
                />
                <button type="submit">
                  <CiSearch className="icon" />
                </button>
              </form>

              {!isNotFilter && (
                <div className="filter_wrapper">
                  <div className="filter_item">
                    <FilterTag
                      label="Payment"
                      text={
                        filters?.salary_basis &&
                        `₱ ${nFormatter(
                          filters?.salary_basis[0]
                        )} to ₱ ${nFormatter(filters?.salary_basis[1])}`
                      }
                      onClick={() => handleSelectFilter('salary')}
                      onDelete={() => handleDeleteJobFilter('salary_basis')}
                    />
                    {selectedFilter === 'salary' && (
                      <SalaryFilter
                        addJobFilter={handleAddJobFilter}
                        resetJobFilter={handleResetJobFilter}
                      />
                    )}
                  </div>
                  <div className="filter_item">
                    <FilterTag
                      label="Career Type"
                      text={filters?.job_type}
                      onClick={() => handleSelectFilter('jobType')}
                      onDelete={() => handleDeleteJobFilter('job_type')}
                    />
                    {selectedFilter === 'jobType' && (
                      <JobTypeFilter addJobFilter={handleAddJobFilter} />
                    )}
                  </div>
                  <div className="filter_item">
                    <FilterTag
                      label="Shift Schedule"
                      text={filters?.time_shift}
                      onClick={() => handleSelectFilter('shiftSchedule')}
                      onDelete={() => handleDeleteJobFilter('time_shift')}
                    />
                    {selectedFilter === 'shiftSchedule' && (
                      <ShiftScheduleFilter addJobFilter={handleAddJobFilter} />
                    )}
                  </div>
                  <div className="filter_item">
                    <FilterTag
                      label="Date Posted"
                      text={filters?.date_posted}
                      onClick={() => handleSelectFilter('datePosted')}
                      onDelete={() => handleDeleteJobFilter('date_posted')}
                    />
                    {selectedFilter === 'datePosted' && (
                      <DatePostedFilter addJobFilter={handleAddJobFilter} />
                    )}
                  </div>
                  <div className="filter_item">
                    <FilterTag
                      label="Country"
                      text={setCountryFilterText(filters?.country)}
                      onClick={() => handleSelectFilter('country')}
                      onDelete={() => handleDeleteJobFilter('country')}
                    />
                    {selectedFilter === 'country' && (
                      <CountryFilter addJobFilter={handleAddJobFilter} />
                    )}
                  </div>

                  <div className="filter_item clear_filter">
                    {
                      <FilterTag
                        label="Clear Filters"
                        onClick={() => handleClearFilters()}
                        clearFilter
                      />
                    }
                  </div>
                </div>
              )}
            </div>

            {localStorage.getItem('token') ? (
              <div className="header_menu">
                <IoMdNotificationsOutline
                  className="icon"
                  onClick={handleNotificationToggle}
                />
                {notViewedNotifications > 0 && (
                  <span className="notificationCount">
                    {notViewedNotifications}
                  </span>
                )}
                <img
                  onClick={handleHeaderToggle}
                  className="header_profile"
                  src={!imgLink || imgLink === 'null' ? user : imgLink}
                  alt="user"
                />
                {headerToggle && <HeaderDropdown top="40%" />}
                {notificationToggle && (
                  <NotificationDropdown
                    onUrlClick={handleNotificationUrlClick}
                    top="40%"
                  />
                )}
              </div>
            ) : (
              <>
                {isMobile ? (
                  <div className="header_menu">
                    <img
                      onClick={handleHeaderToggle}
                      className="header_profile"
                      src={imgLink || user}
                      alt="user"
                    />
                    {headerToggle && <HeaderDropdown top="40%" />}
                  </div>
                ) : (
                  <div className="link_menu">
                    <Link to="/login">Login</Link>
                    <Link className="register" to="/signup">
                      Create Account
                    </Link>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="search_bar mobile">
            <form onSubmit={handleSubmit} className="search_wrapper">
              <input
                onChange={handleOnChange}
                className="input"
                type="text"
                placeholder="Search for a job title"
              />
              <button>
                <CiSearch className="icon" />
              </button>
            </form>

            <span className="mobile-filter" onClick={handleMobileFormFilter}>
              <div className="mobile-filter-row">
                <span className="pagination">
                  {pagination?.startingIndex}-{pagination?.endingIndex} of{' '}
                  {pagination?.rowCount} Results
                </span>
                <div className="mobile-filter-notifications">
                  <FiFilter className="mobile-filter-icon" />
                  <h4>Filter</h4>
                  <span className="mobile-active-filters">{activeFilters}</span>
                </div>
              </div>
              {filters?.findJobByText && (
                <div className="mobile-filter-row">
                  <span className="pagination">{`Search results for '${filters?.findJobByText}'`}</span>
                </div>
              )}
            </span>
          </div>
        </StyledHeader>
      ) : null}
    </>
  )
}

export default SearchHeader

const StyledHeader = styled.section`
  width: 100vw;
  padding: 1.9rem;
  border-bottom: 1px solid #dfdfe9;
  position: fixed;
  background: white;
  z-index: 100;

  .header_wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .header_logo {
      width: 183.78px;
      height: 2.2rem;

      img {
        height: 100%;
        width: auto;
      }
    }

    .header_menu {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      position: relative;
      order: 3;

      @media (max-width: 768px) {
        order: 2;
      }

      .header_profile {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        cursor: pointer;
      }

      .icon {
        width: 2rem;
        height: 2rem;
        cursor: pointer;
      }

      .notificationCount {
        position: absolute;
        bottom: 0.8rem;
        left: 0.9rem;
        width: 24px;
        padding: 1.5px;
        height: 24px;
        border-radius: 50%;
        background: #ff5880;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.6rem;
      }
    }

    .link_menu {
      display: flex;
      align-items: center;

      a {
        text-decoration: none;
        color: #31374f;
        font-size: 0.85rem;
        font-weight: 400;
        margin-left: 2.1rem;

        &:first-child {
          margin-left: 0;
        }

        &.last {
          margin-left: 0;
          padding-left: 2rem;
          border-left: 1px solid #d0d5e8;
          color: #fd2055;
        }

        &.register {
          margin-right: 2rem;
          color: #fd2055;
        }
      }

      @media (max-width: 768px) {
        position: fixed;
        top: 0;
        right: 0;
        width: 70vw;
        height: 100vh;
        background-color: #fff;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1;
        display: none;

        &.active {
          display: flex;
        }

        a {
          margin-left: 0;
          text-align: center;
          margin-bottom: 2rem;
          font-size: 1rem;

          &.last {
            margin-left: 0;
            padding-left: 0;
            color: #fd2055;
            border: none;
            padding-top: 2rem;
            border-top: 1px solid #d0d5e8;
          }

          &.register {
            margin-right: 0;
            color: #fd2055;
          }
        }
      }
    }
  }

  .search_bar {
    width: 100%;
    max-width: 693px;

    &.mobile {
      display: none;
    }

    @media (max-width: 768px) {
      &.web {
        display: none;
      }

      &.mobile {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        gap: 1rem;

        .mobile-filter {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .mobile-filter-row {
            display: flex;
            justify-content: space-between;
            gap: 0.4rem;

            .mobile-filter-notifications {
              display: flex;
              gap: 0.4rem;
              color: #1877f2;

              .mobile-filter-icon {
                font-size: 20px;
              }

              .mobile-active-filters {
                border-radius: 50%;
                padding: 3px 8px;
                width: 22px;
                height: 22px;
                font-size: 12px;
                color: #ffffff;
                background-color: #1877f2;
              }
            }
          }
        }
      }
    }

    .search_wrapper {
      width: 100%;
      height: 2.5rem;
      border: 1px solid #aeb3c9;
      border-radius: 100px;
      position: relative;
      overflow: hidden;
      margin-bottom: 0.75rem;
    }

    .filter_wrapper {
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      gap: 0.65rem;
      padding-top: 1.3rem;
      position: relative;

      .filter_item {
        display: flex;
        align-items: center;
        cursor: pointer;

        p {
          font-size: 1rem;
          font-weight: 300;
          color: #31374f;
        }
      }

      @media (max-width: 768px) {
        margin: 0 auto;
        display: flex;
        gap: 0.25rem;
        width: 100%;
        flex-wrap: wrap;

        .filter_item {
          display: flex;

          p {
            font-size: 13.1px;
            font-weight: 300;
            color: #31374f;
          }
        }
      }

      .clear_filter {
        margin-left: 0.5rem;
        padding-left: 1rem;
        border-left: 1px solid #dfdfe9;
      }
    }

    .input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      padding: 0 1.5rem;
      font-size: 1rem;
      font-weight: 300;
      color: #31374f;
    }

    button {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 3.5rem;
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      .icon {
        font-size: 1.5rem;
        color: #31374f;
      }
    }
  }
`
