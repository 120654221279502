import React from 'react'

import { IoMdNotificationsOutline } from 'react-icons/io'

import styled from 'styled-components'

import P from '../typography/P'

const EmptyNotification = () => {
  return (
    <StyledEmptyNotificationDropdown>
      <IoMdNotificationsOutline className="empty-notification-icon" />
      <h5>No Notifications Yet</h5>
      <P>You have no notifications right now.</P>
      <P>Come back later</P>
    </StyledEmptyNotificationDropdown>
  )
}

export default EmptyNotification

const StyledEmptyNotificationDropdown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .empty-notification-icon {
    font-size: 8rem;
    color: #ff5880;
  }
`
