import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import styled from 'styled-components'

import JobWrapper from './components/JobWrapper'
import SearchHeader from './Header'

import ReactGA4 from '../../lib/ReactGA4'

import apiClient from '../../helpers/apiClient'

import { getFromLocalStorage } from '../../utils/util'

const Home = () => {
  const history = useHistory()

  const userId = getFromLocalStorage('user_id')

  useEffect(() => {
    const reactGA4 = new ReactGA4()
    reactGA4.sendPageView('Home')
  }, [])

  useEffect(() => {
    if (userId && userId !== 'null') {
      apiClient
        .get(`/applicant`, {
          params: {
            userId: userId,
          },
        })
        .then((res) => {
          if (!res.data[0]?.name) {
            history.push('/onboarding')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [])

  return (
    <StyledHome>
      <SearchHeader />
      <JobWrapper />
    </StyledHome>
  )
}

export default Home

const StyledHome = styled.section`
  width: 100vw;
  height: 100vh;
  background-color: #fff;
`
