import React from 'react'

import styled from 'styled-components'

const InputWrapper = ({ label, children, mt, count, countNum, maxCount }) => {
  return (
    <StyledWrapper mt={mt}>
      <div className="heading-wrapper">
        <label>{label}</label>
        {count && (
          <label className="small">
            {countNum} / {maxCount}
          </label>
        )}
      </div>
      {children}
    </StyledWrapper>
  )
}

export default InputWrapper

const StyledWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  margin-top: ${(props) => (props.mt ? props.mt : '0')};

  .heading-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  label {
    display: block;
    font-size: 0.875rem;
    font-weight: 300;
    color: #0e1324;
    margin-bottom: 0.5rem;

    &.small {
      font-size: 0.75rem;
    }
  }
`
