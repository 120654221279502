import { useEffect, useState } from 'react'
import apiClient from '../helpers/apiClient'

function useFeatureFlag(name) {
  const [activeFlag, setActiveFlag] = useState(false)

  const fetchFlag = () => {
    apiClient
      .get('/feature-flag', {
        params: { name: name },
      })
      .then((res) => {
        if (res?.data?.result?.is_active === 1) {
          setActiveFlag(true)
        } else {
          setActiveFlag(false)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    fetchFlag()
  }, [])

  return { activeFlag }
}

export default useFeatureFlag
