import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeJob: null,
  filters: {},
  pagination: {
    page: 1,
    pageSize: 15,
    rowCount: 32,
    pageCount: 3,
    startingIndex: 0,
    endingIndex: 0,
  },
  selectedFilter: '',
  selectedJob: null,
  savedJobsModal: '',
  suggestedJobsModal: '',
  hasSkillChange: false,
}

const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    updateActiveJob: (state, action) => {
      state.activeJob = action.payload
    },
    updatePagination: (state, action) => {
      state.pagination.page = action.payload.page
      state.pagination.pageSize = action.payload.pageSize
      state.pagination.rowCount = action.payload.rowCount
      state.pagination.pageCount = action.payload.pageCount
      state.pagination.startingIndex =
        (action.payload.page - 1) * action.payload.pageSize + 1
      state.pagination.endingIndex = Math.min(
        action.payload.page * action.payload.pageSize,
        action.payload.rowCount
      )
    },
    selectFilter: (state, action) => {
      state.selectedFilter = action.payload
    },
    addFilter: (state, action) => {
      if (!('page' in action.payload)) {
        state.filters = {
          ...state.filters,
          ...action.payload,
          page: 1,
        }
        state.pagination = {
          ...state.pagination,
          page: 1,
        }
      } else {
        state.filters = {
          ...state.filters,
          ...action.payload,
        }
      }
    },
    deleteFilter: (state, action) => {
      const filterToDelete = action.payload
      const newState = { ...state.filters }
      delete newState[filterToDelete]
      state.filters = {
        ...newState,
      }
    },
    resetFilters: (state) => {
      state.filters = {
        page: 1,
      }
    },
    selectJob: (state, action) => {
      state.selectedJob = action.payload
    },
    setSavedJobsModal: (state, action) => {
      state.savedJobsModal = action.payload
    },
    setSuggestedJobsModal: (state, action) => {
      state.suggestedJobsModal = action.payload
    },
    setHasSkillChange: (state, action) => {
      state.hasSkillChange = action.payload
    },
  },
})

export const {
  updateActiveJob,
  updatePagination,
  selectFilter,
  addFilter,
  deleteFilter,
  resetFilters,
  selectJob,
  setSavedJobsModal,
  setSuggestedJobsModal,
  setHasSkillChange,
} = jobSlice.actions

export default jobSlice.reducer
