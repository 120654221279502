import React from 'react'
import styled from 'styled-components'

const JobTypeFilter = ({ addJobFilter }) => {
    const param = 'job_type'

    return (
        <StyledJobTypeFilter>
            <div onClick={() => addJobFilter(param, '')} className='filter-item'><span className='filter-text'>All Career Types</span></div>
            <div onClick={() => addJobFilter(param, 'Full-time')} className='filter-item'><span >Full time</span></div>
            <div onClick={() => addJobFilter(param, 'Part-time')} className='filter-item'><span >Part time</span></div>
        </StyledJobTypeFilter>
    )
}

export default JobTypeFilter

const StyledJobTypeFilter = styled.div`
  width: 15.5rem;
  display: flex;
  flex-direction: column;
  padding: 5px;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #eeeef6;
  box-shadow: 3px 3px 7px rgba(178, 178, 203, 0.2);
  position: absolute;
  top: 110%;
  z-index: 8;

  .filter-item {
    padding: 1rem 0.5rem;
    
    &:hover {
        background: #EEEEF6;
    }

    .filter-text {
        font-size: 14px;
    }
  }
`
