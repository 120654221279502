import React, { useContext } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { StyledProgress, StyledSidebar } from '../onboarding/Sidebar'

import { ModalContext } from '../../context/ModalContext'

export const DropProgress = ({
  last,
  text,
  active,
  postActive,
  remotePage,
  reachRemotePage,
}) => {
  return (
    <StyledProgress
      as="button"
      disabled={remotePage > reachRemotePage}
      last={last}
    >
      <div
        className={
          active
            ? 'circle-dot active'
            : postActive
            ? 'circle-dot postActive'
            : 'circle-dot'
        }
      />
      <p>{text}</p>
    </StyledProgress>
  )
}

const RemoteSidebar = () => {
  const { setRemotePage, remotePage, reachRemotePage } =
    useContext(ModalContext)

  return (
    <StyledSidebar>
      <div className="in">
        <CircularProgressbar
          value={remotePage}
          maxValue={3}
          text={`${remotePage} of 3`}
          strokeWidth={5}
          styles={buildStyles({
            strokeLinecap: 'butt',
            textSize: '.8rem',
            pathTransitionDuration: 0.5,
            pathColor: `#FF5880`,
            textColor: '#31374F',
            trailColor: '#E9EBF2',
          })}
        />
      </div>
      <div className="sidebar-content">
        <DropProgress
          active={remotePage === 1}
          value={1}
          postActive={remotePage > 1 || reachRemotePage > 1}
          last={true}
          text="Internet Speed"
          setter={setRemotePage}
          remotePage={remotePage}
          reachRemotePage={reachRemotePage}
          setRemotePage={setRemotePage}
        />
        <DropProgress
          active={remotePage === 2}
          value={2}
          postActive={remotePage > 2 || reachRemotePage > 2}
          text="Computer Specs"
          setter={setRemotePage}
          remotePage={remotePage}
          reachRemotePage={reachRemotePage}
          setRemotePage={setRemotePage}
        />
        <DropProgress
          active={remotePage === 3}
          value={3}
          postActive={remotePage === 3 || reachRemotePage === 3}
          text="Peripherals"
          setter={setRemotePage}
          remotePage={remotePage}
          reachRemotePage={reachRemotePage}
          setRemotePage={setRemotePage}
        />
      </div>
    </StyledSidebar>
  )
}

export default RemoteSidebar
