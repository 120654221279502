import React from 'react'

import styled from 'styled-components'

const GridWrapper = ({ children }) => {
  return <GridStyle>{children}</GridStyle>
}

export default GridWrapper

const GridStyle = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5rem;

  @media (max-width: 765px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
