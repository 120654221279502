import React from 'react'

import Modal from '../Modal'

import Login from '../../pages/Login'

const ValidateLogin = ({ onSuccessfulValidation }) => {
  return (
    <Modal showPrimaryBtn={false} noCancel>
      <Login type="modal" onCustomLogin={onSuccessfulValidation} />
    </Modal>
  )
}

export default ValidateLogin
