import React, { useState, useRef } from 'react'

import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast'

import FormWrapper from '../../components/controls/FormWrapper'
import Input, { TextArea } from '../../components/controls/Input'
import { PhoneNumberInput } from '../../components/Input'
import ScrollToTop from '../../components/ScrollRestoration'
import InputWrapper from '../../components/MiniComponents/InputWrapper'
import H2 from '../../components/typography/H2'
import FlexWrapper from '../../components/MiniComponents/FlexWrapper'

import { baseUrl } from '../../constants/constant'

const Contact = ({ width }) => {
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    phone_number: '',
    sender: '',
    content: '',
  })

  const captchaRef = useRef(null)

  const [verified, setVerified] = useState(false)

  function resetState() {
    setData({
      firstName: '',
      lastName: '',
      phone_number: '',
      sender: '',
      content: '',
    })
    setVerified(false)
    captchaRef.current.reset()
  }

  const [loading, setLoading] = useState(false)

  const handleTextareaChange = (e) => {
    if (e.target.value.length > 500) {
      return
    }

    setData((prevState) => ({ ...prevState, content: e.target.value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setLoading(true)

    axios
      .post(`${baseUrl}/contact-support`, data)
      .then((res) => {
        setLoading(false)
        toast.success(res.data.message)
        resetState()
      })
      .catch((err) => {
        setLoading(false)
        toast.error(err.response.data)
      })
  }

  const isDisabled = data.sender !== '' && data.content !== '' && verified

  return (
    <>
      <ScrollToTop />

      <H2 size="20px" weight="400" mb="1.5rem" mt="3rem">
        Contact our team
      </H2>

      <FormWrapper
        maxWidth={width}
        loading={loading}
        onSubmit={handleSubmit}
        onVerify={() => setVerified(true)}
        disabled={!isDisabled}
        centered={false}
        buttonPlacement="start"
        buttonText="Send Message"
        captchaRef={captchaRef}
      >
        <FlexWrapper gap="1rem" mobileDirection="column" mobileGap="0">
          <InputWrapper label="First name">
            <Input
              name="firstname"
              type="text"
              onChange={(e) =>
                setData((prevState) => ({
                  ...prevState,
                  firstName: e.target.value,
                }))
              }
              value={data.firstName}
            />
          </InputWrapper>
          <InputWrapper label="Last name">
            <Input
              name="lastname"
              type="text"
              onChange={(e) =>
                setData((prevState) => ({
                  ...prevState,
                  lastName: e.target.value,
                }))
              }
              value={data.lastName}
            />
          </InputWrapper>
        </FlexWrapper>
        <InputWrapper label="Your Email">
          <Input
            type="email"
            name="email"
            onChange={(e) =>
              setData((prevState) => ({ ...prevState, sender: e.target.value }))
            }
            value={data.sender}
          />
        </InputWrapper>
        <InputWrapper label="Contact Number">
          <PhoneNumberInput
            country="ph"
            value={data.phone_number}
            onChange={(value) =>
              setData((prevState) => ({ ...prevState, phone_number: value }))
            }
          />
        </InputWrapper>
        <InputWrapper
          label="Message"
          count
          countNum={data?.content?.length || 0}
          maxCount={'500'}
        >
          <TextArea
            onChange={handleTextareaChange}
            name="message"
            value={data.content}
            maxLength={500}
          />
        </InputWrapper>
      </FormWrapper>
      <Toaster position="top-right" reverseOrder={false} />
    </>
  )
}

export default Contact
