import { useState } from 'react'
import moment from 'moment'

import PDFService from '../lib/PDFService'
import OCRService from '../lib/OCRService'

import apiClient from '../helpers/apiClient'

const useSyncCV = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [images, setImages] = useState([])

  const handleSyncCV = async (cvFile) => {
    try {
      setLoading(true)
      // 1. Convert cv file into URL
      const pdfUrl = URL.createObjectURL(cvFile)

      // 2. Convert uploaded CV into image(s) using a web worker
      const pdfService = new PDFService()
      const pageImages = await pdfService.convertPDFToImages(pdfUrl)
      setImages(pageImages)

      // 3. Get all text from images
      const ocrService = new OCRService()
      const mergedText = await ocrService.convertImagesToText(pageImages)

      // 4. Run AI prompt and get parsed data
      const response = await apiClient.post('/claude-ai/cv', {
        ocrText: mergedText,
        token: localStorage.getItem('token'),
      })

      // 5. Update localStorage from AI-generated data
      const parsedApplicantSkills = response.data.data.skills
      const parsedApplicantProfile = response.data.data.profile

      let applicantCVData = localStorage.getItem('applicant-cv-data')
      const currentTime = moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      applicantCVData = JSON.parse(applicantCVData)

      applicantCVData.character_reference = JSON.stringify(
        parsedApplicantProfile?.character_reference
      )
      applicantCVData.meta = JSON.stringify({
        ...parsedApplicantProfile?.meta,
        fullSkills: parsedApplicantSkills,
      })

      applicantCVData.first_name = parsedApplicantProfile?.first_name
      applicantCVData.middle_name = parsedApplicantProfile?.middle_name
      applicantCVData.last_name = parsedApplicantProfile?.last_name
      applicantCVData.name = parsedApplicantProfile?.name
      applicantCVData.professional_headline = JSON.stringify(
        parsedApplicantProfile?.professional_headline
      )
      applicantCVData.professional_summary =
        parsedApplicantProfile?.professional_summary
      applicantCVData.years_of_experience =
        parsedApplicantProfile?.years_of_experience
      applicantCVData.updated_at = currentTime

      let applicantData = localStorage.getItem('applicant-data')
      applicantData = JSON.parse(applicantData)

      applicantData.city = parsedApplicantProfile.city
      applicantData.country = parsedApplicantProfile.country
      applicantData.phone_number = parsedApplicantProfile.phone_number

      localStorage.setItem('applicant-cv-data', JSON.stringify(applicantCVData))
      localStorage.setItem('applicant-data', JSON.stringify(applicantData))

      setSuccess(true)
      return {
        profile: parsedApplicantProfile,
        skills: parsedApplicantSkills,
      }
    } catch (processError) {
      setError(processError)
    } finally {
      setLoading(false)
    }
  }

  return { loading, error, handleSyncCV, success, images }
}

export default useSyncCV
