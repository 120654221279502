import { React, createContext, useState } from 'react'

export const ModalContext = createContext(null)
export const ModalProvider = ({ children }) => {
  const [openModal, setModal] = useState(false)
  const [openTopModal, setOpenTopModal] = useState(false)
  const [openNormalModal, setNormalModal] = useState(false)
  const [headerToggle, setHeaderToggle] = useState(false)
  const [notificationToggle, setNotificationToggle] = useState(false)
  const [remotePage, setRemotePage] = useState(1)
  const [reachRemotePage, setReachRemotePage] = useState(1)
  const [pingTime, setPingTime] = useState(0)
  const [downloadSpeed, setDownloadSpeed] = useState(0.0)
  const [workEthicsPage, setWorkEthicsPage] = useState(1)
  const [reachWorkEthicsPage, setReachWorkEthicsPage] = useState(1)
  const [uploadSpeed, setUploadSpeed] = useState(0.0)
  const [majorSpeed, setMajorSpeed] = useState(0.0)
  const [scale, setScale] = useState(0)
  const [testCount, setTestCount] = useState(3)
  const [completeSpin, setCompleteSpin] = useState(false)
  const [closeModal, setCloseModal] = useState(false)
  const [isModalOpen, setOpenModal] = useState(true)
  const [updateCv, setUpdateCv] = useState(false)
  const [compSpec, setCompSpec] = useState({
    os: '',
    osVersion: '',
    processor: '',
    ram: '',
  })
  const [pheripheral, setPheripheral] = useState({
    webCamera: '',
    headSet: '',
    microphone: '',
  })

  const [remoteWork, setRemoteWork] = useState('')

  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('user'))
  )

  const [mobileJobFilterForm, setMobileJobFilterForm] = useState('')

  const handleModalToggle = () => {
    setModal(!openModal)
  }

  const handleTopModalToggle = () => {
    setOpenTopModal(!openTopModal)
  }

  const handleModalToTrue = () => {
    setModal(true)
  }

  const handleHeaderToggle = () => {
    setHeaderToggle(!headerToggle)
    setNotificationToggle(false)
  }

  const handleNotificationToggle = () => {
    setHeaderToggle(false)
    setNotificationToggle(!notificationToggle)
  }

  return (
    <ModalContext.Provider
      value={{
        handleModalToggle,
        openModal,
        handleTopModalToggle,
        openTopModal,
        handleHeaderToggle,
        headerToggle,
        setHeaderToggle,
        handleNotificationToggle,
        notificationToggle,
        userData,
        setUserData,
        remotePage,
        setRemotePage,
        workEthicsPage,
        setWorkEthicsPage,
        openNormalModal,
        setNormalModal,
        pingTime,
        setPingTime,
        downloadSpeed,
        setDownloadSpeed,
        completeSpin,
        setCompleteSpin,
        uploadSpeed,
        setUploadSpeed,
        majorSpeed,
        setMajorSpeed,
        testCount,
        setTestCount,
        compSpec,
        setCompSpec,
        pheripheral,
        setPheripheral,
        reachRemotePage,
        setReachRemotePage,
        scale,
        setScale,
        setModal,
        setOpenTopModal,
        closeModal,
        setCloseModal,
        remoteWork,
        setRemoteWork,
        reachWorkEthicsPage,
        setReachWorkEthicsPage,
        handleModalToTrue,
        isModalOpen,
        setOpenModal,
        updateCv,
        setUpdateCv,
        mobileJobFilterForm,
        setMobileJobFilterForm,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}
