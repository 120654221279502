import React from 'react'

import { P } from '../../../styles/Typograph.styles'
import InputWrapper from '../../../components/MiniComponents/InputWrapper'
import Input from '../../../components/controls/Input'
import SubFileUpload from '../../../components/MiniComponents/SubFileUpload'
import SpacingLine from '../../../components/SpacingLine'
import IconContainer from '../../../components/controls/Icons'
// import InternetTest from '../../../components/RemoteSection/InternetTest'

import { FiExternalLink } from 'react-icons/fi'

import { WorkEthicsContext } from '../../../context/WorkEthics'

const OoklaTest = () => {
  const { ooklaLink, setOoklaLink, ooklaScreenshot, setOoklaScreenshot } =
    React.useContext(WorkEthicsContext)

  return (
    <>
      <SpacingLine full />
      <P>
        You can also use Speedtest by Ookla
        <a target="_blank" href="https://www.speedtest.net/" rel="noreferrer">
          <IconContainer color="#1877F2" ml=".5rem">
            <FiExternalLink className="icon" />
          </IconContainer>
        </a>
      </P>
      {/* <InternetTest /> */}
      <InputWrapper mt="1.5rem" label="Speedtest Result Link">
        <Input
          type="text"
          value={ooklaLink}
          placeholder="Ookla Result Link"
          onChange={(e) => {
            setOoklaLink(e.target.value)
          }}
        />
      </InputWrapper>

      <SubFileUpload
        label="Speedtest Screenshot"
        noBorder
        maxW="100%"
        pt=".5rem"
        attachment={ooklaScreenshot}
        setAttachment={setOoklaScreenshot}
      />
    </>
  )
}

export default OoklaTest
