export const theme = {
  colors: {
    primary: '#FD2055',
    darkGrey: '#878787',
    grey: '#F6E5E9',
    grey100: '#5F6683',
    grey200: '#858AA0',
    grey300: '#AEB3C9',
    white: '#FFF',
    dark: '#0E1324',
    black: '#1A1A1A',
    warning: '#FFB92D',
    error: '#DC2929',
    success: '#5AC56D',
    link: '#3164FF',
  },
  fontWeights: {
    light: 300,
    regular: 400,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
  fontSize: {
    heading: '2rem',
    subHeading: '3.5rem',
    body: '1rem',
    span: '.75rem',
  },
  fontSizes: {
    heroLink: '.875rem',
    buttonText: '1.125rem',
    paragraph: '1.25rem',
    smallHeading: '3.5rem',
    heading: '4.5rem',
    largeHeading: '7rem',
  },
  lineHeights: {
    paragraph: 1.5,
    heading: 1,
  },
  spacing: {
    xs: '0.5rem',
    s: '1rem',
    m: '2rem',
    l: '2.5rem',
    xl: '3rem',
  },
}
