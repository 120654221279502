import React from 'react'

import styled from 'styled-components'

import { RiDeleteBinLine } from 'react-icons/ri'

import moment from 'moment'

import pencil from '../assets/icons/pencil.svg'

const ProfileDetails = ({
  educationLevel,
  school,
  schoolLocation,
  from,
  to,
  currentStatus,
  handleDelete,
  handleEdit,
  id,
}) => {
  return (
    <StyledProfileDetails>
      <p className="paragraph">
        <b>{educationLevel}</b>
      </p>

      <p className="paragraph">
        {school} -{' '}
        {schoolLocation.label ? schoolLocation.label : schoolLocation}
      </p>
      <p className="paragraph">
        {moment(from).format('MMMM YYYY')} -{' '}
        {currentStatus === 'currently enrolled' ||
        to === null ||
        to === '' ||
        to === undefined ||
        to === 'Invalid date'
          ? 'Present'
          : moment(to).format('MMMM YYYY')}
      </p>

      <div className="cta">
        <RiDeleteBinLine className="icon" onClick={() => handleDelete(id)} />
        <img
          src={pencil}
          alt="pencil icon"
          onClick={() => handleEdit(id)}
          className="pencil"
        />
      </div>
    </StyledProfileDetails>
  )
}

export default ProfileDetails

export const StyledProfileDetails = styled.div`
  width: 100%;
  background: #f8f9ff;
  padding: 1rem;
  border-radius: 3px;
  position: relative;
  margin-top: 1.5rem;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 2rem;
  }

  .cta {
    position: absolute;
    top: 1rem;
    right: 1rem;

    display: flex;
    align-items: center;

    .icon {
      font-size: 0.875rem;
      color: #aeb3c9;
      margin-right: 1rem;
      cursor: pointer;
    }

    .pencil {
      width: 0.875rem;
      height: auto;
      cursor: pointer;
    }
  }

  .paragraph {
    font-size: 0.75rem;
    font-weight: 300;
    color: #31374f;
    line-height: 1.8;
    letter-spacing: 0.03em;

    &.first {
      &::first-letter {
        margin-left: 1rem;
      }
    }

    b {
      font-weight: 500;
    }
  }
`
