/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import EmptyState, { SimpleEmptyState } from './EmptyState'
import JobDetails from './JobDetails'
import JobList from './JobList'
import Tutorial from './Tutorial'
import MobileTutorial from './MobileTutorial'

import loadingGif from '../../../assets/gifs/loading.gif'

import { updatePagination, setHasSkillChange, updateActiveJob } from '../../../redux/feature/jobSlice'
import { useGetJobsQuery } from '../../../redux/services/JobApi'

import axios from 'axios'

import { baseUrl } from '../../../constants/constant'

const Jobs = () => {
  const pagination = useSelector((state) => state.jobs.pagination)
  const jobFilters = useSelector((state) => state.jobs.filters)
  const hasSkillChange = useSelector((state) => state.jobs.hasSkillChange)

  const [tutorial, setTutorial] = useState(true)
  const [loading, setLoading] = useState(true)
  const [jobs, setJobs] = useState([])
  const handleCloseTutorial = () => setTutorial(false)

  const findJobBy = jobFilters?.findJobByText

  const { data } = useGetJobsQuery({ ...jobFilters })

  const activeJob =
    window.innerWidth < 768
      ? useSelector((state) => state?.jobs?.activeJob)
      : useSelector((state) => state?.jobs?.activeJob) || jobs?.data?.jobs[0]

  const dispatch = useDispatch()

  const fetchJobsData = async () => {
    setLoading(true);
    const filters = new URLSearchParams(jobFilters).toString()
    axios.get(`${baseUrl}/jobs?status=active&${filters}`)
      .then((resp) => {
        dispatch(updatePagination(resp?.data?.data?.pagination))
        setJobs(resp?.data)
        dispatch(updateActiveJob(resp?.data?.data?.jobs[0]))
        dispatch(setHasSkillChange(false))
        setLoading(false)
      }).catch((error) => {
        console.error('Error fetching job data:', error)
      })
  };

  useEffect(() => {
    setJobs(data)
    if (data) {
      setLoading(false)
      dispatch(updatePagination(data?.data?.pagination))
    }
  }, [data])

  useEffect(() => {
    if (hasSkillChange) {
      fetchJobsData()
    }
  }, [hasSkillChange])

  return (
    <>
      <StyledJob>
        {tutorial && (
          <MobileTutorial
            className="mobile-view"
            closeTutorial={handleCloseTutorial}
          />
        )}
        {tutorial && <Tutorial closeTutorial={handleCloseTutorial} />}
        <div className="job-header">
          <div className="job-card">
            <span className="search">
              {findJobBy && `Search results for '${findJobBy}'`}
            </span>
            {pagination?.rowCount > 0 && (
              <span className="pagination">
                {pagination?.startingIndex}-{pagination?.endingIndex} of{' '}
                {pagination?.rowCount} Results
              </span>
            )}
          </div>
        </div>

        {jobs?.data?.jobs.length > 0 && (
          <div className="jobs">
            <JobList jobs={jobs} />
            {activeJob?.id ? (
              <JobDetails job={activeJob} />
            ) : (
              <div className="empty_jobs">
                <SimpleEmptyState />
              </div>
            )}
          </div>
        )}

        {loading && (
          <div className="loading">
            <img
              alt="loading-gif"
              src={loadingGif}
              className="loading_gif"
            ></img>
          </div>
        )}

        {!jobs?.data?.jobs.length && Object.keys(jobFilters).length > 0 && (
          <EmptyState findJobBy={findJobBy} />
        )}
      </StyledJob>
    </>
  )
}

export default Jobs

const StyledJob = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 12rem;
  width: 100%;

  @media (max-width: 768px) {
    padding-top: 14.8rem;
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;

    .loading_gif {
      max-width: 100%;
      max-height: 100%;
      image-rendering: smooth;
    }
  }

  .job-header {
    width: 45%;
    display: flex;
    flex-direction: column;

    .job-card {
      @media (max-width: 768px) {
        display: none;
      }

      display: flex;
      justify-content: space-between;

      .pagination {
        font-size: 0.875rem;
        font-weight: 400;
        color: #31374f;
        align-self: flex-end;
      }
    }
  }

  .jobs {
    width: 100%;
    height: 90vh;
    display: grid;
    grid-template-columns: 45% 1fr;
    gap: 1.5rem;

    @media (max-width: 768px) {
      height: 100%;
      grid-template-columns: 1fr;
      .empty_jobs {
        display: none;
      }
    }
  }
`
