import React, { useContext } from 'react'
import FlexWrapper from '../../components/MiniComponents/FlexWrapper'
import ItemSelect from '../../components/MiniComponents/ItemSelect'
import { ModalContext } from '../../context/ModalContext'
import { WorkEthicsContext } from '../../context/WorkEthics'
import { Button, StyledScreen } from '../remoteSetup/RemoteScreenOne'

const WorkEthicsStepFour = () => {
  const { shift, setShift } = useContext(WorkEthicsContext)
  const { setWorkEthicsPage, setReachWorkEthicsPage } = useContext(ModalContext)

  return (
    <StyledScreen maxWidth="456px">
      <h2 className="subHeading">
        Considering my personal lifestyle and professional experience, I work
        best during...
      </h2>
      <p className="paragraph">
        We believe in perfect matches! There are people who work better as the
        sun goes up, and there are folks who like to dance under the moon.
      </p>

      <div className="mb-2" />
      <ItemSelect
        value="Mornings"
        onClick={() => setShift('Mornings')}
        active={shift === 'Mornings'}
      />
      <ItemSelect
        value="Afternoons"
        onClick={() => setShift('Afternoons')}
        active={shift === 'Afternoons'}
      />
      <ItemSelect
        value="Evenings"
        onClick={() => setShift('Evenings')}
        active={shift === 'Evenings'}
      />
      <ItemSelect
        value="Any time of the day"
        onClick={() => setShift('Any time of the day')}
        active={shift === 'Any time of the day'}
      />

      <FlexWrapper content="flex-end" mt="2rem">
        <Button
          onClick={() => {
            setWorkEthicsPage(3)
            setReachWorkEthicsPage(3)
          }}
        >
          Go Back
        </Button>
        <Button
          className="filled"
          disabled={shift === '' || !shift}
          onClick={() => {
            setWorkEthicsPage(5)
            setReachWorkEthicsPage(5)
          }}
        >
          Next Step
        </Button>
      </FlexWrapper>
    </StyledScreen>
  )
}

export default WorkEthicsStepFour
