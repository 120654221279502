import { React, createContext, useState } from 'react'

export const WorkEthicsContext = createContext(null)
export const WorkEthicsProvider = ({ children }) => {
  const [workArea, setWorkArea] = useState('')
  const [device, setDevice] = useState('')
  const [fixSchedule, setFixSchedule] = useState('')
  const [shift, setShift] = useState('')
  const [jobHours, setJobHours] = useState('')
  const [timeTracker, setTimeTracker] = useState('')
  const [attachment, setAttachment] = useState('')
  const [ooklaLink, setOoklaLink] = useState('')
  const [ooklaScreenshot, setOoklaScreenshot] = useState(null)

  return (
    <WorkEthicsContext.Provider
      value={{
        workArea,
        setWorkArea,
        device,
        setDevice,
        fixSchedule,
        setFixSchedule,
        shift,
        setShift,
        jobHours,
        setJobHours,
        timeTracker,
        setTimeTracker,
        attachment,
        setAttachment,
        ooklaLink,
        setOoklaLink,
        ooklaScreenshot,
        setOoklaScreenshot,
      }}
    >
      {children}
    </WorkEthicsContext.Provider>
  )
}
