import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'

import styled from 'styled-components'
import { BsCheck2Circle } from 'react-icons/bs'

import Modal from '../Modal'

import { ModalContext } from '../../context/ModalContext'

import { setSavedJobsModal } from '../../redux/feature/jobSlice'

const SuccessfulApplication = () => {
  const dispatch = useDispatch()

  const { setModal } = useContext(ModalContext)

  const handleSuccessfulApply = () => {
    setModal(false)
    dispatch(setSavedJobsModal(''))
  }

  return (
    <Modal btnTitle="Finish" btnFunc={handleSuccessfulApply} noCancel>
      <StyledSuccessfulApplication>
        <BsCheck2Circle className="success_icon" />
        <h1>Application Successful!</h1>
        <p>
          Thank you for applying! You will receive a response within 2 business
          days.
        </p>
      </StyledSuccessfulApplication>
    </Modal>
  )
}

const StyledSuccessfulApplication = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .success_icon {
    font-size: 5rem;
    color: #5cca95;
    margin: 0.5rem;
  }

  h1,
  p {
    text-align: center;
  }
`

export default SuccessfulApplication
