import React from 'react'
import styled from 'styled-components'

const ShiftScheduleFilter = ({ addJobFilter }) => {
  const param = 'time_shift'

  return (
    <StyledJobTypeFilter>
      <div onClick={() => addJobFilter(param, '')} className='filter-item'><span className='filter-text'>All Shifts</span></div>
      <div onClick={() => addJobFilter(param, 'Morning Shift - PH TIME')} className='filter-item'><span >Morning Shift - PH TIME</span></div>
      <div onClick={() => addJobFilter(param, 'Afternoon Shift - PH TIME')} className='filter-item'><span >Afternoon Shift - PH TIME</span></div>
      <div onClick={() => addJobFilter(param, 'Night Shift - PH TIME')} className='filter-item'><span >Night Shift - PH TIME</span></div>
    </StyledJobTypeFilter>
  )
}

export default ShiftScheduleFilter

const StyledJobTypeFilter = styled.div`
  width: 15.5rem;
  display: flex;
  flex-direction: column;
  padding: 5px;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #eeeef6;
  box-shadow: 3px 3px 7px rgba(178, 178, 203, 0.2);
  position: absolute;
  top: 110%;
  z-index: 8;

  .filter-item {
    padding: 1rem 0.5rem;
    
    &:hover {
        background: #EEEEF6;
    }

    .filter-text {
        font-size: 14px;
    }
  }
`
