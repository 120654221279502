import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import MultiRangeSlider from '../../../components/controls/MultiRangeSlider'

import { nFormatter } from '../../../utils/nformatter'

const SalaryFilter = ({ addJobFilter, resetJobFilter }) => {
  const param = 'salary_basis'
  const history = useHistory();

  const [range, setRange] = useState([1000, 200000])
  const params = new URLSearchParams(history.location.search);
  const salaryBasis = params.get('salary_basis');
  const salaryBasisArray = salaryBasis && salaryBasis.split(',');

  useEffect(() => {
    if (salaryBasis) {
      setRange(salaryBasisArray)
    }
  }, [])

  return (
    <StyledSalaryFilter>
      <h4 className="salary-filter-title">
        ₱ {nFormatter(range[0])} <span> to </span> ₱ {nFormatter(range[1])} /
        Month
      </h4>

      <div className="slider">
        <MultiRangeSlider
          defaultValue={[1000, 9000]}
          min={0}
          max={200000}
          value={range}
          onChange={(value) => setRange(value)}
        />
      </div>

      <div className="salary-filter-footer">
        <button onClick={() => resetJobFilter(param)} className='button reset'>Reset</button>
        <button onClick={() => addJobFilter(param, range)} className='button apply'>Apply</button>
      </div>
    </StyledSalaryFilter>
  )
}

export default SalaryFilter

const StyledSalaryFilter = styled.div`
  width: 100%;
  max-width: 374px;
  padding: 1.25rem 1.85rem;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #eeeef6;
  box-shadow: 3px 3px 7px rgba(178, 178, 203, 0.2);
  position: absolute;
  top: 110%;
  z-index: 8;

  .salary-filter-title {
    font-size: 1.5rem;
    font-weight: 300;
    color: #31374f;
    text-align: center;

    span {
      color: #858aa0;
    }
  }

  .slider {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .salary-filter-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: static;
    width: 100%;
    padding: 0.5rem 0;
    margin-top: 2.5rem;
    gap: 1rem;

    .button {
      position: static;
      font-size: 0.875rem;
      font-weight: 500;

      &.reset {
        color: #858AA0;
      }

      &.apply {
        color: #1877F2;
      }
    }
  }
`
