import React, { useState } from 'react'
import styled from 'styled-components'

import MyApplicationsTab from './MyApplicationsTab'
import MyCvTab from './MyCvTab'
import SavedApplicationTab from './SavedApplicationTab'
import SuggestedJobsTab from './SuggestedJobsTab'

const Tabs = () => {
  const types = [
    'My CV',
    'My Applications',
    'My Saved Listings',
    'My Suggested Vacancies',
  ]
  const [active, setActive] = useState(types[0])

  const Tab = styled.button`
    font-size: 14px;
    cursor: pointer;
    opacity: 0.6;
    background: white;
    border: 0;
    outline: 0;
    opacity: 1;
    padding: 5px 20px;
    color: #31374f;
    ${({ active }) =>
      active &&
      `
    border-bottom: 2px solid pink;
    opacity: 1;
  `}
  `
  const ButtonGroup = styled.div`
    display: flex;
  `

  return (
    <>
      <ButtonGroup>
        {types.map((type) => (
          <Tab
            key={type}
            active={active === type}
            onClick={() => setActive(type)}
          >
            {type}
          </Tab>
        ))}
      </ButtonGroup>
      {active === 'My CV' ? <MyCvTab /> : ''}
      {active === 'My Applications' ? <MyApplicationsTab /> : ''}
      {active === 'My Saved Listings' ? <SavedApplicationTab /> : ''}
      {active === 'My Suggested Vacancies' ? <SuggestedJobsTab /> : ''}
    </>
  )
}

export default Tabs
