import React from 'react'

import styled from 'styled-components'

const ExperiencePopup = () => {
  return (
    <StyledExperiencePopup>
      <p>If you’re stuck, try answering these guide questions</p>

      <ul>
        <li>What were your responsibilities at work?</li>
        <li>
          Who did you work with and how did you cooperate with them in
          completing tasks?
        </li>
        <li>
          What are some of your notable accomplishments and how did you
          accomplish them?
        </li>
      </ul>
    </StyledExperiencePopup>
  )
}

export default ExperiencePopup

const StyledExperiencePopup = styled.div`
  position: absolute;
  top: 8px;
  left: 102%;
  width: 100%;
  max-width: 302px;
  padding: 0.75rem 1rem;
  background: #fff;
  box-shadow: 3px 3px 7px rgba(178, 178, 203, 0.2);
  border-radius: 3px;

  p,
  li {
    font-size: 0.875rem;
    font-weight: 300;
    letter-spacing: 0.03em;
    color: #31374f;
    margin-top: 1rem;
  }

  p {
    margin-top: 0;
  }

  ul {
    margin-left: 0.85rem;
  }
`
