export function capitalize(str) {
  if (str) {
    return str[0].toUpperCase() + str.slice(1)
  }
}

export function numberCommaSeparator(str) {
  return parseFloat(str).toLocaleString()
}

export function formatTimeDifference(startDate, endDate) {
  const diffInMilliseconds = Math.abs(endDate - startDate)
  const seconds = Math.floor(diffInMilliseconds / 1000) % 60
  const minutes = Math.floor(diffInMilliseconds / (1000 * 60)) % 60
  const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60)) % 24
  const days = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24))

  if (days > 0) {
    return `${days}d`
  } else if (hours > 0) {
    return `${hours}h ${minutes}m`
  } else if (minutes > 0) {
    return `${minutes}m`
  } else {
    return `${seconds}s`
  }
}

export function getExperienceLevel(experience) {
  if (experience === 1) return 'Beginner'
  if (experience === 2) return 'Novice'
  if (experience === 3) return 'Skilled'
  if (experience === 4) return 'Experienced'
  if (experience === 5) return 'Expert'
  return 'Unknown'
}

export function getFullName(firstName, middleName, lastName) {
  const formattedMiddleName = middleName ? ` ${middleName} ` : ' '
  return `${firstName}${formattedMiddleName}${lastName}`
}

export function isValidJSON(json) {
  if (json) {
    try {
      JSON.parse(json)
      return true
    } catch (error) {
      return false
    }
  }
}

// Get the value from localStorage and use JSON.parse so that it will return the actual value
export function getFromLocalStorage(key) {
  try {
    const item = localStorage.getItem(key)

    if (isValidJSON(item)) {
      return JSON.parse(item)
    } else {
      if (item === 'undefined') return undefined
      else return item
    }
  } catch (error) {
    console.error(
      `Error while fetching from local storage item - ${key}: ${error}`
    )
    return null
  }
}

/**
 * Extract specific fields from an object
 * @param {Object} obj - The target object to extract fields from
 * @param {Array} arr - An array of fields to be extracted from the object
 * @returns {Object} - A new object containing only the specified fields
 * @example
 * const target = {
 *   first_name: 'John',
 *   last_name: 'Doe',
 *   age: 30,
 *   profession: 'Developer'
 * };
 * const fields = ['first_name', 'last_name'];
 * const result = pickObject(target, fields);
 * console.log(result); // { first_name: 'John', last_name: 'Doe' }
 */
export function pickObject(obj, arr) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => arr.includes(key))
  )
}
