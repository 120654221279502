import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import moment from 'moment'
import toast from 'react-hot-toast'
import { BsBookmarkFill } from 'react-icons/bs'
import { MdInfo } from 'react-icons/md'

import JobApplicationModal from '../components/JobApplicationModal'
import JobDetailsModal from '../components/JobDetailsModal'
import { Button } from '../../../components/Button'

import { ModalContext } from '../../../context/ModalContext'

import { selectJob, setSavedJobsModal } from '../../../redux/feature/jobSlice'

import { getFromLocalStorage } from '../../../utils/util'

import ReactGA4 from '../../../lib/ReactGA4'

import apiClient from '../../../helpers/apiClient'

import bookmark from '../../../assets/images/bookmark.png'

const SavedApplicationTab = () => {
  const dispatch = useDispatch()

  const { savedJobsModal } = useSelector((state) => state.jobs)

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [updated, setUpdated] = useState(false)

  const { setModal } = useContext(ModalContext)

  const cvDetails = getFromLocalStorage('applicant-cv-data')

  useEffect(() => {
    apiClient
      .get('/saved-jobs', {
        params: {
          applicant_id: cvDetails.id,
          is_saved: true,
          with_related: ['Jobs'],
        },
      })
      .then((res) => {
        setData(res?.data?.data)
        setIsLoading(false)
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
        setIsLoading(false)
      })
  }, [cvDetails.id, updated])

  const handleJobApplication = (job) => {
    dispatch(selectJob(job))
    dispatch(setSavedJobsModal('applyJob'))
    setModal(true)
  }

  const handleViewListing = (job) => {
    dispatch(selectJob(job))

    const reactGA4 = new ReactGA4()
    reactGA4.sendPageView(`View Listing - ${job.title}`)

    dispatch(setSavedJobsModal('viewJob'))
    setModal(true)
  }

  const handleUnsaveListing = (job) => {
    const reqBody = {
      applicant_id: cvDetails?.id?.toString(),
      job_id: job?.job_id?.toString(),
    }
    apiClient
      .post(`job/saved`, reqBody)
      .then((res) => {
        toast.success(res?.data?.resp?.successMessage)
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => {
        setUpdated(!updated)
      })
  }

  return (
    <StyledSavedApplicationTab>
      <>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <>
            {savedJobsModal === 'applyJob' && <JobApplicationModal />}
            {savedJobsModal === 'viewJob' && <JobDetailsModal />}

            {data?.map((job, index) => (
              <div className="saved__listings__wrapper" key={index}>
                <div className="saved__listings__header">
                  <h4 className={`saved__listings__title ${job?.status}`}>
                    {job?.Jobs?.title}
                  </h4>
                  <BsBookmarkFill
                    className="saved__listings__header__icon"
                    onClick={() => handleUnsaveListing(job)}
                  />
                </div>
                <p className={`saved__listings__desc ${job?.status}`}>
                  Saved {moment(job?.Jobs?.created_at).format('MMMM DD, YYYY')}
                </p>
                {job?.Jobs?.status === 'active' ? (
                  <div className="saved__listings__button__group">
                    <Button
                      radius={'none'}
                      height="3rem"
                      handleClick={() => {
                        handleJobApplication(job?.Jobs)
                      }}
                    >
                      Apply Now
                    </Button>
                    <Button
                      radius={'none'}
                      btnClass="secondary"
                      height="3rem"
                      handleClick={() => {
                        const updatedJob = { ...job?.Jobs, is_saved: true }
                        handleViewListing(updatedJob)
                      }}
                    >
                      View Listing
                    </Button>
                  </div>
                ) : (
                  <div className="inactive__listing">
                    <MdInfo className="inactive__listing__icon" />
                    No longer accepting applications
                  </div>
                )}
              </div>
            ))}
            <br />
            {data?.length === 0 && (
              <EmptySavedListingWrapper>
                <img src={bookmark} className="empty__saved__listings__icon" />
                <p>
                  You currently have no saved listings,
                  <br /> search for a job to apply to{' '}
                  <a
                    href="/home"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text"
                  >
                    here
                  </a>
                </p>
              </EmptySavedListingWrapper>
            )}
          </>
        )}
      </>
    </StyledSavedApplicationTab>
  )
}

export const StyledSavedApplicationTab = styled.section`
  @media (max-width: 768px) {
    flex-direction: column;
  }

  .saved__listings__wrapper {
    padding: 20px 0;
    border-bottom: 1px solid #eaeaea;

    .saved__listings__header {
      display: flex;
      justify-content: space-between;

      .saved__listings__title {
        font-weight: 600;
        font-size: 20px;
        color: #31374f;

        &.closed {
          color: #909090;
        }
      }

      .saved__listings__header__icon {
        font-size: 26px;
        cursor: pointer;
      }
    }

    .saved__listings__desc {
      margin-top: 10px;
      margin-bottom: 10px;
      color: #31374f;
      font-size: 14px;

      &.closed {
        color: #909090;
      }
    }

    .saved__listings__button__group {
      display: flex;
      gap: 0.5rem;
      color: #1a1a1a;
      max-width: 300px;
      text-align: center;
      padding: 5px 0 8px;
      border-radius: 5px;
      font-size: 14px;
    }

    .inactive__listing {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      width: 295px;
      height: 35px;
      background-color: #e4e4e4;
      padding: 1rem;

      .inactive__listing__icon {
        font-size: 24px;
        color: #4f4f4f;
      }
    }
  }
`

const EmptySavedListingWrapper = styled.div`
  text-align: center;

  .empty__saved__listings__icon {
    width: 100px;
  }

  p {
    color: #31374f;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
`

export default SavedApplicationTab
