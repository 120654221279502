import React from 'react'

import { useParams } from 'react-router-dom'

import { useMediaQuery } from 'react-responsive'

import JobPage from './components/JobPage'

import AuthLayouts from '../../layouts/AuthLayouts'

const Job = () => {
  const params = useParams()
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  return (
    <AuthLayouts width={`${isMobile ? '100%' : '80%'}`}>
      <JobPage token={params.token} />
    </AuthLayouts>
  )
}

export default Job
