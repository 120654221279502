import React from 'react'

import styled from 'styled-components'

const CVImage = ({ url, pageNumber }) => {
  if (!url) return null

  const splitUrl = url.split('/')
  splitUrl[0] = 'http://'
  splitUrl[5] = `upload/pg_${pageNumber}/`

  const newUrl =
    splitUrl &&
    splitUrl[0] +
      splitUrl[2] +
      '/' +
      splitUrl[3] +
      '/' +
      splitUrl[4] +
      '/' +
      splitUrl[5] +
      '/' +
      splitUrl[6] +
      '/' +
      splitUrl[7]

  return (
    <StyledCVImage>
      <img src={newUrl} alt="cv image" />
    </StyledCVImage>
  )
}

export default CVImage

const StyledCVImage = styled.div`
  width: 100%;
  background: red;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
    vertical-align: baseline;
  }
`
