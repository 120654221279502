/* eslint-disable camelcase */
import React, { useContext, useEffect } from 'react'
import { PagesContext } from '../../context/FormPagesContext'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import styled from 'styled-components'
import axios from 'axios'

import Sidebar from './Sidebar'
import PersonalInfo from './PersonalInfo'
import EducationalBackground from './EducationalBackground'
import JobExperience from './JobExperience'
import Skill from './Skill'
import CharacterRef from './CharacterReference'
import PreviewCV from './PreviewCV'

import AuthLayouts from '../../layouts/AuthLayouts'

import ReactGA4 from '../../lib/ReactGA4'

import { baseUrl } from '../../constants/constant'

const MainOnboard = () => {
  const { page, setSoftSkillOption } = useContext(PagesContext)

  useEffect(() => {
    const reactGA4 = new ReactGA4()
    reactGA4.sendPageView('Manage CV')
  }, [])

  useEffect(() => {
    axios
      .get(`${baseUrl}/skills`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((res) => {
        res.data.data.softSkills.map((skill) => {
          if (skill !== '') {
            setSoftSkillOption((prevState) => [
              ...prevState,
              { value: skill, label: skill },
            ])
          }

          return 'skill'
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <DndProvider backend={HTML5Backend}>
      <AuthLayouts width="750px">
        <StyledMain>
          <Sidebar />
          {page === 0.2 && <PersonalInfo />}
          {page === 0.4 && <EducationalBackground />}
          {page === 0.6 && <JobExperience />}
          {page === 0.8 && <Skill />}
          {page === 1 && <CharacterRef />}
          {page === 1.2 && <PreviewCV />}
        </StyledMain>
      </AuthLayouts>
    </DndProvider>
  )
}

export default MainOnboard

export const StyledMain = styled.section`
  width: 100%;
  display: flex;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
