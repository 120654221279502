import React, { useContext } from 'react'
import FlexWrapper from '../../components/MiniComponents/FlexWrapper'
import ItemSelect from '../../components/MiniComponents/ItemSelect'
import { ModalContext } from '../../context/ModalContext'
import { WorkEthicsContext } from '../../context/WorkEthics'
import { Button, StyledScreen } from '../remoteSetup/RemoteScreenOne'

const WorkEthicsStepThree = () => {
  const { fixSchedule, setFixSchedule } = useContext(WorkEthicsContext)
  const { setWorkEthicsPage, setReachWorkEthicsPage } = useContext(ModalContext)

  return (
    <StyledScreen maxWidth="456px">
      <h2 className="subHeading">
        I have the same mindset and am currently looking for a...
      </h2>
      <p className="paragraph">
        We are firm advocates of long-term engagements with our team members to
        support their dreams of building robust work-from-home careers while
        representing work-life balance.
      </p>

      <div className="mb-2" />
      <ItemSelect
        value="Full-time engagement with a fixed schedule"
        onClick={() =>
          setFixSchedule('Full-time engagement with a fixed schedule')
        }
        active={fixSchedule === 'Full-time engagement with a fixed schedule'}
      />
      <ItemSelect
        value="Part-time engagement with a fixed schedule"
        onClick={() =>
          setFixSchedule('Part-time engagement with a fixed schedule')
        }
        active={fixSchedule === 'Part-time engagement with a fixed schedule'}
      />
      <ItemSelect
        value="Full-time engagement with a flexible schedule"
        onClick={() =>
          setFixSchedule('Full-time engagement with a flexible schedule')
        }
        active={fixSchedule === 'Full-time engagement with a flexible schedule'}
      />
      <ItemSelect
        value="Part-time engagement with a flexible schedule"
        onClick={() =>
          setFixSchedule('Part-time engagement with a flexible schedule')
        }
        active={fixSchedule === 'Part-time engagement with a flexible schedule'}
      />

      <FlexWrapper content="flex-end" mt="2rem">
        <Button
          onClick={() => {
            setWorkEthicsPage(2)
            setReachWorkEthicsPage(2)
          }}
        >
          Go Back
        </Button>
        <Button
          className="filled"
          disabled={fixSchedule === '' || !fixSchedule}
          onClick={() => {
            setWorkEthicsPage(4)
            setReachWorkEthicsPage(4)
          }}
        >
          Next Step
        </Button>
      </FlexWrapper>
    </StyledScreen>
  )
}

export default WorkEthicsStepThree
