/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import styled from 'styled-components'

import Sidebar from './Sidebar'

import AuthLayouts from '../../layouts/AuthLayouts'

import ReactGA4 from '../../lib/ReactGA4'

import SettingsOne from './SettingsOne'
import SettingsTwo from './SettingsTwo'
import SettingsThree from './SettingsThree'
import SettingsFour from './SettingsFour'
import ContentArea from './ContentArea'

const Settings = () => {
  useEffect(() => {
    const reactGA4 = new ReactGA4()
    reactGA4.sendPageView('Settings')
  }, [])

  const menuItems = [
    { label: 'Personal Information', component: <SettingsOne /> },
    { label: 'Password', component: <SettingsTwo /> },
    { label: 'Notification', component: <SettingsThree /> },
    { label: 'Sync Resume', component: <SettingsFour /> },
  ]

  const [selectedPage, setSelectedPage] = useState(menuItems[0].component)

  const handleMenuItemClick = (menuItem) => {
    setSelectedPage(menuItem.component)
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <AuthLayouts width="1200px">
        <StyledMain>
          <Sidebar menuItems={menuItems} onItemClick={handleMenuItemClick} />
          <ContentArea className="xContentArea" selectedPage={selectedPage} />
        </StyledMain>
      </AuthLayouts>
    </DndProvider>
  )
}

export default Settings

export const StyledMain = styled.section`
  width: 100%;
  display: flex;
  align-items: flex-start;
  border: 1px solid #dfe2ec;
  padding: 10px 0;

  .xContentArea {
    padding: 10px;
    border-left: 1px solid #ccc;
  }

  .hide {
    display: hide;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
