import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import countriesList from 'all-countries-and-cities-json'

const CountryFilter = ({ addJobFilter }) => {
  const param = 'country'
  const [allCountries, setCountries] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const formattedCountries = Object.keys(countriesList).map((country) => ({
      value: country,
      label: country,
    }));
    setCountries(formattedCountries);
  }, [countriesList]);


  const toggleSelection = (option) => {
    setSelectedOptions((prev) =>
      prev.includes(option)
        ? prev.filter((o) => o !== option)
        : [...prev, option]
    );
  };

  const handleApplyFilterClick = () => {
    if (selectedOptions.includes('All Country')) {
      addJobFilter(param, 'All Country')
    } else {
      addJobFilter(param, selectedOptions)
    }

    
  };

  return (
    <StyledJobTypeFilter>
        <div className="custom-multi-select">
          <div className="dropdown-header" >   
            <div className='btn-group'>
              <button className="buttonAccept" onClick={handleApplyFilterClick}>Apply selected filters</button>
            </div>
          </div>

            <div className="dropdown-list">
              {allCountries?.map((option) => (
                <div
                  key={option.value}
                  onClick={() => toggleSelection(option.value)}
                  className={`dropdown-item ${selectedOptions.includes(option.value) ? 'selected' : ''}`}
                >
                  {option.value}
                </div>
              ))}
            </div>
        </div>      
    </StyledJobTypeFilter>
  )
}

export default CountryFilter

const StyledJobTypeFilter = styled.div`
  width: 15.5rem;
  display: flex;
  flex-direction: column;
  padding: 5px;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #eeeef6;
  box-shadow: 3px 3px 7px rgba(178, 178, 203, 0.2);
  position: absolute;
  top: 110%;
  z-index: 8;

  .filter-item {
    padding: 1rem 0.5rem;
    
    &:hover {
        background: #EEEEF6;
    }

    .filter-text {
        font-size: 14px;
    }
  }

  .scrollable-container {
      height: 200px;  /* Set a fixed height */
      overflow-y: auto;  /* Enable vertical scrolling */
      border: 1px solid #ccc;  /* Optional: add a border for visibility */
  }

  .content {
      padding: 10px;  /* Optional: add some padding */
  }

  .custom-multi-select {
  position: relative;
  width: 300px; /* Adjust as needed */
}

.dropdown-header {
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  background-color: white;
  border-radius: 4px;
}
.btn-group{
  float: left;
}

.dropdown-list {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.dropdown-item {
  padding: 8px;
  cursor: pointer;
  transition: background 0.2s;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.selected {
  background-color: #d1e7dd; /* Highlight for selected items */
}

.buttonAccept{
  background-color: rgb(215, 88, 128) !important;
  left: 0px !important;
  width: 100% !important;
  color: white !important;
}

.buttonAccept:hover {
  background-color: rgb(255, 88, 128) !important;
}
`
