import React, { useContext, useEffect, useState, useMemo } from 'react'

import DatePicker from 'react-datepicker'
import { FiPlus } from 'react-icons/fi'
import toast, { Toaster } from 'react-hot-toast'
import moment from 'moment'
import Joi from 'joi'
import styled from 'styled-components'

import { StyledProfileDetailsUpdate } from './ProfileDetailsUpdate'
import Modal from '../Modal'
import ToggleBtn from '../ToggleBtn'
import RichTextbox from '../RichTextbox'
import ExperienceDetails from '../ExperienceDetails'

import { ModalContext } from '../../context/ModalContext'
import { PagesContext } from '../../context/FormPagesContext'

import { industries } from '../../constants/industry'

const EmploymentHistory = ({ handleHardUpdate, handleHardDeleteItem }) => {
  const { handleModalToggle } = useContext(ModalContext)

  const [show, setShow] = useState(false)
  const [clearField, setClear] = useState(false)
  const [fieldErrors, setFieldErrors] = useState({})

  const {
    experienceFrom,
    setExperienceFrom,
    experienceTo,
    setExperienceTo,
    jobTitle,
    setJobTitle,
    companyName,
    setCompanyName,
    companyLocation,
    setCompanyLocation,
    industry,
    setIndustry,
    workType,
    setWorkType,
    experienceCurrentStatus,
    setExperienceCurrentStatus,
    jobDescriptionHtml,
    setJobDescriptionHtml,
    experienceState,
    setExperienceState,
    toggle,
    setToggle,
  } = useContext(PagesContext)

  useEffect(() => {
    if (experienceState?.length > 0) {
      setShow(false)
    } else {
      setShow(true)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('experienceState', JSON.stringify(experienceState))
  }, [experienceState])

  const data = {
    jobTitle,
    companyName,
    companyLocation,
    industry,
    workType,
    experienceCurrentStatus,
    jobDescriptionHtml,
    experienceFrom,
    experienceTo,
    id: new Date(),
  }

  const validationSchema = useMemo(() => {
    return Joi.object({
      jobTitle: Joi.string().label('Job Title').required(),
      companyName: Joi.string().label('Company').required(),
      companyLocation: Joi.string().label('Company Location').required(),
      industry: Joi.string().label('Industry').required(),
      workType: Joi.string().label('Work Type').required(),
      experienceCurrentStatus: Joi.string().label('Current Status').required(),
      jobDescriptionHtml: Joi.string()
        .label('Job Description')
        .required()
        .custom((value, helpers) => {
          // Check if the value is empty or contains only an empty paragraph
          if (value.trim() === '<p><br></p>') {
            return helpers.error('any.invalid', {
              label: 'Job Description',
            })
          }
          return value
        })
        .messages({
          'any.required': '"Job Description" is required',
          'any.invalid': '"Job Description" is not allowed to be empty',
        }),
      experienceFrom: Joi.date()
        .label('Date From')
        .less('now')
        .less(new Date(experienceTo))
        .message('Date From must be lesser than Date To and the current date')
        .required(),
      experienceTo: Joi.date()
        .label('Date To')
        .less('now')
        .greater(new Date(experienceFrom))
        .message('Date To must be greater than Date From')
        .required(),
      id: Joi.date().not().required(),
    })
  }, [experienceTo, experienceFrom])

  const clear = () => {
    setJobTitle('')
    setCompanyName('')
    setCompanyLocation('')
    setIndustry('')
    setWorkType('')
    setExperienceCurrentStatus('')
    setJobDescriptionHtml('')
    setExperienceTo('')
    setExperienceFrom('')
  }

  const handleStoreData = () => {
    const cvData = JSON.parse(localStorage.getItem('applicant-cv-data'))
    const oldMeta = cvData && cvData.meta ? JSON.parse(cvData.meta) : {}

    if (handleHardUpdate) {
      handleHardUpdate(data)
      clear()
      setClear(true)
      setShow(false)
    } else {
      const result = validationSchema.validate(data, { abortEarly: false })

      if (!result.error) {
        setExperienceState((prevState) => {
          if (prevState) {
            return [...prevState, data]
          } else {
            return [data]
          }
        })
        const meta = JSON.stringify({
          experienceState: experienceState,
          ...oldMeta,
        })
        localStorage.setItem(
          'applicant-cv-data',
          JSON.stringify({
            ...cvData,
            meta: meta,
          })
        )
        clear()
        handleModalToggle()
      } else if (!show) {
        setExperienceState((prevState) => {
          if (prevState) {
            return [...prevState, data]
          } else {
            return [data]
          }
        })
        handleModalToggle()
      } else {
        if (toggle) {
          setExperienceState([])
          const meta = JSON.stringify({
            ...oldMeta,
            experienceState: [],
          })
          localStorage.setItem(
            'applicant-cv-data',
            JSON.stringify({
              ...cvData,
              meta: meta,
            })
          )
          handleModalToggle()
        } else {
          const validationErrors = result.error.details.reduce((acc, curr) => {
            acc[curr.context.key] = curr.message.replace(/"/g, '')
            return acc
          }, {})

          setFieldErrors(validationErrors)
          toast.error('Please correct the validation errors before proceeding.')
        }
      }
    }
  }

  const handleEdit = (id) => {
    const newData = experienceState.find((item) => item.id === id)

    const filteredData = experienceState.filter((item) => item.id !== id)

    setShow(true)

    setJobTitle(newData.jobTitle)
    setCompanyName(newData.companyName)
    setCompanyLocation(newData.companyLocation)
    setIndustry(newData.industry)
    setWorkType(newData.workType)
    setExperienceCurrentStatus(newData.experienceCurrentStatus)
    setJobDescriptionHtml(newData.jobDescriptionHtml)
    setExperienceTo(moment(newData.experienceTo).toDate())
    setExperienceFrom(moment(newData.experienceFrom).toDate())

    setExperienceState(filteredData)
  }

  const handleDelete = (id) => {
    const filteredItems = experienceState.filter((item) => item.id !== id)
    setExperienceState(filteredItems)
    if (handleHardDeleteItem) {
      handleHardDeleteItem(filteredItems)
    }
  }

  return (
    <Modal
      title="Employment History"
      btnTitle="Update"
      btnFunc={handleStoreData}
    >
      <StyledProfileDetailsUpdate>
        {experienceState?.map(
          (
            {
              jobTitle,
              companyName,
              id,
              companyLocation,
              experienceFrom,
              experienceTo,
              jobDescription,
              experienceCurrentStatus,
            },
            index
          ) => (
            <ExperienceDetails
              key={index}
              jobTitle={jobTitle}
              companyName={companyName}
              experienceCurrentStatus={experienceCurrentStatus}
              companyLocation={companyLocation}
              from={experienceFrom}
              to={experienceTo}
              jobDescription={jobDescription}
              jobDescriptionHtml={jobDescriptionHtml}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              id={id}
            />
          )
        )}

        {/* toggle button */}
        {experienceState?.length === 0 && (
          <div className="toggleBtnWrapper">
            <ToggleBtn toggle={toggle} setToggle={setToggle} />
            <p className="small-subtitle">I have no employment history</p>
          </div>
        )}

        {show && (
          <>
            {/* Field of Study */}
            <div className="inputs">
              <label htmlFor="jobTitle">
                Job Title <span className="required">*</span>
              </label>

              <input
                className={`input ${'jobTitle' in fieldErrors && 'has-error'}`}
                disabled={toggle}
                id="jobTitle"
                type="text"
                placeholder="Job Title"
                onChange={(e) => setJobTitle(e.target.value)}
                value={jobTitle}
              />
              {'jobTitle' in fieldErrors && (
                <sub style={{ color: 'red' }}>{fieldErrors.jobTitle}</sub>
              )}
            </div>

            {/* Company Name */}
            <div className="inputs">
              <label htmlFor="companyName">
                Company Name <span className="required">*</span>
              </label>

              <input
                className={`input ${
                  'companyName' in fieldErrors && 'has-error'
                }`}
                disabled={toggle}
                id="companyName"
                type="text"
                placeholder="Company Name"
                onChange={(e) => setCompanyName(e.target.value)}
                value={companyName}
              />
              {'companyName' in fieldErrors && (
                <sub style={{ color: 'red' }}>{fieldErrors.companyName}</sub>
              )}
            </div>

            {/* Company Location */}
            <div className="inputs">
              <label htmlFor="companyLocation">
                Company Location <span className="required">*</span>
              </label>

              <input
                className={`input ${
                  'companyLocation' in fieldErrors && 'has-error'
                }`}
                disabled={toggle}
                id="company-location"
                type="text"
                placeholder="Company Location"
                onChange={(e) => setCompanyLocation(e.target.value)}
                value={companyLocation}
              />
              {'companyLocation' in fieldErrors && (
                <sub style={{ color: 'red' }}>
                  {fieldErrors.companyLocation}
                </sub>
              )}
            </div>

            {/* Industry */}
            <div className="inputs">
              <label htmlFor="industry">
                Industry <span className="required">*</span>
              </label>

              <select
                style={{
                  borderColor: 'industry' in fieldErrors && 'red',
                }}
                disabled={toggle}
                className="select"
                name=""
                id="industry"
                onChange={(e) => setIndustry(e.target.value)}
                value={industry}
              >
                <option>Choose Option</option>

                {industries.map((industry) => (
                  <option key={industry} value={industry}>
                    {industry}
                  </option>
                ))}
              </select>
              {'industry' in fieldErrors && (
                <sub style={{ color: 'red' }}>{fieldErrors.industry}</sub>
              )}
            </div>

            <div className="inputs">
              <label htmlFor="firstName">Job Description</label>
              <div className="moreInfo">
                <p>2-3 sentences that describe your relevant work experience</p>
              </div>

              <RichTextbox
                className={'jobDescriptionHtml' in fieldErrors && 'has-error'}
                content={jobDescriptionHtml}
                setContent={setJobDescriptionHtml}
                isReadOnly={toggle}
              />
            </div>

            {/* Work Type */}
            <div className="inputs rad">
              <label htmlFor="currentStatus">Work Type</label>

              <div className="radioBtns">
                <div className="radio">
                  <input
                    disabled={toggle}
                    className="radio-input"
                    type="radio"
                    name="workType"
                    id="fromHome"
                    onChange={(e) => setWorkType(e.target.value)}
                    value="Permanent Work From Home"
                    checked={workType === 'Permanent Work From Home'}
                  />
                  <label htmlFor="fromHome" className="small-subTitle">
                    Permanent Work From Home
                  </label>
                </div>
                <div className="radio">
                  <input
                    className="radio-input"
                    disabled={toggle}
                    type="radio"
                    name="workType"
                    id="tempFromHome"
                    onChange={(e) => setWorkType(e.target.value)}
                    value="Temporary Work From Home"
                    checked={workType === 'Temporary Work From Home'}
                  />
                  <label htmlFor="tempFromHome" className="small-subTitle">
                    Temporary Work From Home
                  </label>
                </div>
                <div className="radio">
                  <input
                    className="radio-input"
                    disabled={toggle}
                    type="radio"
                    name="workType"
                    id="office"
                    onChange={(e) => setWorkType(e.target.value)}
                    value="Office"
                    checked={workType === 'Office'}
                  />
                  <label htmlFor="office" className="small-subTitle">
                    Office
                  </label>
                </div>
              </div>
              {'workType' in fieldErrors && (
                <sub style={{ color: 'red' }}>{fieldErrors.workType}</sub>
              )}
            </div>

            {/* Current Status */}
            <div className="inputs rad">
              <label htmlFor="currentStatus">Current Status</label>

              <div className="radioBtns">
                <div className="radio">
                  <input
                    disabled={toggle}
                    className="radio-input"
                    type="radio"
                    name="notEmployed"
                    id="currentStatus"
                    onChange={(e) => setExperienceCurrentStatus(e.target.value)}
                    value="No longer employed"
                    checked={experienceCurrentStatus === 'No longer employed'}
                  />
                  <label htmlFor="fromHome" className="small-subTitle">
                    No longer employed
                  </label>
                </div>
                <div className="radio">
                  <input
                    className="radio-input"
                    disabled={toggle}
                    type="radio"
                    name="employed"
                    id="currentStatus"
                    onChange={(e) => setExperienceCurrentStatus(e.target.value)}
                    value="Currently employed"
                    checked={experienceCurrentStatus === 'Currently employed'}
                  />
                  <label htmlFor="tempFromHome" className="small-subTitle">
                    Currently employed
                  </label>
                </div>
              </div>
              {'currentStatus' in fieldErrors && (
                <sub style={{ color: 'red' }}>{fieldErrors.currentStatus}</sub>
              )}

              {/* Data Grid */}
              <div className="data-grid">
                <div className="inputs">
                  <label htmlFor="">Date From</label>
                  <StyledDatePickerWrapper>
                    <DatePicker
                      className={'experienceFrom' in fieldErrors && 'has-error'}
                      disabled={toggle}
                      selected={experienceFrom}
                      onChange={(date) => setExperienceFrom(date)}
                      showMonthYearPicker
                      dateFormat="MMMM yyyy"
                      placeholderText="MM/YYYY"
                    />
                  </StyledDatePickerWrapper>
                  {'experienceFrom' in fieldErrors && (
                    <sub style={{ color: 'red' }}>
                      {fieldErrors.experienceFrom}
                    </sub>
                  )}
                </div>
                <div className="inputs">
                  <label htmlFor="">Date To</label>
                  <StyledDatePickerWrapper>
                    <DatePicker
                      className={'experienceTo' in fieldErrors && 'has-error'}
                      disabled={
                        toggle ||
                        experienceCurrentStatus === 'Currently employed'
                      }
                      selected={experienceTo}
                      onChange={(date) => setExperienceTo(date)}
                      showMonthYearPicker
                      placeholderText="MM/YYYY"
                      dateFormat="MMMM yyyy"
                    />
                  </StyledDatePickerWrapper>
                  {'experienceTo' in fieldErrors && (
                    <sub style={{ color: 'red' }}>
                      {fieldErrors.experienceTo}
                    </sub>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        <div className="addContainer mt">
          <button
            onClick={() => {
              if (!show) {
                setClear(true)
                setShow(true)
              } else {
                handleStoreData()
              }
            }}
            className="keywordBtn"
          >
            <span className="mr">
              <FiPlus className="icon" />
            </span>
            Add another employment history
          </button>

          {clearField && (
            <button
              onClick={() => {
                setShow(false)
                setClear(false)
              }}
              className="keywordBtn remove"
            >
              Remove
            </button>
          )}
        </div>
        {/* </div> */}
        <Toaster position="top-right" reverseOrder={false} />
      </StyledProfileDetailsUpdate>
    </Modal>
  )
}

const StyledDatePickerWrapper = styled.div`
  .has-error {
    color: red;
    border-color: red !important;
  }
`

export default EmploymentHistory
