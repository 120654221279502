import axios from 'axios'
import { baseUrl } from '../constants/constant'

let token = localStorage.getItem('token') ? localStorage.getItem('token') : null

const apiClient = axios.create({
  baseURL: baseUrl,
  responseType: 'json',
  Authorization: `Bearer ${token}`,
})

apiClient.interceptors.request.use(async (req) => {
  token = localStorage.getItem('token') ? localStorage.getItem('token') : null
  req.headers.Authorization = `Bearer ${token}`
  return req
})

axios.interceptors.response.use(
  (response) => {
    return response
  },
  function (error) {
    if (
      error.response.status === 401 &&
      error.response.data === 'Unauthorized'
    ) {
      localStorage.removeItem('token')
      window.location.href = '/login'
      return Promise.reject(error)
    }

    return Promise.reject(error)
  }
)

export default apiClient
