import React, { useContext } from 'react'

import { PagesContext } from '../../../../context/FormPagesContext'
import { ModalContext } from '../../../../context/ModalContext'

import { P, H2 } from '../../../../styles/Typograph.styles'

import moment from 'moment'
import styled from 'styled-components'

import { getStatus } from '../../../../components/RangeSlider'

import avatar from '../../../../assets/images/avatar-temp.png'

import { getFromLocalStorage } from '../../../../utils/util'

const ManualCV = ({ headLines }) => {
  const imgLink = getFromLocalStorage('profileImgLink')

  const {
    userDetails,
    educationalData,
    experienceState,
    skills,
    softSkills,
    userData,
    cvPhoto,
    wfh,
    remoteFitness,
    characterReferences,
    toggleCharacterRef,
  } = useContext(PagesContext)

  const { compSpec, pheripheral, remoteWork } = useContext(ModalContext)

  return (
    <StyledManualCV>
      <div className="cvHeading">
        <div className="info">
          {cvPhoto && (
            <div className="avatar">
              <img src={imgLink || avatar} alt="avatar" />
            </div>
          )}
          <div>
            <H2
              mb=".5rem"
              align="left"
              textTransform="uppercase"
              size="2rem"
              weight="700"
            >
              {userDetails?.first_name + ' ' + userDetails?.last_name}
            </H2>
            <P>{headLines && headLines}</P>
          </div>
        </div>
        <div className="contact">
          <P>{userDetails && userDetails.contact ? userDetails.contact : ''}</P>
          <P>{userData && userData.email}</P>
          <P>
            {userDetails && userDetails.streetAddress
              ? userDetails.streetAddress
              : ''}
            , {userDetails && userDetails.city ? userDetails.city : ''},{' '}
            {userDetails && userDetails.country ? userDetails.country : ''}
          </P>
        </div>
      </div>

      <div className="cvBody">
        <div className="cvBody-left">
          <P textTransform="uppercase" mb="1rem" weight="500" size=".875rem">
            Technical Skills
          </P>
          <div className="two-grid">
            {skills &&
              skills.length > 0 &&
              skills.map(({ skill, experience }, index) => (
                <div key={index} className="grid-item">
                  <P weight="500" size=".75rem">
                    {skill}
                  </P>
                  <P weight="300" size=".75rem">
                    {getStatus(experience)}
                  </P>
                </div>
              ))}
          </div>

          <P textTransform="uppercase" mb="1rem" weight="500" size=".875rem">
            Personal Skills
          </P>

          <div className="flex-wrapper">
            {softSkills &&
              softSkills.length > 0 &&
              softSkills.map(({ value }, index) => (
                <div key={index} className="flex-item">
                  {value}
                </div>
              ))}
          </div>
        </div>
        <div className="cvBody-right">
          <>
            <div className="section">
              <P
                textTransform="uppercase"
                mb="1rem"
                weight="500"
                size=".875rem"
              >
                Professional Summary
              </P>

              <P
                weight="300"
                size=".875rem"
                dangerouslySetInnerHTML={{
                  __html: userDetails.professionalSummary,
                }}
              />
            </div>
            <div>
              <P
                textTransform="uppercase"
                mt="1.5rem"
                mb="1rem"
                weight="500"
                size=".875rem"
              >
                Work Experience
              </P>

              {experienceState && experienceState.length > 0 ? (
                experienceState.map(
                  (
                    {
                      companyName,
                      jobTitle,
                      jobDescriptionHtml,
                      experienceFrom,
                      experienceTo,
                      experienceCurrentStatus,
                      workType,
                      companyLocation,
                    },
                    index
                  ) => (
                    <div className="wide-grid" key={index}>
                      <div className="left-content">
                        <P weight="500" size=".75rem">
                          {moment(experienceFrom).format('MMM YYYY')} -{' '}
                          {experienceCurrentStatus === 'Currently employed'
                            ? 'Present'
                            : moment(experienceTo).format('MMM YYYY')}
                        </P>
                        <P weight="300" size=".75rem">
                          {companyName}
                        </P>
                        <P weight="300" size=".75rem">
                          {companyLocation?.label
                            ? companyLocation?.label
                            : companyLocation}
                        </P>
                        <P weight="300" size=".75rem">
                          {workType}
                        </P>
                      </div>
                      <div className="right-content">
                        <P weight="500" size=".75rem">
                          {jobTitle}
                        </P>
                        <P
                          weight="300"
                          size=".75rem"
                          dangerouslySetInnerHTML={{
                            __html: jobDescriptionHtml,
                          }}
                        />
                      </div>
                    </div>
                  )
                )
              ) : (
                <P>No experience</P>
              )}
            </div>
            <div>
              <P
                textTransform="uppercase"
                mt="1.5rem"
                mb="1rem"
                weight="500"
                size=".875rem"
              >
                Education
              </P>

              {educationalData && educationalData.length > 0 ? (
                educationalData.map(
                  (
                    { fieldOfStudy, school, from, to, schoolLocation },
                    index
                  ) => (
                    <>
                      <div key={index} className="wide-grid">
                        <div className="left-content">
                          <P weight="500" size=".75rem">
                            {moment(from).format('MMM YYYY')} -{' '}
                            {to === ''
                              ? 'Present'
                              : moment(to).format('MMM YYYY')}
                          </P>
                          <P weight="300" size=".75rem">
                            {schoolLocation.label
                              ? schoolLocation.label
                              : schoolLocation}
                          </P>
                        </div>
                        <div className="right-content">
                          <P weight="500" size=".75rem">
                            {fieldOfStudy}
                          </P>
                          <P weight="300" size=".75rem">
                            {school}
                          </P>
                        </div>
                      </div>
                    </>
                  )
                )
              ) : (
                <P>No education</P>
              )}
            </div>
          </>
        </div>
      </div>

      {wfh && (
        <div className="cv__row">
          <P
            textTransform="uppercase"
            mb="1rem"
            mt="1.5rem"
            weight="500"
            size=".875rem"
          >
            WFH Setup
          </P>

          <div className="three-grid">
            <div>
              <P weight="300" size=".75rem">
                Operating System
              </P>
              <P weight="500" size=".75rem">
                {compSpec?.os ? compSpec?.os : 'not specified'}
              </P>
            </div>

            <div>
              <P weight="300" size=".75rem">
                Processor
              </P>
              <P weight="500" size=".75rem">
                {compSpec?.processor ? compSpec?.processor : 'not specified'}
              </P>
            </div>

            <div>
              <P weight="300" size=".75rem">
                Installed Ram
              </P>
              <P weight="500" size=".75rem">
                {compSpec.ram ? `${compSpec.ram} RAM` : 'not specified'}
              </P>
            </div>

            <div>
              <P weight="300" size=".75rem">
                Os Version
              </P>
              <P weight="500" size=".75rem">
                {compSpec?.osVersion ? compSpec?.osVersion : 'not specified'}
              </P>
            </div>

            <div>
              <P weight="300" size=".75rem">
                WebCam
              </P>
              <P weight="500" size=".75rem">
                {pheripheral?.webCamera
                  ? pheripheral?.webCamera
                  : 'not specified'}
              </P>
            </div>

            <div>
              <P weight="300" size=".75rem">
                Headset
              </P>
              <P weight="500" size=".75rem">
                {pheripheral?.headSet ? pheripheral?.headSet : 'not specified'}
              </P>
            </div>

            <div>
              <P weight="300" size=".75rem">
                Microphone
              </P>
              <P weight="500" size=".75rem">
                {pheripheral?.microphone
                  ? pheripheral?.microphone
                  : 'not specified'}
              </P>
            </div>
          </div>
        </div>
      )}

      {remoteFitness && (
        <div className="cv__row">
          <P
            textTransform="uppercase"
            mb="1rem"
            mt="1.5rem"
            weight="500"
            size=".875rem"
          >
            Remote Fitness Level
          </P>

          {remoteWork && (
            <ul>
              <li className="list">
                <P>
                  I am ready to work from home permanently. I will be working
                  from {remoteWork?.workStation?.toLowerCase()}
                  {'.'}
                </P>
              </li>
              <li className="list">
                <P>
                  My hardware set-up is perfect for working from home. I have a{' '}
                  {remoteWork?.device?.toLowerCase()}
                </P>
              </li>
              <li className="list">
                <P>
                  I have the same mindset and am currently looking for a{' '}
                  {remoteWork?.workSchedule?.toLowerCase()}
                  {'.'}
                </P>
              </li>
              <li className="list">
                <P>
                  Considering my personal lifestyle and professional experience,
                  I work best during {remoteWork?.workShift?.toLowerCase()}
                  {'.'}
                </P>
              </li>
              <li className="list">
                <P>
                  I am dedicated to building my work-from-home career and{' '}
                  {remoteWork?.timeTracker?.toLowerCase()}
                  {'.'}
                </P>
              </li>
            </ul>
          )}
        </div>
      )}

      {toggleCharacterRef && (
        <div className="cv__row">
          <P
            textTransform="uppercase"
            mb="1rem"
            mt="1.5rem"
            weight="500"
            size=".875rem"
          >
            Character References
          </P>

          {characterReferences && (
            <div className="three-grid">
              {characterReferences?.map((c) => (
                <div key={c.id}>
                  <P weight="500" size=".75rem">
                    {c.crName}
                  </P>
                  <P weight="300" size=".75rem">
                    {c.crEmail}
                  </P>
                  <P weight="300" size=".75rem">
                    {c.crPhone}
                  </P>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </StyledManualCV>
  )
}

const StyledManualCV = styled.div`
  .cv__row {
    border-top: 1px solid #aeb3c9;
    padding: 1rem 2rem;

    .three-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 0.5rem;
      margin-bottom: 1rem;

      .grid-item {
        margin-bottom: 0.5rem;
      }
    }

    ul {
      padding: 0 1rem;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  @media print {
    border: none;
  }

  .cvHeading {
    width: 100%;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:first-child {
      border-bottom: 1px solid #aeb3c9;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;

      @media print {
        flex-direction: row;
        align-items: center;
      }
    }

    .info {
      display: flex;
      align-items: center;
    }

    .avatar {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      margin-right: 1rem;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  .cvBody {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;

    @media (max-width: 768px) {
      flex-direction: column;

      @media print {
        flex-direction: row;
      }
    }

    .cvBody-left {
      width: 100%;
      max-width: 300px;
      min-height: 21rem;
      padding: 1rem 1.5rem;
      border-right: 1px solid #aeb3c9;

      @media (max-width: 768px) {
        width: 100%;
        max-width: 768px;
        border: none;

        @media print {
          max-width: 300px;
          border-right: 1px solid #aeb3c9;
        }
      }

      .two-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.5rem;
        margin-bottom: 1rem;

        .grid-item {
          margin-bottom: 0.5rem;
        }
      }

      .flex-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.5rem;
        margin-bottom: 1.5rem;

        .flex-item {
          font-size: 0.75rem;
          padding: 0.5rem;
          font-weight: 300;
          border: 1px solid #0e1324;
          color: #0e1324;
          border-radius: 100px;
        }
      }

      .list {
        font-size: 0.75rem;
        margin-left: 1rem;
        font-weight: 300;
        margin-bottom: 0.5rem;
      }
    }

    .cvBody-right {
      width: 100%;
      padding: 1rem 1.5rem;

      .wide-grid {
        display: grid;
        grid-template-columns: 150px 1fr;
        grid-gap: 0.5rem;
        margin-bottom: 1rem;
      }

      @media (max-width: 768px) {
        width: 100%;
        border: none;

        @media print {
          border-left: 1px solid #aeb3c9;
        }
      }
    }
  }
`

export default ManualCV
