import React, { useContext, useState } from 'react'
import { Button, StyledScreen } from './RemoteScreenOne'

import Select from '../../components/MiniComponents/Select'
import InputWrapper from '../../components/MiniComponents/InputWrapper'
import FlexWrapper from '../../components/MiniComponents/FlexWrapper'
import { ModalContext } from '../../context/ModalContext'
import Input from '../../components/controls/Input'

const os = ['Windows', 'MacOs', 'Linux', 'Others']

const chooseOSVersion = (os) => {
  switch (os) {
    case 'Windows':
      return version.windows
    case 'MacOs':
      return version.macos
    case 'Linux':
      return version.linux
    default:
      return []
  }
}

const version = {
  windows: [
    'Windows 7',
    'Windows 8',
    'Windows 8.1',
    'Windows 10',
    'Windows 11',
    'Others',
  ],
  macos: [
    'OS X 10.9 (Mavericks)',
    'OS X 10.10 (Yosemite)',
    'OS X 10.11 (El Capitan)',
    'macOS 10.12 (Sierra)',
    'macOS 10.13 (High Sierra)',
    'macOS 10.14 (Mojave)',
    'macOS 10.15 (Catalina)',
    'macOS 11 (Big Sur)',
    'Others',
  ],
  linux: [
    'Ubuntu',
    'Deepin',
    'Pop!_Os',
    'Parrot Os',
    'Arch Linux',
    'Solus',
    'Kali Linux',
    'Nitrux',
    'Others',
  ],
}

const processor = [
  'i3',
  'i5',
  'i7',
  'i9',
  '8-core CPU with 4 performance cores and 4 efficiency cores',
  '8-core GPU',
  '16-core Neural Engine',
  'Others',
]

const ram = ['2', '4', '8', '16', '32', 'Others']

const RemoteScreenTwo = () => {
  const { setRemotePage, compSpec, setCompSpec, setReachRemotePage } =
    useContext(ModalContext)

  const [osExtra, setOsExtra] = useState(false)
  const [osVersionExtra, setOsVersionExtra] = useState(false)
  const [processorExtra, setProcessorExtra] = useState(false)
  const [ramExtra, setRamExtra] = useState(false)

  return (
    <StyledScreen>
      <h2 className="subHeading">Computer Specifications</h2>
      <p className="paragraph mb-2">
        Add your computer specifications to help us find the best match
      </p>

      {/* Operating System */}
      <InputWrapper label="Operating System">
        <Select
          value={osExtra ? 'Others' : compSpec.os}
          handleSelect={(value) => {
            if (value === 'Others') {
              setOsExtra(true)
              setCompSpec((prevState) => ({
                ...prevState,
                os: value,
                osVersion: '',
              }))
            } else {
              setCompSpec((prevState) => ({
                ...prevState,
                os: value,
                osVersion: '',
              }))
              setOsExtra(false)
            }
          }}
          data={os}
          placeholder="Choose an option"
        />
        {osExtra && (
          <Input
            style={{ marginTop: '1rem' }}
            onChange={(e) =>
              setCompSpec((prevState) => ({
                ...prevState,
                os: e.target.value,
                osVersion: '',
              }))
            }
            placeholder="Enter your Operating system"
          />
        )}
      </InputWrapper>

      {/* Os Version */}
      <InputWrapper label="OS Version">
        {osExtra ? (
          <Input
            style={{ marginTop: '1rem' }}
            onChange={(e) =>
              setCompSpec((prevState) => ({
                ...prevState,
                osVersion: e.target.value,
              }))
            }
            placeholder="Enter your Operating system version"
          />
        ) : (
          <>
            <Select
              value={osVersionExtra ? 'Others' : compSpec.osVersion}
              handleSelect={(value) => {
                if (value === 'Others') {
                  setOsVersionExtra(true)
                  setCompSpec((prevState) => ({
                    ...prevState,
                    osVersion: value,
                  }))
                } else {
                  setCompSpec((prevState) => ({
                    ...prevState,
                    osVersion: value,
                  }))
                  setOsVersionExtra(false)
                }
              }}
              data={chooseOSVersion(compSpec.os)}
              placeholder="Choose an option"
            />
            {osVersionExtra && (
              <Input
                style={{ marginTop: '1rem' }}
                onChange={(e) =>
                  setCompSpec((prevState) => ({
                    ...prevState,
                    osVersion: e.target.value,
                  }))
                }
                placeholder="Enter your Opereating system version"
              />
            )}
          </>
        )}
      </InputWrapper>

      {/* Processor */}
      <InputWrapper label="Processor">
        <Select
          value={processorExtra ? 'Others' : compSpec.processor}
          handleSelect={(value) => {
            if (value === 'Others') {
              setProcessorExtra(true)
              setCompSpec((prevState) => ({ ...prevState, processor: value }))
            } else {
              setCompSpec((prevState) => ({ ...prevState, processor: value }))
              setProcessorExtra(false)
            }
          }}
          data={processor}
          placeholder="Choose an option"
        />
        {processorExtra && (
          <Input
            style={{ marginTop: '1rem' }}
            onChange={(e) =>
              setCompSpec((prevState) => ({
                ...prevState,
                processor: e.target.value,
              }))
            }
            placeholder="Enter your Processor"
          />
        )}
      </InputWrapper>

      {/* RAM */}
      <InputWrapper label="RAM">
        <Select
          value={compSpec.ram}
          handleSelect={(value) => {
            if (value === 'Others') {
              setRamExtra(true)
              setCompSpec((prevState) => ({ ...prevState, ram: value }))
            } else {
              setCompSpec((prevState) => ({ ...prevState, ram: value }))
              setRamExtra(false)
            }
          }}
          data={ram}
          placeholder="Choose an option"
        />

        {ramExtra && (
          <Input
            style={{ marginTop: '1rem' }}
            onChange={(e) =>
              setCompSpec((prevState) => ({
                ...prevState,
                ram: e.target.value,
              }))
            }
            placeholder="Enter your RAM"
          />
        )}
      </InputWrapper>

      <FlexWrapper content="flex-end" mt="2rem">
        <Button
          onClick={() => {
            setRemotePage(1)
            setReachRemotePage(1)
          }}
        >
          Go Back
        </Button>

        <Button
          className="filled"
          disabled={
            compSpec.os === '' ||
            compSpec.osVersion === '' ||
            compSpec.processor === '' ||
            compSpec.ram === ''
          }
          onClick={() => {
            setRemotePage(3)
            setReachRemotePage(3)
          }}
        >
          Next Step
        </Button>
      </FlexWrapper>
    </StyledScreen>
  )
}

export default RemoteScreenTwo
