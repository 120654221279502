import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import ReactDOM from 'react-dom'

import styled from 'styled-components'
import {
  FiLogOut,
  FiEdit,
  FiFileText,
  FiUserPlus,
  FiLogIn,
  FiMail,
} from 'react-icons/fi'
import { BiCog } from 'react-icons/bi'
import { IoIosReturnLeft } from 'react-icons/io'
import { useMediaQuery } from 'react-responsive'

import { ModalContext } from '../../context/ModalContext'

import DownloadCVModal from '../../pages/welcome/components/DownloadCVModal'

import SocketIOClient from '../../lib/SocketIOClient'

const HeaderDropdown = ({ top }) => {
  const history = useHistory()

  const { handleHeaderToggle, setCloseModal, setHeaderToggle } =
    useContext(ModalContext)

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  let isCvUploaded = localStorage.getItem('applicant-cv-data')
  isCvUploaded = isCvUploaded && JSON.parse(isCvUploaded)

  const handleLogout = () => {
    const socket = new SocketIOClient()

    socket.emit('logout')

    setTimeout(() => {
      socket.disconnect()
    }, 1000)

    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('file')
    localStorage.removeItem('imgLink')
    localStorage.removeItem('educationData')
    localStorage.removeItem('email')
    localStorage.removeItem('professionalHeadlinesId')
    localStorage.removeItem('applicant-cv-data')
    localStorage.removeItem('applicant-data')
    localStorage.removeItem('reachPage')
    localStorage.removeItem('page')
    localStorage.removeItem('user_id')
    localStorage.removeItem('profileImgLink')
    localStorage.removeItem('cvImgLink')
    localStorage.removeItem('open_subscription')
    handleHeaderToggle()
    history.push('/login')
  }

  const handleDownloadCv = () => {
    setCloseModal(true)
    setHeaderToggle(true)
  }

  const handleContactUs = () => {
    history.push('/contact')
    handleHeaderToggle()
  }

  return ReactDOM.createPortal(
    <StyledHeaderDropdown top={top}>
      <DownloadCVModal />
      {isMobile && !localStorage.getItem('token') ? (
        <>
          <div
            onClick={() => {
              history.push('/login')
              handleHeaderToggle()
            }}
            className="menuItem"
          >
            <FiLogIn className="icon" />
            Log In
          </div>
          <div
            onClick={() => {
              history.push('/signup')
              handleHeaderToggle()
            }}
            className="menuItem"
          >
            <FiUserPlus className="icon" />
            Create Account
          </div>
        </>
      ) : (
        <>
          <div
            onClick={() => {
              history.push('/profile')
              handleHeaderToggle()
            }}
            className="menuItem"
          >
            <FiFileText className="icon" />
            My Profile
          </div>
          <div
            onClick={() => {
              history.push('/home')
              handleHeaderToggle()
            }}
            className="menuItem"
          >
            <IoIosReturnLeft className="icon" />
            Careers Page
          </div>

          <div
            onClick={() => {
              history.push('/settings')
              handleHeaderToggle()
            }}
            className="menuItem"
          >
            <BiCog className="icon" />
            Settings
          </div>
          <div
            onClick={() => {
              if (isCvUploaded.cv_public_id) {
                history.push('/remote-setup')
              } else {
                history.push('/cv-review')
              }
              handleHeaderToggle()
            }}
            className="menuItem"
          >
            <FiEdit className="icon" />
            Manage Profile
          </div>
          <div onClick={handleDownloadCv} className="menuItem">
            <FiFileText className="icon" />
            Download CV
          </div>
          <div onClick={handleContactUs} className="menuItem">
            <FiMail className="icon" />
            Contact Us
          </div>
          <div onClick={handleLogout} className="menuItem">
            <FiLogOut className="icon" />
            Logout
          </div>
        </>
      )}
    </StyledHeaderDropdown>,
    document.getElementById('headerDropdown')
  )
}

export default HeaderDropdown

const StyledHeaderDropdown = styled.div`
  position: fixed;
  top: 10%;
  right: 2.2rem;
  width: 145px;
  min-height: 106px;
  background: #fff;
  border: 1px solid #eeeef6;
  box-sizing: border-box;
  box-shadow: 3px 3px 7px rgba(178, 178, 203, 0.2);
  border-radius: 1px;
  padding: 1rem;
  z-index: 1000;

  .menuItem {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 300;
    letter-spacing: 0.03rem;
    color: #31374f;
    padding: 0.5rem 0;
    cursor: pointer;

    .icon {
      font-size: 0.75rem;
      color: #31374f;
      margin-right: 0.5rem;
    }

    &:hover {
      color: #ff7b9b;

      .icon {
        color: #ff7b9b;
      }
    }
  }
`
