import React, { useContext } from 'react'

import styled from 'styled-components'

import { P } from '../../../styles/Typograph.styles'

import { PagesContext } from '../../../context/FormPagesContext'

const CVCustomizer = () => {
  const {
    cvPhoto,
    setCvPhoto,
    wfh,
    setWfh,
    remoteFitness,
    setRemoteFitness,
    toggleCharacterRef,
    setToggleCharacterRef,
  } = useContext(PagesContext)

  return (
    <StyledCVCustomizer>
      <P>Customize Data</P>

      <div className="checkbox-container">
        <div className="checkbox">
          <input
            onChange={() => setCvPhoto(!cvPhoto)}
            checked={cvPhoto}
            className="check"
            type="checkbox"
            id="cvPhoto"
            name="cvPhoto"
          />
          <label htmlFor="cvPhoto">CV Photo</label>
        </div>

        <div className="checkbox">
          <input
            className="check"
            onChange={() => setWfh(!wfh)}
            checked={wfh}
            type="checkbox"
            id="contactDetails"
            name="contactDetails"
          />
          <label htmlFor="contactDetails">WFH Setup</label>
        </div>
        <div className="checkbox">
          <input
            className="check"
            onChange={() => setRemoteFitness(!remoteFitness)}
            checked={remoteFitness}
            type="checkbox"
            id="contactDetails"
            name="contactDetails"
          />
          <label htmlFor="contactDetails">Remote Fitness Level</label>
        </div>
        <div className="checkbox">
          <input
            className="check"
            onChange={() => setToggleCharacterRef(!toggleCharacterRef)}
            checked={toggleCharacterRef}
            type="checkbox"
            id="contactDetails"
            name="contactDetails"
          />
          <label htmlFor="contactDetails">Character References</label>
        </div>
      </div>
    </StyledCVCustomizer>
  )
}

const StyledCVCustomizer = styled.div`
  @media (max-width: 768px) {
    max-width: 768px;
  }

  .checkbox-container {
    @media (max-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      margin-top: 1.25rem;
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
    margin-top: 0.875rem;

    input.check {
      width: 0.75rem;
      height: 0.75rem;
      accent-color: #0e1324;
      background: #fff;
      cursor: pointer;

      @media (max-width: 768px) {
        width: 1.25rem;
        height: 1.25rem;
      }
    }

    label {
      font-size: 0.75rem;
      font-weight: 300;
      color: #0e1324;
      margin-left: 0.5rem;

      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }
  }
`

export default CVCustomizer
