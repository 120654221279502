import { api } from './api'

export const jobApi = api.injectEndpoints({
  endpoints: (build) => ({
    getJobs: build.query({
      query: (options = {}) => ({
        url: '/jobs?status=active',
        method: 'GET',
        params: options,
        withCredentials: false,
      }),
    }),
    scheduleInterview: build.mutation({
      query: (interview) => ({
        url: '/schedule-interview',
        method: 'POST',
        body: interview,
      }),
    }),
  }),
})

export const { useGetJobsQuery, useScheduleInterviewMutation } = jobApi

// export default jobApi
