export const industries = [
  'Information Technology and Services',
  'Hospital & Health Care',
  'Construction',
  'Retail',
  'Education Management',
  'Financial Services',
  'Accounting',
  'Computer Software',
  'Higher Education',
  'Automotive',
  'Government Administration',
  'Marketing and Advertising',
  'Banking',
  'Health, Wellness and Fitness',
  'Real Estate',
  'Food & Beverages',
  'Telecommunications',
  'Oil & Energy',
  'Hospitality',
  'Mechanical or Industrial Engineering',
  'Primary/Secondary Education',
  'Internet',
  'Electrical/Electronic Manufacturing',
  'Insurance',
  'Medical Practice',
  'Human Resources',
  'Consumer Services',
  'Transportation/Trucking/Railroad',
  'Pharmaceuticals',
  'Restaurants',
  'Management Consulting',
  'Civil Engineering',
  'Research',
  'Design',
  'Logistics and Supply Chain',
  'Law Practice',
  'Architecture & Planning',
  'Apparel & Fashion',
  'Consumer Goods',
  'Facilities Services',
  'Food Production',
  'Non-Profit Organization Management',
  'Machinery',
  'Entertainment',
  'Chemicals',
  'Wholesale',
  'Arts and Crafts',
  'Utilities',
  'Farming',
  'Legal Services',
  'Mining & Metals',
  'Airlines/Aviation',
  'Leisure, Travel & Tourism',
  'Sports',
  'Building Materials',
  'Environmental Services',
  'Professional Training & Coaching',
  'Medical Devices',
  'Music',
  'Individual & Family Services',
  'Cosmetics',
  'Mental Health Care',
  'Industrial Automation',
  'Security and Investigations',
  'Staffing and Recruiting',
  'Aviation & Aerospace',
  'Graphic Design',
  'Biotechnology',
  'Textiles',
  'Import and Export',
  'Consumer Electronics',
  'Public Relations and Communications',
  'Broadcast Media',
  'Business Supplies and Equipment',
  'Writing and Editing',
  'Military',
  'Media Production',
  'Computer Networking',
  'International Trade and Development',
  'Renewables & Environment',
  'Events Services',
  'Civic & Social Organization',
  'Photography',
  'Computer Hardware',
  'Defense & Space',
  'Furniture',
  'Computer & Network Security',
  'Printing',
  'Fine Art',
  'Investment Management',
  'E-Learning',
  'Outsourcing/Offshoring',
  'Warehousing',
  'Law Enforcement',
  'Publishing',
  'Religious Institutions',
  'Maritime',
  'Information Services',
  'Supermarkets',
  'Executive Office',
  'Animation',
  'Government Relations',
  'Semiconductors',
  'Program Development',
  'Plastics',
  'Online Media',
  'Public Safety',
  'Packaging and Containers',
  'Judiciary',
  'Alternative Medicine',
  'Performing Arts',
  'Commercial Real Estate',
  'Motion Pictures and Film',
  'Veterinary',
  'Computer Games',
  'Luxury Goods & Jewelry',
  'International Affairs',
  'Investment Banking',
  'Market Research',
  'Wine and Spirits',
  'Package/Freight Delivery',
  'Newspapers',
  'Translation and Localization',
  'Sporting Goods',
  'Public Policy',
  'Capital Markets',
  'Paper & Forest Products',
  'Libraries',
  'Wireless',
  'Venture Capital & Private Equity',
  'Gambling & Casinos',
  'Ranching',
  'Glass, Ceramics & Concrete',
  'Philanthropy',
  'Dairy',
  'Museums and Institutions',
  'Shipbuilding',
  'Think Tanks',
  'Political Organization',
  'Fishery',
  'Fund-Raising',
  'Tobacco',
  'Railroad Manufacture',
  'Alternative Dispute Resolution',
  'Nanotechnology',
  'Legislative Office',
  'Mobile Games',
]
