import React from 'react'

import styled from 'styled-components'

const FlexWrapper = ({
  children,
  content,
  align,
  direction,
  mt,
  wrap,
  gap,
  mobileDirection,
  mobileGap,
}) => {
  return (
    <StyledFlex
      content={content}
      align={align}
      direction={direction}
      mt={mt}
      gap={gap}
      wrap={wrap}
      mobileDirection={mobileDirection}
      mobileGap={mobileGap}
    >
      {children}
    </StyledFlex>
  )
}

export default FlexWrapper

const StyledFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.content ? p.content : 'center')};
  align-items: ${(p) => (p.align ? p.align : 'flex-start')};
  flex-direction: ${(p) => (p.direction ? p.direction : 'row')};
  margin-top: ${(p) => (p.mt ? p.mt : 0)};
  flex-wrap: ${(p) => (p.wrap ? p.wrap : 'nowrap')};
  gap: ${(p) => (p.gap ? p.gap : 0)};

  @media (max-width: 768px) {
    flex-direction: ${(p) =>
      p.mobileDirection ? p.mobileDirection : p.content};

    gap: ${(p) => (p.mobileGap ? p.mobileGap : p.gap)};
  }
`
