import { api } from './api'

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query({
      query: (id) => ({
        url: '/user/' + id,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetUsersQuery } = userApi
