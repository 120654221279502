import React from 'react'

import styled from 'styled-components'

const ToggleBtn = ({ toggle, setToggle }) => {
  return (
    <StyledToggle
      onClick={() => setToggle(!toggle)}
      className={toggle ? 'active' : ''}
    >
      <div className="tog" />
    </StyledToggle>
  )
}

export default ToggleBtn

const StyledToggle = styled.div`
  height: 19px;
  width: 38px;
  background: #aeb3c9;
  border-radius: 100px;
  position: relative;
  cursor: pointer;
  margin-right: 0.75rem;

  .tog {
    position: absolute;
    top: 50%;
    left: 2px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    transition: all 0.3s ease;
  }

  &.active {
    transition: all 0.3s ease;
    background-color: #ff7b9b;

    .tog {
      left: calc(36px - 15px);
    }
  }
`
