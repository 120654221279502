import React from 'react'

import styled from 'styled-components'

const RadioButton = ({ name, label, value, onChange, checked }) => {
  return (
    <StyledRadio>
      <input
        className="radio"
        type="radio"
        name={name}
        value={value}
        id={name}
        onChange={onChange}
        checked={checked}
      />
      <label className="label" htmlFor={name}>
        {label}
      </label>
    </StyledRadio>
  )
}

export default RadioButton

const StyledRadio = styled.div`
  display: flex;
  align-items: center;

  .radio {
    width: 1.25rem;
    height: 1.25rem;
    accent-color: #ff7b9b;
    opacity: 1;
  }

  .label {
    margin: 0;
    padding: 0;
    margin-left: 0.65rem;
    font-size: 0.875rem;
    font-weight: 300;
    color: #0e1324;
  }
`
