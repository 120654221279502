import { configureStore } from '@reduxjs/toolkit'
import { api } from './services/api'

import jobReducer from './feature/jobSlice'
import cvReducer from './feature/cvSlice'

export const store = configureStore({
  reducer: {
    jobs: jobReducer,
    cv: cvReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
})
