import React, { useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import styled from 'styled-components'
import { IoClose } from 'react-icons/io5'
import { Carousel } from 'react-responsive-carousel'

const MobileTutorial = ({ closeTutorial }) => {
  const [item, setItem] = useState(0)

  return (
    <StyledMobileTutorial>
      <h4 className="tutorial-header">Welcome to HOPLA</h4>
      <IoClose className="close-icon" onClick={closeTutorial} />
      <Carousel
        showIndicators={true}
        showStatus={false}
        autoPlay={true}
        infiniteLoop={true}
        showThumbs={false}
        selectedItem={item}
        renderIndicator={(isSelected, index, label) => {
          if (isSelected) {
            return (
              <StyledCarouselItem
                onClick={() => setItem(label)}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
              />
            )
          }
          return <StyledCarouselItem />
        }}
      >
        <div className="tutorial-steps">
          <div className="tutorial-details">
            <h3 className="tutorial-step-number">01</h3>
            <h4 className="tutorial-step-header">Search Jobs</h4>
            {localStorage.getItem('applicant-data') === null ? (
              <p>Find a vacancy that suits your skills.</p>
            ) : (
              <p>Select vacancy that suits your skills</p>
            )}
          </div>
        </div>
        <div className="tutorial-steps">
          <div className="tutorial-details">
            <h3 className="tutorial-step-number">02</h3>
            <h4 className="tutorial-step-header">Click Apply</h4>
            {localStorage.getItem('applicant-data') === null ? (
              <p>Create an account and tell us more about yourself</p>
            ) : (
              <p>Your next career move is just a click away</p>
            )}
          </div>
        </div>
        <div className="tutorial-steps">
          <div className="tutorial-details">
            <h3 className="tutorial-step-number">03</h3>
            <h4 className="tutorial-step-header">Search Jobs</h4>
            <p>Sit back and wait for our email confirmation</p>
          </div>
        </div>
      </Carousel>
    </StyledMobileTutorial>
  )
}

const StyledMobileTutorial = styled.div`
  @media screen and (min-width: 1024px) {
    display: none;
  }

  margin-bottom: 10px;

  .tutorial-header {
    text-align: center;
    margin: 0 auto;
    font-weight: 400;
    font-size: 14px;
  }

  .close-icon {
    font-size: 1.5rem;
    color: #31374f;
    cursor: pointer;
    position: relative;
    bottom: 23px;
    left: 320px;
  }

  .tutorial-steps {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .tutorial-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem 1.8rem;

      .tutorial-step-header {
        font-weight: 500;
        font-size: 20px;
      }

      h5 {
        font-weight: 300;
        font-size: 12px;
      }

      p {
        text-align: left;
      }
    }
  }
`

const StyledCarouselItem = styled.li`
  background-color: #dfe2ec;
  border-radius: 50%;
  width: 8;
  height: 8;
  display: inline-block;
  position: relative;
  top: 13px;
  margin: 0 5px;
`
export default MobileTutorial
