import React from 'react'

import styled from 'styled-components'

const Icons = ({ children, color, ml }) => {
  return (
    <IconContainer color={color} ml={ml}>
      {children}
    </IconContainer>
  )
}

export default Icons

const IconContainer = styled.span`
  .icon {
    font-size: 0.75rem;
    color: ${(props) => props.color || '#0E1324'};
    margin-left: ${(props) => props.ml || '0'};
  }
`
