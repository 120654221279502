import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import styled from 'styled-components'
import axios from 'axios'
import { Toaster } from 'react-hot-toast'
import OutsideClickHandler from 'react-outside-click-handler'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { FiEdit, FiFileText } from 'react-icons/fi'
import { IoChevronForwardOutline } from 'react-icons/io5'

import { ContentWrapper } from '../../layouts/AuthLayouts'

import WelcomeModal from './components/WelcomeModal'
import DownloadCVModal from './components/DownloadCVModal'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import UploadCv from '../../components/UploadCv'
import { IconButton } from '../../components/Button'
import ViewDetails from '../remoteSetup/components/ViewDetails'

import { baseUrl } from '../../constants/constant'

import apiClient from '../../helpers/apiClient'

import { H2, P } from '../../styles/Typograph.styles'

import { ModalContext } from '../../context/ModalContext'
import { PagesContext } from '../../context/FormPagesContext'

const WelcomeScreen = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [cvData, setCvData] = useState({})

  const {
    setCloseModal,
    handleModalToggle,
    handleModalToTrue,
    setPheripheral,
    setCompSpec,
    setRemoteWork,
    setOpenModal,
    setUpdateCv,
    compSpec,
    remoteWork,
  } = useContext(ModalContext)
  const { setPage, setReachPage, userData, cvUserData } =
    useContext(PagesContext)
  const [showDropdown, setShowDropdown] = useState(false)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    axios
      .get(`${baseUrl}/user/${userData.id}/remote-work`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        const result = res.data.resp
        setRemoteWork(JSON.parse(result.meta))
        setPheripheral(JSON.parse(result.peripherals) || [])
        setCompSpec(JSON.parse(result.computer_specification) || [])
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  // get cv details
  useEffect(() => {
    apiClient
      .get(`${cvUserData?.id}/uploaded-cv`)
      .then((res) => {
        if (res.data.resp) {
          const data = res.data.resp

          const SlicedUrl = data.url.slice(0, -3) + 'jpg'

          const cvDetails = {
            url: data.url,
            slicedUrl: SlicedUrl,
            id: data.public_id,
            totalPageNumber: data.pages,
          }

          setCvData(cvDetails)
        } else {
          setCvData({})
        }
      })
      .catch(() => {
        setCvData({})
      })
  }, [])

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        {/* show modal */}
        {showModal && <ViewDetails modal={showModal} setModal={setShowModal} />}
        <Header />
        <WelcomeModal />
        <DownloadCVModal />
        <UploadCv
          cvData={cvData}
          loading={loading}
          setLoading={setLoading}
          setCvData={setCvData}
        />
        <ContentWrapper width="550px">
          <ContainerStyle>
            <H2 size="3.5rem" className="sub-heading">
              Welcome Aboard!
            </H2>
            <P textAlign="center">
              Your CV is complete! Feel free to review your CV or choose a CV
              template to download while we review your profile
            </P>

            <div className="buttonContainer">
              <IconButton
                handleClick={() => {
                  setShowDropdown(!showDropdown)
                }}
              >
                <FiEdit className="leftIcon" />
                Manage Profile
                <IoChevronForwardOutline className="rightIcon" />
                {showDropdown && (
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setShowDropdown(!showDropdown)
                    }}
                  >
                    <Dropdown>
                      <div
                        className="item"
                        onClick={() => {
                          if (cvUserData?.cv_url) {
                            handleModalToTrue()
                            setUpdateCv(true)
                            setOpenModal(true)
                          } else {
                            history.push('/cv-review')
                          }
                        }}
                      >
                        Update CV
                      </div>
                      {!cvUserData?.name && (
                        <div
                          className="item"
                          onClick={() => {
                            setReachPage(0.2)
                            setPage(0.2)
                            history.push('/cv-review')
                          }}
                        >
                          Create CV
                        </div>
                      )}
                      <div
                        className="item"
                        onClick={() => {
                          history.push('/remote-setup')
                        }}
                      >
                        {compSpec?.os === '' ? 'Add ' : 'Edit '} WFH Setup
                      </div>
                      <div
                        className="item"
                        onClick={() => {
                          history.push('/remote-fitness-level')
                        }}
                      >
                        {!remoteWork || remoteWork === '' ? 'Add ' : 'Edit '}{' '}
                        Remote Fitness Level
                      </div>
                      {compSpec?.os !== '' && (
                        <div
                          className="item"
                          onClick={() => {
                            handleModalToggle()
                            setShowModal(!showModal)
                          }}
                        >
                          View WFH Setup
                        </div>
                      )}
                    </Dropdown>
                  </OutsideClickHandler>
                )}
              </IconButton>
              <IconButton handleClick={() => setCloseModal(true)}>
                <FiFileText className="leftIcon" />
                Download CV
                <IoChevronForwardOutline className="rightIcon" />
              </IconButton>
            </div>
          </ContainerStyle>
        </ContentWrapper>
        <Footer />
        <Toaster position="top-right" reverseOrder={false} />
      </DndProvider>
    </>
  )
}

export default WelcomeScreen

const Dropdown = styled.div`
  position: absolute;
  top: 108%;
  left: 0;
  width: 100%;
  background: #ffffff;
  border: 1px solid #eeeef6;
  box-shadow: 3px 3px 7px rgba(178, 178, 203, 0.2);
  border-radius: 3px;
  padding: 1.5rem;
  z-index: 3;

  .item {
    padding: 0.75rem 0.5rem;
    font-size: 1rem;
    font-weight: 300;
    color: #0e1324;
    text-align: left;

    &:hover {
      background: #0e1324;
      color: #fff;
    }
  }
`

const ContainerStyle = styled.div`
  width: 100%;

  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    margin-top: 3rem;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
`
