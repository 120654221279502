import React, { useContext, useState } from 'react'

import styled from 'styled-components'
import OutsideClickHandler from 'react-outside-click-handler'

import { FiSearch } from 'react-icons/fi'
import { RiVipCrownLine } from 'react-icons/ri'
import DraggableItem from './DraggableItem'
import { PagesContext } from '../context/FormPagesContext'
import { IoCloseOutline } from 'react-icons/io5'
import toast from 'react-hot-toast'

export const ClickableItem = ({ item, id, noClose }) => {
  const {
    markAsMoved,
    setItems,
    items,
    setProfessionalHeadlinesId,
    userDetails,
    setUserDetails,
  } = useContext(PagesContext)

  const handleClick = (id) => {
    const filteredList = items.filter((item) => item.status === 'moved')

    if (filteredList.length >= 3) {
      toast.error('Professional Headline should be 3')
    } else {
      markAsMoved(item.id)
      setProfessionalHeadlinesId((prevState) => {
        if (prevState.includes(item.id)) {
          localStorage.setItem(
            'professionalHeadlinesId',
            JSON.stringify(prevState)
          )
          return prevState
        } else {
          localStorage.setItem(
            'professionalHeadlinesId',
            JSON.stringify([...prevState, item.id])
          )
          return [...prevState, item.id]
        }
      })

      const newItems = items.filter((item) => item.status === 'moved')

      const newHeadlines = []

      newItems.forEach((item) => {
        newHeadlines.push(item)
      })

      setUserDetails({ ...userDetails, professionalHeadlines: newHeadlines })
    }
  }

  const deleteHandler = (id) => {
    const filteredList = items.filter((headline) => headline.id !== id)
    const newHeadline = items.filter((headline) => headline.id === id)

    newHeadline[0].status = 'current'

    setItems([...newHeadline, ...filteredList])
    setUserDetails((prevState) => ({
      ...prevState,
      professionalHeadlines: items.filter(
        (headline) => headline.status === 'moved'
      ),
    }))

    setProfessionalHeadlinesId((prevState) => {
      localStorage.setItem(
        'professionalHeadlinesId',
        JSON.stringify(prevState.filter((headline) => headline.id !== id))
      )
      return prevState.filter((headline) => headline.id !== id)
    })
  }

  return (
    <StyledClickedElement
      onClick={() => (noClose === false ? null : handleClick(item.id))}
    >
      <div className="item">{item.item}</div>

      {noClose === false && (
        <IoCloseOutline
          onClick={() => deleteHandler(item.id)}
          className="icon close"
        />
      )}
    </StyledClickedElement>
  )
}

const KeywordSearcher = ({ items, setCheck, headlines }) => {
  const [newItems, setNewItems] = useState(items)

  const [noFilter, setNoFilter] = useState(false)
  const searchRef = React.useRef()

  const handleSearch = (e) => {
    const filteredSearch = items.filter((item) =>
      item.item.toLowerCase().includes(e.target.value.toLowerCase())
    )

    setNewItems(filteredSearch)
    if (filteredSearch.length === 0) {
      setNoFilter(true)
    }
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setCheck(false)}>
      <StyledKeywordSearcher>
        <div className="heading">
          <FiSearch className="icon" />
          <input
            ref={searchRef}
            className="input2"
            type="text"
            onChange={handleSearch}
            placeholder="Filter keywords e.g. Creative, Marketing Specialist, Consultant"
          />
        </div>

        <div className="subTitle">
          <RiVipCrownLine className="icon crown" />
          <span className="text">
            Popular Keywords - ( Please drag and drop )
          </span>
        </div>

        {noFilter && <div className="noHeadline">Headline not Found</div>}

        <div className="lists">
          {newItems
            .filter((item) => item.status === 'current')
            .map((item, index) => (
              <>
                <DraggableItem
                  key={index + 5}
                  item={item}
                  id={item.id}
                  index={index}
                  noClose={true}
                />
                <ClickableItem
                  noClose={true}
                  key={index}
                  item={item}
                  id={item.item.id}
                />
              </>
            ))}
        </div>
      </StyledKeywordSearcher>
    </OutsideClickHandler>
  )
}

export default KeywordSearcher

const StyledClickedElement = styled.div`
  display: none;

  @media (max-width: 768px) {
    position: relative;
    display: block;
    cursor: pointer;

    .item {
      padding: 0.5rem;
      background: #f2f3f8;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.75rem;
      margin-bottom: 0.75rem;
      transition: 0.3s ease-in all;
      font-size: 0.75rem;
      padding-right: 2rem;
    }

    .icon {
      font-size: 1rem;
      color: #676e92;
      position: absolute;
      top: 35%;
      right: 1rem;
      transform: translateY(-50%);

      &.close {
        cursor: pointer;

        &:hover {
          color: red;
        }
      }
    }
  }
`

export const StyledKeywordSearcher = styled.div`
  position: absolute;
  top: 20%;
  left: 102%;
  width: 100%;
  max-width: 340px;
  padding: 1.25rem;
  background-color: #fff;
  border: 1px solid #eeeef6;
  box-shadow: 3px 3px 7px rgba(178, 178, 203, 0.2);
  border-radius: 3px;
  z-index: 77;

  @media (max-width: 768px) {
    left: 0;
    top: 102%;
    width: 100%;
    max-width: 100%;
  }

  .notFound {
    padding: 2rem 0;
    font-size: 0.75rem;
    font-weight: 300;
    display: block;
  }

  .noHeadline {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 300;
    letter-spacing: 0.03rem;
    color: #0e1324;
    margin-top: 0.5rem;
    cursor: pointer;

    span {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f2f3f8;
      margin-right: 0.5rem;

      .icon {
        font-size: 0.8rem;
        color: #8e93a6;
      }
    }

    &:hover {
      font-weight: 500;

      span {
        .icon {
          color: #0e1324;
        }
      }
    }
  }

  .heading,
  .subTitle {
    width: 100%;
    border-bottom: 1px solid #eeeef6;
    padding-bottom: 1.25rem;
    display: flex;
    align-items: center;

    .icon {
      font-size: 1rem;
      color: #31374f;
    }

    .input2 {
      width: 100%;
      background: none;
      border: none;
      outline: none;
      font-size: 0.875rem;
      color: #31374f;
      padding: 0 0.5rem;
      font-weight: 300;

      &::placeholder {
        color: #858aa0;
      }
    }
  }

  .subTitle {
    border: none;
    padding: 0.5rem 0;

    .icon.crown {
      font-size: 0.875rem;
      color: #eca842;
    }

    .text {
      font-size: 0.75rem;
      font-weight: 300;
      margin-left: 0.5rem;
      color: #676e92;
    }
  }

  .lists {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 200px;
    overflow-y: auto;
  }
`
