import React, { useEffect } from 'react'

import styled from 'styled-components'

const Slider = ({ value }) => {
  const [style, setStyle] = React.useState(1)

  const getStatus = (val) => {
    if (val === 1) {
      return 'Beginner'
    } else if (val === 2) {
      return 'Novice'
    } else if (val === 3) {
      return 'Skilled'
    } else if (val === 4) {
      return 'Experienced'
    } else if (val === 5) {
      return 'Expert'
    }
  }

  useEffect(() => {
    setStyle(getStatus(value))
  }, [value])

  return (
    <StyledSlider>
      <p>{style}</p>
      <input
        value={value}
        className={`slider ${style}`}
        type="range"
        min="1"
        max="5"
      />
    </StyledSlider>
  )
}

export default Slider

const StyledSlider = styled.div`
  width: 100%;
  max-width: 318px;

  p {
    font-size: 0.625rem;
    font-weight: 500;
    color: #58a05e;
  }

  .slider {
    width: 100%;
    height: 2px;
    -webkit-appearance: none;
    outline: none;
    border: none;
    background: linear-gradient(
      90deg,
      #e4f79c -1.65%,
      #cae463 23.76%,
      #a2dd74 49.71%,
      #7dd585 76.18%,
      #5cca95 100%
    );
    border-radius: 100px;
    transition: all 0.3s ease-in-out;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 0.875rem;
      height: 0.875rem;
      border-radius: 50%;
      background: #ddf382;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }

    &.Novice {
      &::-webkit-slider-thumb {
        background: #cae463;
      }
    }

    &.Skilled {
      &::-webkit-slider-thumb {
        background: #a2dd74;
      }
    }

    &.Experienced {
      &::-webkit-slider-thumb {
        background: #7dd585;
      }
    }

    &.Expert {
      &::-webkit-slider-thumb {
        background: #5cca95;
        box-shadow: 0px 0px 0px 12px rgba(92, 202, 149, 0.2);
      }
    }
  }
`
