import React, { useContext } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { IoCloseOutline } from 'react-icons/io5'
import { Toaster } from 'react-hot-toast'

import { ModalContext } from '../context/ModalContext'

const Modal = ({
  title,
  children,
  btnFunc,
  btnTitle,
  noCancel,
  showPrimaryBtn = true,
  closeBtnFunc,
  secondaryBtn,
  secondaryBtnTitle,
  onOverlayClick,
  containerClass,
}) => {
  const { openModal, handleModalToggle, setModal } = useContext(ModalContext)

  const handleCloseModal = () => {
    setModal(false)
    if (closeBtnFunc) closeBtnFunc()
  }

  return ReactDOM.createPortal(
    <ModalContainer className={openModal ? 'active' : ''}>
      <Toaster position="top-right" reverseOrder={false} />
      <div
        className="overlay"
        onClick={() => {
          handleModalToggle()
          if (onOverlayClick) onOverlayClick()
        }}
      />
      <div
        className={
          noCancel
            ? `modal-container resize ${containerClass}`
            : `modal-container ${containerClass}`
        }
      >
        <div className="modal-header">
          <h1>{title}</h1>
          <IoCloseOutline onClick={handleCloseModal} className="close-button" />
        </div>

        <div className="modal-body">{children}</div>

        <div className="modal-footer">
          {!noCancel && (
            <button className="btn btn-close" onClick={handleCloseModal}>
              Cancel
            </button>
          )}
          {secondaryBtn && (
            <label htmlFor="cv" className="btn btn-secondary">
              {secondaryBtnTitle}
            </label>
          )}
          {showPrimaryBtn && (
            <button
              onClick={btnFunc}
              className={
                noCancel ? 'btn btn-primary active' : 'btn btn-primary'
              }
            >
              {btnTitle}
            </button>
          )}
        </div>
      </div>
    </ModalContainer>,
    document.getElementById('modal')
  )
}

export default Modal

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  background-color: rgba(71, 86, 116, 0.2);
  z-index: 101;
  align-items: center;
  justify-content: center;
  display: none;

  &.active {
    display: flex;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    cursor: pointer;
  }

  .modal-container {
    position: relative;
    width: 100%;
    max-width: 689px;
    height: 100vh;
    // height: 31.4375rem;
    background-color: #fff;
    padding: 1.6rem 2.5rem;

    .modal-body {
      width: 100%;
      height: calc(100% - 6rem);
      padding: 0 0.5rem;
      overflow-y: auto;

      &.active {
        height: 100%;
      }

      &::-webkit-scrollbar {
        width: 5px;
        background: #edf0f4;
      }

      &::-webkit-scrollbar-thumb {
        background: #c9d1dc;
      }
    }

    &.resize {
      max-width: 500px;
      height: 515px;
    }

    &.job__details {
      max-width: 700px;
      height: 700px;
    }

    &.upload__cv {
      max-width: 800px;
      height: 90vh;
    }

    &.subscribe__modal {
      height: 450px;

      .modal-body {
        overflow: unset;
      }
    }

    &.with__headlines {
      .modal-body {
        overflow-y: unset;
      }
    }

    .modal-header {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 1rem;

      h1 {
        font-weight: 300;
        font-size: 1.5rem;
        letter-spacing: 0.03em;
        line-height: 1.5;
        color: #31374f;
      }

      .close-button {
        font-size: 2rem;
        color: #858aa0;
        cursor: pointer;
      }
    }

    .modal-footer {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;

      .btn {
        font-size: 0.75rem;
        font-weight: 400;
        color: #858aa0;
        padding: 0.75rem 1.3rem;
        border-radius: 100px;
        background: none;
        outline: none;
        cursor: pointer;

        &.btn-close {
          border: none;
        }

        &.btn-secondary,
        &.btn-primary {
          border: 1px solid #ff7b9b;
          color: #ff5880;

          &:hover,
          &.active {
            background-color: #ff5880;
            color: #fff;

            &:hover {
              background-color: #ff7b9b;
              border: 1px solid #ff7b9b;
              color: #fff;
            }
          }
        }
      }
    }
  }
`
