import React, { useContext, useEffect, useState } from 'react'

import styled from 'styled-components'
import toast from 'react-hot-toast'

import H2 from './typography/H2'
import TopModal from './TopModal'
import { Input } from '../components/Input'
import { Button } from '../components/Button'

import { ModalContext } from '../context/ModalContext'

import apiClient from '../helpers/apiClient'

import { getFromLocalStorage } from '../utils/util'

const SubscribeToMailerLiteModal = () => {
  const { setOpenTopModal } = useContext(ModalContext)

  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const isSubscribed = Boolean(
    getFromLocalStorage('applicant-data')?.is_subscribed
  )

  const handleOnClose = () => {
    setOpenTopModal(false)
    localStorage.setItem('open_subscription', false)
  }

  const handleEmailChanged = (e) => {
    setEmail(e)
  }

  const handleSubscribeUser = () => {
    setLoading(true)
    apiClient
      .post('/subscribe-user', { email })
      .then((res) => {
        toast.success(res.data.message)
      })
      .catch((err) => {
        const errorMessage =
          err?.response?.data?.message ||
          'Something went wrong. Please try again later.'
        toast.error(errorMessage)
      })
      .finally(() => {
        setLoading(false)
        setOpenTopModal(false)
      })
  }

  const delay = (delayInMs) => {
    return new Promise((resolve) => setTimeout(resolve, delayInMs))
  }

  useEffect(() => {
    const token = getFromLocalStorage('token')
    const isOpenSubscription = getFromLocalStorage('open_subscription')
    // 5 seconds
    const timer = 5000

    if (!isSubscribed && token && isOpenSubscription) {
      delay(timer).then(() => {
        setOpenTopModal(true)
      })
    }
  }, [])

  return (
    <TopModal
      noCancel={true}
      btnTitle="Close"
      containerClass="subscribe__modal"
      closeBtnFunc={handleOnClose}
      showPrimaryBtn={false}
      htmlId="subscribeModal"
    >
      <Wrapper>
        <H2 size="1.75rem" weight="500" mb="1rem" align="center">
          Subscribe For Latest Updates
        </H2>
        <H2 size="1.25rem">
          Empower your job search. Subscribe for top-tier job postings and
          career development tools!
        </H2>
        <FormWrapper>
          <Input
            title="Email"
            type="text"
            fontSize="1rem"
            value={email}
            onChange={handleEmailChanged}
            required
          />
          <Button handleClick={handleSubscribeUser} radius="10px" type="submit">
            {loading ? 'Subscribing...' : 'Subscribe'}
          </Button>
        </FormWrapper>
      </Wrapper>
    </TopModal>
  )
}

export default SubscribeToMailerLiteModal

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const FormWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 0.5rem;
`
