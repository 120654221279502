import React from 'react'

import styled from 'styled-components'

import ReCAPTCHA from '../ReCAPTCHA'

const FormWrapper = ({
  maxWidth,
  onSubmit,
  onVerify,
  children,
  loading,
  disabled,
  centered = true,
  buttonPlacement,
  buttonText,
  captchaRef
}) => {

  return (
    <StyledFormWrapper
      maxWidth={maxWidth}
      onSubmit={onSubmit}
      centered={centered}
      buttonPlacement={buttonPlacement}
    >
      {children}
      <ReCAPTCHA onChange={onVerify} captchaRef={captchaRef} />
      <div className="submit-button-container">
        <button disabled={disabled} type="submit">
          {loading ? 'Sending message...' : buttonText || 'Send'}
        </button>
      </div>
    </StyledFormWrapper>
  )
}

export default FormWrapper

const StyledFormWrapper = styled.form`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  margin: ${(p) => (p.centered ? '0 auto' : 'unset')};

  .submit-button-container {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: ${(p) =>
      p.buttonPlacement === 'start' ? 'flex-start' : 'flex-end'};

    button {
      border: none;
      outline: none;
      background: #ff5880;
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      padding: 0.875rem 2.5rem;
      border-radius: 3px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: #ff7b9b;
        color: #fff;
      }

      &:disabled {
        background: #aeb3c9;
        cursor: not-allowed;

        &:hover {
          background: #aeb3c9;
        }
      }
    }
  }
`
