import React from 'react'
import styled from 'styled-components'

import { RxDotFilled } from 'react-icons/rx'

const NotificationItem = ({ message, date, status, url, onUrlClick }) => {
  return (
    <StyledNotificationItem url={url}>
      <div className="notification-wrapper">
        <div className="notification-icon_wrapper">
          <RxDotFilled className={`notification-icon ${status}`} />
        </div>
        <div className="messages">
          <a
            dangerouslySetInnerHTML={{ __html: message }}
            className="message"
            href={url || '/'}
            rel="noreferrer"
            onClick={onUrlClick}
          ></a>
          <p className="date">{date}</p>
        </div>
      </div>
    </StyledNotificationItem>
  )
}

export default NotificationItem

const StyledNotificationItem = styled.div`
  letter-spacing: 0.03rem;
  font-weight: 500;
  font-size: 12px;

  .notification-wrapper {
    display: flex;
    gap: 1rem;

    .notification-icon_wrapper {
      .notification-icon {
        font-size: 20px;
        color: #31374f;

        &.notViewed {
          color: #ff5880;
        }
      }
    }

    .messages {
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
      padding-right: 1.5rem;
      margin-bottom: 0.5rem;

      .message {
        text-decoration: none;
        cursor: pointer;
        // pointer-events: ${(props) => (props.url ? 'fill' : 'none')};
        // // pointer-events: fill;

        &:hover {
          color: #ff7b9b;
        }
      }

      .date {
        font-weight: 400;
        color: #676e92;
      }
    }
  }
`
