import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import 'react-phone-input-2/lib/style.css'

import ScrollToTop from '../../components/ScrollRestoration'
import styled from 'styled-components'
import toast, { Toaster } from 'react-hot-toast'
import apiClient from '../../helpers/apiClient'

const userData = JSON.parse(localStorage.getItem('applicant-data'))
const userId = localStorage.getItem('user_id')
let isError = false

const FormConponent = () => {
  const [formData, setFormData] = useState({
    password: '',
    newPassword: '',
    comfirPassword: '',
    email: userData.email,
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const validate = (values) => {
    const upperCaseCharacters = /[A-Z]+/g
    const lowerCaseCharacters = /[a-z]+/g
    const numberCharacters = /[0-9]+/g
    const specialCharacters = /[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]+/

    if (!values.password) {
      toast.error('Current Password is Required!')
      isError = true
      return false
    }

    if (!values.newPassword) {
      toast.error('New Password is Required!')
      isError = true
      return false
    }

    if (values.newPassword.length < 8) {
      toast.error('New Password length should be minimum of 8 characters!')
      isError = true
      return false
    }

    if (upperCaseCharacters.test(values.newPassword) === false) {
      toast.error('New Password should have upper case letter!')
      isError = true
      return false
    }

    if (lowerCaseCharacters.test(values.newPassword) === false) {
      toast.error('New Password should have lower letter!')
      isError = true
      return false
    }

    if (numberCharacters.test(values.newPassword) === false) {
      toast.error('New Password should have numbers!')
      isError = true
      return false
    }

    if (specialCharacters.test(values.newPassword) === false) {
      toast.error('New Password should have special character!')
      isError = true
      return false
    }

    if (values.newPassword !== values.confirmPassword) {
      toast.error('Confirm Password not matched with New Password')
      isError = true
      return false
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const data = {
      email: formData.email,
      password: formData.password,
    }

    validate(formData)

    if (!isError) {
      apiClient
        .post(`/user/login/applicant`, data)
        .then((resp) => {
          // Request body
          const body = {
            newPassword: formData.newPassword,
            userId: userId,
          }

          apiClient
            .post(`/update-password`, body)
            .then((res) => {
              toast.success('Password Changed Successfully!')
            })
            .catch((err) => {
              toast.error(
                err?.response?.data?.message ||
                  err?.response?.data ||
                  'opps, Something went wrong!'
              )
            })
        })
        .catch(() => {
          toast.error('Wrong Current Password! Please Try again!')
        })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="formGroup">
        <label htmlFor="currentPassword">Current Password:</label>
        <input
          type="password"
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
        />
      </div>

      <span className="forgotPassword">
        <Link className="forgetLink" to="/forget-password">
          Forgot your password?
        </Link>
      </span>
      <br />
      <div className="formGroup">
        <label htmlFor="newPassword">New Password:</label>
        <div style={{ position: 'relative' }}>
          <input
            type="password"
            id="newPassword"
            name="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <div className="formGroup">
        <label htmlFor="confirmPassword">Confirm Password:</label>
        <input
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          required
        />
      </div>

      <button id="submit" type="submit">
        Change Password
      </button>
    </form>
  )
}

const SettingsTwo = () => {
  return (
    <StyledSettingsTwo>
      <ScrollToTop />
      <h2>Password</h2>
      <div>
        <FormConponent />
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </StyledSettingsTwo>
  )
}

export default SettingsTwo

export const StyledSettingsTwo = styled.div`
  width: 100%;
  padding: 0 15px;
  padding-left: 30px;

  h2 {
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: 5%;
    color: #31374f;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .formGroup {
    width: 100%;
    padding: 0 15px 10px 0;
  }

  label {
    margin-bottom: 5px;
    font-weight: 300;
    color: #31374f;
    letter-spacing: 5%;
    font-size: 16px;
  }

  .forgotPassword {
    float: right;
    margin-right: 15px;
    color: #6c91ff;
    font-size: 14px;
    font-weight: 400;
  }

  input {
    width: 100%;
    border: 1px solid #c6c6c6;
    font-size: 16px;
    padding: 8px;
    border-radius: 0;
    margin-top: 8px;
    font-weight: 300;
    color: #31374f;
    letter-spacing: 5%;
  }

  #submit {
    border: none;
    outline: none;
    background: #ff5880;
    color: #fff;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 100px;
    cursor: pointer;
    float: right;
    margin: 15px;
  }

  @media (max-width: 768px) {
    margin-top: 2rem;
    width: 100%;
  }
`
