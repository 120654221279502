import React, { useMemo, useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import pluralize from 'pluralize'
import { sanitize } from 'dompurify'
import toast from 'react-hot-toast'
import { BsBookmarkFill, BsBookmark } from 'react-icons/bs'
import { MdOutlineDateRange, MdOutlineSupervisorAccount } from 'react-icons/md'
import { FiClock } from 'react-icons/fi'

import ValidateLogin from './ValidateLogin'
import SuccessfulApplication from './SuccessfulApplication'
import ConfirmApplication from './ConfirmApplication'
import Tag from '../../pages/home/components/Tag'

import { ModalContext } from '../../context/ModalContext'
import { PagesContext } from '../../context/FormPagesContext'

import { setSavedJobsModal } from '../../redux/feature/jobSlice'

import apiClient from '../../helpers/apiClient'

import {
  numberCommaSeparator,
  capitalize,
  getFromLocalStorage,
} from '../../utils/util'

const JobDetails = ({ job }) => {
  const cvDetails = getFromLocalStorage('applicant-cv-data')
  const applicantData = getFromLocalStorage('applicant-data')
  const token = getFromLocalStorage('token')

  const dispatch = useDispatch()
  const { savedJobsModal } = useSelector((state) => state.jobs)

  const { userDetails } = useContext(PagesContext)
  const { setModal } = useContext(ModalContext)

  const [authorized, setAuthorized] = useState(false)
  const [previousClicked, setPreviousClicked] = useState('')
  const [saved, setSaved] = useState(false)

  useEffect(() => {
    if (token && cvDetails?.id) {
      setAuthorized(true)

      const request = {
        applicant_id: cvDetails?.id?.toString(),
        job_id: job?.id?.toString(),
        is_saved: true,
      }
      const urlParams = new URLSearchParams(request).toString()

      apiClient
        .get(`/saved-jobs?${urlParams}`)
        .then((response) => {
          if (response?.data?.data?.length > 0) {
            setSaved(true)
          }
        })
        .catch((error) => toast.error(error?.response?.data?.message))
    }
  }, [authorized])

  const updatedBenefit = useMemo(() => {
    if (job?.benefits) {
      return JSON.parse(job?.benefits)
    }
    return []
  }, [job])

  const technicalSkills = useMemo(() => {
    if (job?.technical_skills) {
      return JSON.parse(job?.technical_skills)
    }
    return []
  }, [job])

  const countryForVacancy = useMemo(() => {
    if (job?.countries) {
      return JSON.parse(job?.countries)
    }
    return []
  }, [job])

  const experience = useMemo(() => {
    if (job?.experience) {
      return JSON.parse(job?.experience)
    }
    return []
  }, [job])

  const languages = useMemo(() => {
    if (job?.language) {
      const newLangs = JSON.parse(job?.language)
      const langs = newLangs.map((lang) => lang.value)
      return langs.join(',')
    }
    return ''
  }, [job])

  const [hoverBookMarkIcon, setHoverBookmarkIcon] = useState(
    <BsBookmark className="icon" />
  )
  const [successMessage, setSuccessMessage] = useState('')

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage)
      setSuccessMessage('')
    }
  }, [successMessage])

  const handleApplyJob = () => {
    setModal(true)
    if (!authorized) {
      setPreviousClicked('applyJob')
      dispatch(setSavedJobsModal('validateLogin'))
      return
    }
    dispatch(setSavedJobsModal('applyJob'))
  }

  const handleConfirmApply = async () => {
    const reqBody = {
      applicant_id: cvDetails?.id?.toString(),
      applicant_name: `${userDetails?.firstName} ${userDetails?.lastName}`,
      email: applicantData?.email,
      added_by: 'Recruiter',
      phone_number: cvDetails?.contact?.toString() || '',
      job_id: job?.id?.toString(),
      job_title: job?.title,
      tags: 'Pending',
    }

    apiClient
      .post(`screening/${cvDetails?.id?.toString()}/add-applicant`, reqBody)
      .then(() => {
        dispatch(setSavedJobsModal('successfulApplication'))
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
      })
  }

  const handleSaveJob = () => {
    if (!authorized) {
      setPreviousClicked('saveJob')
      setModal(true)
      dispatch(setSavedJobsModal('validateLogin'))
    } else {
      const reqBody = {
        applicant_id: cvDetails?.id?.toString(),
        job_id: job?.id?.toString(),
      }

      apiClient
        .post(`job/saved`, reqBody)
        .then((response) => {
          setSaved(response?.data?.resp?.savedJob?.is_saved)
          setSuccessMessage(response?.data?.resp?.successMessage)
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message)
        })
    }
  }

  const handleSuccessfulLogin = () => {
    setAuthorized(true)
    if (previousClicked === 'applyJob') {
      dispatch(setSavedJobsModal('applyJob'))
    } else if (previousClicked === 'saveJob') {
      handleSaveJob()
    }
    setModal(false)
    setPreviousClicked('')
  }

  return (
    <StyledJobDetails>
      {savedJobsModal === 'validateLogin' && (
        <ValidateLogin onSuccessfulValidation={handleSuccessfulLogin} />
      )}
      {savedJobsModal === 'applyJob' && (
        <ConfirmApplication onConfirmApply={handleConfirmApply} />
      )}
      {savedJobsModal === 'successfulApplication' && <SuccessfulApplication />}

      <div className="title_section">
        <h2 className="title">{job?.title}</h2>
      </div>
      <p className="salary">
        {job?.currencies} {numberCommaSeparator(job.min_salary)} - {job?.currencies}{' '}
        {numberCommaSeparator(job.max_salary)}
      </p>
      <div className="more_details">
        <div className="details">
          <MdOutlineSupervisorAccount className="icon" />
          <p className="content">
            Hiring {job?.active_vacancy}{' '}
            {pluralize('Candidate', job?.active_vacancy)}
          </p>
        </div>
        <div className="details">
          <MdOutlineDateRange className="icon" />
          <p className="content">{capitalize(job?.job_type)}</p>
        </div>
        <div className="details">
          <FiClock className="icon" />
          <p className="content">{job?.time_shift}</p>
        </div>
      </div>

      <div className="button__group">
        <button onClick={handleApplyJob}>Apply Now</button>
        {saved ? (
          <div className="action active" onClick={handleSaveJob}>
            <BsBookmarkFill className="icon" />
            <p>Saved</p>
          </div>
        ) : (
          <div
            className="action"
            onClick={handleSaveJob}
            onMouseEnter={() => {
              setHoverBookmarkIcon(<BsBookmarkFill className="icon" />)
            }}
            onMouseLeave={() => {
              setHoverBookmarkIcon(<BsBookmark className="icon" />)
            }}
          >
            {hoverBookMarkIcon}
            <p>Save</p>
          </div>
        )}
      </div>

      <div className="section">
        <div className="section_title">Benefits</div>
        <div className="section_flex">
          {updatedBenefit?.map((benefit) => (
            <Tag key={benefit} text={benefit} />
          ))}
        </div>
      </div>

      <div className="section">
        <div className="section_title">Required Technical Skills</div>
        <div className="section_flex">
          {technicalSkills?.map((skill) => (
            <Tag key={skill.value} text={skill.value} />
          ))}
        </div>
      </div>

      <div className="section">
        <div className="section_title">Vacancy available on countries</div>
        <div className="section_flex">
          {countryForVacancy?.map((country) => (
            <Tag key={country.value} text={country.value} />
          ))}
        </div>
      </div>

      <p
        className="desc"
        dangerouslySetInnerHTML={{
          __html: sanitize(job?.description),
        }}
      />

      <div className="section_grid">
        <div className="section">
          <div className="section_title">Experience</div>
          <div className="section_content">{`${experience[0].specification}: ${experience[0].years}`}</div>
        </div>
        {job.education && (
          <div className="section">
            <div className="section_title">Education</div>
            <div className="section_content">{job?.education}</div>
          </div>
        )}
      </div>
      {languages !== '' && (
        <div className="section">
          <div className="section_title">Language</div>
          <div className="section_content">{languages}</div>
        </div>
      )}
    </StyledJobDetails>
  )
}

export default JobDetails

const StyledJobDetails = styled.div`
  width: 100%;
  padding: 1.875rem 2rem;
  border: 1px solid #dfe2ec;
  border-radius: 3px;
  height: 100%;
  overflow-y: auto;

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    overflow-y: scroll;
    height: calc(100vh - 30px);
    padding: 120px 1.5rem;
    z-index: 101;
  }

  .close-wrapper {
    display: none;

    @media (max-width: 768px) {
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      justify-content: flex-end;
      padding: 1.8rem 1.5rem;
      background: #fff;
      width: 100%;
      border-bottom: 1px solid #e4e4e4;

      .close-icon {
        font-size: 1.5rem;
        cursor: pointer;
      }
    }
  }

  .title_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 5px;

    .title {
      font-size: 1.5rem;
      font-weight: 500;
      color: #31374f;
      max-width: 70%;

      @media (max-width: 768px) {
        margin-bottom: 1rem;
      }
    }
  }

  .button__group {
    display: flex;
    align-items: flex-start;
    gap: 0.875rem;
    margin-top: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #dfdfe9;

    @media (max-width: 768px) {
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      background: #fff;
      display: flex;
      color: red;
      justify-content: flex-start;
      padding: 1.85rem 1.5rem;
      box-shadow: 0px -5px 10px rgba(199, 199, 199, 0.25);
    }

    button {
      border: none;
      outline: none;
      background: #ff5880;
      color: #fff;
      padding: 0.75rem 1.5rem;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      height: 2.8rem;
      max-width: 130px;
    }

    .action {
      display: flex;
      align-items: center;
      width: 100px;
      height: 2.8rem;
      background-color: #fff;
      border: 1px solid #31374f;
      color: #31374f;
      cursor: pointer;
      gap: 0.3rem;
      padding-left: 0.5rem;

      .icon {
        width: 24px;
        height: 24px;
      }

      &:hover,
      &.active {
        background: #edf1ff;
        color: #31374f;
        font-weight: 500;
      }
    }
  }

  .salary {
    font-size: 1rem;
    font-weight: 300;
    color: #31374f;
  }

  .more_details {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 0.75rem;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .details {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .icon {
        font-size: 1.25rem;
        color: #31374f;
      }

      .content {
        font-size: 1rem;
        font-weight: 500;
        color: #31374f;
      }
    }
  }

  .section_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    max-width: 450px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .section {
    margin-top: 1.25rem;

    .section_title {
      font-size: 0.75rem;
      font-weight: 500;
      color: #31374f;
      margin-bottom: 0.5rem;
    }

    .section_flex {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.5rem;
    }

    .section_content {
      font-size: 0.75rem;
      font-weight: 500;
    }
  }

  .desc {
    margin-top: 1.25rem;
    font-size: 14px;
    font-family: 'Lato';
    font-weight: 300;
    color: #31374f;
    line-height: 1.5;
  }
`
