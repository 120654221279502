import styled from 'styled-components'

export const P = styled.p`
  font-size: ${(props) => (props.size ? props.size : '0.875rem')};
  font-weight: ${(props) => (props.weight ? props.weight : '300')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '1.5')};
  color: ${(props) => (props.color ? props.color : '#0E1324')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.03em'};
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : 'none'};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  margin-bottom: ${(props) => (props.mb ? props.mb : '0')};
  margin-top: ${(props) => (props.mt ? props.mt : '0')};
  padding: ${(props) => (props.padding ? props.padding : '0')};
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : 'none'};
`

export const H2 = styled.h2`
  font-size: ${(props) => (props.size ? props.size : '1.5rem')};
  text-align: ${(props) => (props.align ? props.align : 'center')};
  font-weight: ${(props) => (props.weight ? props.weight : '300')};
  color: ${(props) => (props.color ? props.color : '#31374f')};
  margin-bottom: ${(props) => (props.mb ? props.mb : '1.5rem')};
  margin-top: ${(props) => (props.mt ? props.mt : '0')};
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : 'none'};

  @media (max-width: 768px) {
    font-size: ${(props) => (props.mobileSize ? props.mobileSize : '1.45rem')};
  }
`
